.notification {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px 18px 8px 8px;
  border-bottom: 1px solid transparent;

  .icon {
    font-size: 1.5rem;
  }
}

.notification__content {
  color: #333636;
  font-size: 14px;
  line-height: 18px;
  padding-left: 10px;
  flex: 1 1 auto;
}

.notification__dismiss-button.button {
  overflow: visible;
  color: #aaa;
  transition: color 200ms;

  &:hover {
    color: #333;
  }

  .icon.icon-close {
    font-size: .75rem;
    color: inherit;
    
    svg {
      fill: currentColor;
      animation: none;
    }
  }
}

.notification--error {
  background-color: #FDEDEE;
  border-bottom-color: #ED021E;

  svg {
    fill: #ED021E;
  }
}

.notification--info {
  background-color: #E5F1F8;
  border-bottom-color: #0069AB;

  svg {
    fill: #0069AB;
  }
}

.notification--success {
  background-color: #EAF7EF;
  border-bottom-color: #30A259;

  svg {
    fill: #257D45;
  }
}

.notification--warning {
  background-color: #FDF6ED;
  border-bottom-color: #ED8702;

  svg {
    fill: #A55E01;
  }
}

.notification.cookie-banner {
  transition: all 800ms;
  z-index: 3;
  animation: none;
  opacity: 1;

  &.fadeOutUp {
    margin-top: -1em;
    opacity: 0;
  }
}