@import '~Sass/variables';

$summary-border-color: $mineral-light-80;

$solution-background-color: $white-ice;
$solution-expanded-background-color: $rain-light-90;
$solution-border-color: $rain;

$ordered-background-color: rgba(234,247,239,0.65);
$ordered-expanded-background-color: rgba(234,247,239, 1);
$ordered-border-color: $tree;

$validation-background-color: rgba(253,246,237,0.65);
$validation-expanded-background-color: rgba(253,246,237, 1);
$validation-border-color: $warning;
$validation-color: #BD6C01;

.design-rollup {
  border-radius: 10px;
  margin-bottom: .75rem;
  scroll-margin: 1rem;
  border: 1px solid #CCCFCF;
  overflow: hidden;
  transition: box-shadow 100ms;

  &.design-rollup--solution {
    border-color: $solution-border-color;
  }

  &.design-rollup--ordered {
    border-color: $ordered-border-color;
  }

  &.design-rollup--validation {
    border-color: $validation-border-color;
  }

  .design-actions {
    display: none;
    height: 45px;
  }

  .icon-caret-down svg {
    transition: transform 250ms;
  }

  &--expanded {
    .design-rollup__contract-term {
      display: none;
    }

    .design-rollup__summary {
      .monthly-pricing, 
      .install-pricing {
        display: none;
      }
      
      .design-actions {
        display: flex;
      }
    }

    .toggle-legs-button > .icon-caret-down svg {
      transform: rotate(-180deg);
    }
  }

  .design-items__rows .design-item-row {
    &::before {
      content: '';
      position: absolute;
      left: 28px;
      top: 0;
      height: 100%;
      border-left: 1px dashed #99c7e5;
    }
    &:first-child::before {
      top: 50%;
      height: 50%;
    }
    &:last-child::before {
      height: 50%;
    }
  }

  .design-item__included-demarc.design-item__included-demarc {
    font-weight: 300;
    color: #888;
    font-size: .625rem;
    line-height: 1.1;
    margin: .25rem 0 0;
  }

  .demarc-message {
    display: flex;
    align-items: center;
    font-size: .75rem;
    margin-left: -.25rem;

    &.demarc-message--mpoe {
      color: #ED8702;
    }

    &.demarc-message--demarc {
      color: #30a259;
    }
  }

  .label-value {
    min-width: 5rem;
    padding-right: .75rem;
  
    &__label {
      color: #747A7A;
      font-size: .75rem;
      line-height: .875rem;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .icon {
        font-size: 1rem;
        margin-right: .125rem;
        cursor: pointer;
      }
    }
  
    &__value {
      font-size: 1rem;
      color: #1A1B1D;
      text-align: right;
    }
  }
}

.design-rollup__reference {
  color: #747A7A;
  font-size: .75rem;
  line-height: 2;
}

.design-rollup__status {
  display: flex;
  align-items: center;
  font-size: .75rem;
  margin-left: -.25rem;

  .status-icon__text {
    color: #257D45;
  }

  &.status-icon--design-validation {
    .status-icon__text {
      color: $validation-color;
    }  
  }

  &.status-icon--edited {
    .icon svg {
      fill: #009bc2;
    }

    .status-icon__text {
      color: #009bc2;
    }
  }

  &.design-rollup__status--rejected {
    border: 1px dashed transparent;
    flex: 0 0;
    display: inline-flex;
    padding-right: .25rem;
    margin-right: .75rem;
    border-radius: 4px;
    cursor: pointer;
    transition: border 2s;

    .status-icon__text {
      color: red;
    }
  
    &:hover {
      border: 1px dashed red;
      transition: border .5s;
    }
  }
}

.design-rollup__vendors {
  margin: .25rem 0;
  font-size: .75rem;
  color: $icon-text-color;

  .icon {
    font-size: 1.25rem;
    margin-right: .25rem;
  }
}

.design-rollup-description {
  margin-top: -.5rem;
}

.design-rollup-description__offnet-message,
.design-rollup-description__unverified-hub {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .icon {
    font-size: 1.5rem;
    margin-left: -.25rem;
  }

  span {
    font-size: .75rem;
    font-weight: 500;
  }
}

.design-rollup-description__offnet-message {
  color: $red;
}

.design-rollup-description__unverified-hub {
  color: $validation-color;
}

.design-rollup__summary {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: start;
  padding: 20px 20px 10px;
  transition: background-color 200ms;

  .design-rollup--expanded {
    z-index: 1;
  }

  .design-rollup--solution & {
    background-color: $solution-background-color;
  }

  .design-rollup--expanded.design-rollup--solution & {
    background-color: $solution-expanded-background-color;
  }

  .design-rollup--expanded.design-rollup--not-solution & {
    border-bottom: 1px solid $summary-border-color;
  }

  .design-rollup--ordered & {
    background: $ordered-background-color;
  }

  .design-rollup--ordered.design-rollup--expanded & {
    background: $ordered-expanded-background-color;
  }

  .design-rollup--validation & {
    background: $validation-background-color;

    .toggle-legs-button > .icon-caret-down {
      color: $validation-color;
    }
  }

  .design-rollup--validation.design-rollup--expanded & {
    background: $validation-expanded-background-color;
  }
}

.design-container {
  transition: background-color 300ms;

  .design-rollup--ordered & {
    border-color: #9BD8B1;
  }

  .design-rollup--expanded.design-rollup--not-solution & {
    border-color: #CCCFCF;
  }
}

.design-rollup__summary__rows {
  flex: 2 2 auto;
  flex-flow: row nowrap;
}
  
.design-rollup__summary__row {
  display: flex;
  justify-content: flex-end;
}

.checkbox-container {
  display:flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 1 auto;
  position: relative;

  & ~ div {
    flex: 0 0;
    justify-content: space-evenly;
  }

  [type=checkbox] {
    margin-right: 20px;
  }
}

.value {
  font-size: 1rem;
  color: #1A1B1D;
  text-align: right;
  margin-right: .5rem;
}

.design-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #1A1B1D;
}

.design-solution-label {
  position: absolute;
  top: 100%;
  font-size: .75rem;
  line-height: 1.3;
  color: #7c8484;
  white-space: nowrap;
}

.design-rollup__contract-term {
  text-align: right;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  padding: .5rem 0.25rem 0;
  color: $icon-text-color;
  font-size: .75rem;
  white-space: nowrap;

  .icon {
    font-size: 1.25rem;
    margin-right: .125rem;
  }

  .icon-envelope {
    margin-right: .25rem;
    font-size: 1.125rem;
  }
}

.monthly-pricing {
  padding-right: 1rem;
}

.monthly-pricing,
.install-pricing {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: right;

  .value {
    min-width: 80px;
  }
}

.quote-spec__toggle-button.button {
  padding-top: 3px;
  padding-bottom: 3px;

  &::after {
    content: '❮';
    margin-left: 1em;
    transition: transform .3s;
    display: inline-block;
    transform: rotate(270deg)
  }

  &.--expanded::after {
    transform: rotate(90deg);
  }
}

.design-container__timing-group {
  display: inline-block;
  padding-right: 40px;
}

.design-container__timing-label {
  color: #747A7A;	
  font-size: .75rem;
}

.design-container__timing-value {
	color: #1A1B1D;
	font-size: 1rem;
	font-weight: 500;
}

.design-rollup__manual-order-context-menu {
  min-width: unset;
  transform: translateX(-7rem) translateY(1rem);
  font-size: .875rem;
}

// .design-rollup-pricing {
//   display: flex;
//   flex-flow: row nowrap;
//   justify-content: space-between;
// }

@media screen and (max-width: 900px) {
  .design-rollup__summary {
    padding: 1rem .5rem;
  }

  .design-rollup__summary > div {
    flex: 0 0 auto;
  }

  .checkbox-container {
    margin-left: .5rem;
    max-width: 40%;
  }

  .design-rollup__summary__rows {
    margin-left: auto;
  }

  .monthly-pricing,
  .install-pricing {
    flex-direction: column;
    align-items: unset;
  }

  .design-item-group--speeds,
  .design-item-group--pricing {
    flex-direction: column;
  }

  .design-item-group--pricing .price-margin.price-margin {
    flex-direction: row-reverse;
    justify-content: end;
    align-items: flex-start;
  }

  .design-item-group--speeds .design-item--term {
    min-width: auto;
    text-align: initial;
  }

  .price-margin__margin {
    font-size: .675rem;
    color: #999;
    margin-right: 0.35rem;
  }
}


.design-rollup__design-distance {
  display: inline-flex;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: none;

  .icon {
    font-size: 1rem;
  }
}

.design-rollup__design-distance-ratio {
  font-size: .75rem;
  top: .75rem;
  position: absolute;
  font-weight: 500;
  width: 100%;
}