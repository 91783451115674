.admin-organization-notification {
    .notifications-groups-subheader {
         font-size: .875rem;
         color: #555;
         margin-top: 1rem;
         font-weight: 500;
    }
    .all-email-notification-add-user {
        text-align: center;
        width: 50%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .all-email-notification-no-users {
        color: #5A5F5F;
        font-size: .75rem;
    }
}


.sortable-table__all-email-notification-users {
    width: 50%;
    margin-top: 10px;

  .sortable-table-column--icon {
    width: 2rem;
    font-size:1.5rem;
  }

  td {
    vertical-align: middle;
    border-bottom: 1px solid #D9DBDB;
  }

  .sortable-table-column--label {
    width: 100%;
    color: #5A5F5F;
  }

  .sortable-table-column--remove {
    font-size: 1.5rem;
  }
}

.admin-organization-notifications .popup-content {
  overflow: visible;
}
