.notification-area {
  height: 100%;
  position: relative;
  display: flex;
  line-height: 3.5rem;
}

.notification-area__button.button {
  padding: 1rem;
  font-size: 1.5rem;
  display: inline-block;
}