@import '~Sass/variables';

.modal-container {
  position: fixed;
  font-size: 1rem;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: $modal-background;
  z-index: $modal-z;
  pointer-events: all;

  &.dismissed {
    display: none;
  }
}
