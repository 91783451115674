@import '~Sass/variables';

.lookup.currency.lookup--inline {
  .react-select__control {
    width: 6rem;
    height: unset;
    background-color: transparent;
    border-color: transparent;
    transition: background-color 800ms;

    &.react-select__control--is-disabled {
      width: 4.5rem;

      .react-select__indicators {
        display: none;
      }
    }
  }

  .react-select__control:hover {
    border-color: transparent;
  }

  .react-select__control--menu-is-open {
    background-color: #fff;
    border: $input-border-color;
  }
  .react-select__single-value {
    padding: 0;
    margin: 0;
  }

  .react-select__value-container {
    padding: 0 0 0 8px;
  }

  .react-select__dropdown-indicator {
    padding: 0 2px;
  }
}