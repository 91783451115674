.trace-header {
  background-color: #F2F3F3;
  width: 100%;
  font-size: .75rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  border-bottom: 1px solid #D9DBDB;
  font-weight: bold;
}

.trace-info {
  background-color: #4D5151;
  width: 100%;
  color: #fff;
}

.trace-line {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1 auto;
    position: relative;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem; 
}

.trace-label {
  padding-right: 2.5rem;
  font-size: .625rem;
  color: #c0c3c3;
}

.trace-value {
  font-size: .75rem;
  color: #fff;
}

.trace-message {
  font-size: .625rem;
  padding-bottom: 2px;
}

