.page-content {
  margin: 0 auto;
  padding: 2em 0 6em;
  width: 90%;
}

.page-header {
  color: #747A7A;
  font-size: 28px;
  font-weight: 500;
  width: 100%;
}

.header-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  margin-top: 1.95rem;
  margin-bottom: 1.3rem;
  line-height: 1.3;

  &.--sticky {
    position: sticky;
    top: 0;
    background: #fff;
    margin: .45rem 0 .3rem;
    padding: 1.5rem 0 1rem; 
    z-index: 1;

    // Bottom gradient transparency POC
    // &::after {
    //   background: rgb(255,255,255);
    //   background: linear-gradient(0deg, rgba(255,255,255,0.3519782913165266) 0%, rgba(255,255,255,1) 50%);
    //   content: ' ';
    //   height: 0.5rem;
    //   display: block;
    //   width: 100%;
    //   position: absolute;
    //   top: 100%;
    // }
  }

  h1, h2, h3, h4, h5, h6 { 
    margin: 0;
  }
}

[aria-hidden="true"] {
  visibility: hidden;
}