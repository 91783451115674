@import '~Sass/variables';

.health-indicator {
  border-radius: 2px;
  font-weight: bold;
  padding: 0.3rem;
  display: flex;
  justify-content: center;

  &.healthy {
    background-color: $success-background;
  }

  &.critical {
    background-color: $error-background;
  }

  &.major {
    background-color: $loading-background;
  }
}
