.admin-users {
  h3 {
    margin: 0 auto 0 0;
  }

  .flex-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .loading-overlay__fetch-users {
    &.loading-overlay {    
      background: rgba(255, 255, 255, 0.5);
    }
  
    .loading-overlay__positioning {
      position: fixed;
      width: 80%; // inherited from .page-content
    }
  }
}

.admin-users__checkbox-req-order-mgr {
  font-size: .75rem;
  .checkbox-icon {
    margin-right: .25rem;
  }
}

.admin-users__list {
  margin: 1rem 0 0;
  padding: 0;
  border-top: 1px solid #ddd;
}

.admin-users__list-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  border-bottom: 1px solid #ddd;

  &:nth-child(even)  {
    background-color: #f6f6f6;
  }
}

.admin-users__list-item-name {
  flex: 1 1 20rem;

  .icon-avatar {
    transform: scale(1.25);
    margin-right: .25rem;
  }

  .admin-users__list-item--disabled & {
    opacity: .75;
    font-style: italic;

    .icon-avatar {
      color: #ccc;
    }
  }
}

.admin-users__list-item-email {
  font-size: .75rem;
  color: #545454;
}

.admin-users__list-item-roles {
  flex: 1 1 50%;
  vertical-align: top;
  text-align: left;
}

.admin-users__list-item-role {
  padding: .25rem .5rem;
  border: 1px solid;
  border-radius: 3px;
  margin: .1rem .5rem .1rem 0;
  background-color: #f9f9f9;
  text-transform: uppercase;
  font-size: .75rem;
  white-space: nowrap;
  display: inline-block;
  cursor: help;

  &[data-role="Admin"] {
    color: red;
  }
  &[data-role="Internal"] {
    color: #3390cb;
  }
  &[data-role="Unitas Design View"] {
    color: #3390cb;
    font-weight: 500;
  }
  &[data-role="Org Manager"] {
    color: purple;
  }
  &[data-role="Pricing Desk"] {
    color: #30a259;
  }
  &[data-role="Network Engineer"] {
    color: peru;
  }
  &[data-role="Sungard EMEA"] {
    color: #D92A1C;
  }
}

.admin-users__list-item-actions {
  flex: 0 0 auto;
}

.admin-users__context-menu {
  transform: translate3d(-1.5rem, 0, 0);
}

// Admin Quote Options
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher), 
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper, 
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.65);
}

// .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
//   opacity: .8;
//   border-color: #1890ff !important;

//   &::after {
//     border-color: #1890ff;
//   }
// }