.packetfabric-home {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  
    & + .footer-links { 
      background-color: #D9DBDB;
    }
  }
  
  .packetfabric-home__content {
    flex: 0 0 auto;
    padding: 4rem 10%;
    position: relative;
  
    h1 {
      font-size: 3.15rem;
      font-weight: 400;
      padding: 0;
      margin: 0;
      line-height: 1.2;
      color: #fff;
  
      sup { 
        font-size: 1rem;
      }
    }
  }
  
  .packetfabric-home__content-underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: .8;
  }
  
  .packetfabric-home__forms {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 2.5rem -1rem 0;
  
    .space {
      width: 47.5%;
    }
  
    .space--hidden {
      display: none;
    }
  }
  
  .packetfabric-home__form {
    flex: 1 1 47.5%;
    min-height: 23.5rem;
    margin: 1rem;
    text-align: center;
    background-color: #F8F9F9;
    border-radius: 4px;
    padding: 3rem 2rem 2rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    width: 47.5%;
    // animation: formCollapse;
    // animation-duration: 2s;
    //transition: height .1s cubic-bezier(1, 0, 0, 1);
    box-shadow: 0 15px 35px 0 rgba(32,38,41,0.2), 0 9px 15px 0 rgba(32,38,41,0.1);
  
    > * {
      animation: formCollapse;
      animation-duration: 5s;
    }
  
    > p {
      font-size:  1.3125rem;
      line-height: 1.25;
      color: #5A5F5F;
    }
  
    .button--rounded {
      border-radius: 1.5rem;
      border: none;
      box-shadow: none;
      text-transform: uppercase;
      text-shadow: none;
      font-weight: 500;
      font-size: .875rem;
      padding: 1rem;
      width: 85%;
      height: 3.125rem;
    }
  
    .close-button {
      position: absolute;
      border: none;
      background: none;
      box-shadow: none;
      top: 0;
      padding: 0.5rem 0.5rem;
      font-size: 1.25rem;
      margin: 1rem;
      color: #009BC2;
    }
  
    &.form1--expanded {
      position: absolute;
      top: -1rem;
      left: 0;
      width: 95%;
      z-index: 1;
      min-height: 25.5rem;
      animation: formExpand;
      animation-duration: .5s;
      transition: none;
    
      .close-button {
        right: 0;
      }
    }
  
    &.form2--expanded {
      position: absolute;
      top: -1rem;
      right: 0;
      width: 95%;
      z-index: 1;
      min-height: 25.5rem;
      animation: formExpand;
      animation-duration: .5s;
      transition: none;
  
      .close-button {
        left: 0;
      }
    }
  }
  
  .packetfabric-home__form-icons {
    .icon {
      font-size: 3rem;
      color: #19B5DB;
    }
  
    .icon + .icon {
      margin-left: 1.5rem;
    }
  }
  
  .packetfabric-home-section--create-quote {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background-color: #4D5151;
    
    p {
      color: #D9DBDB;
      margin: 0 0 2.5rem;
      font-size: 1.625rem;
      line-height: 1.2;
    }
  }
  
  .packetfabric-home-section__support-questions {
    position: relative;
  
    ul {
      width: 30rem;
      padding: 2rem 4rem 2.5rem;
      margin: 2.5rem auto 4.5rem;
      text-align: left;
      list-style: none;
      background-color: #F2F3F3;
      border: 1px dashed #00ADD8;
      border-radius: 4px;
    }
  
    li {
      color: #4D5151;
  
      &::before {
        content: "\2022";
        color: #00ADD8;
        font-weight: bold;
        display: inline-block;
        margin: 0 .5rem 0 -1rem;
        transform: scale(1.5);
      }
    }
  
    .button {
      position: absolute;
      bottom: -1rem;
      margin: 0 auto;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      background-color: #009BC2;
      padding-left: 2.5rem;
      padding-right: 3rem;
  
      .icon { 
        font-size: 1.25rem;
        margin-right: .5rem;
        color: #fff;
      }
    }
  }
  
  .packetfabric-home-section--footer {
    flex: 1 1 auto;
    background-color: #D9DBDB;
    text-align: center;
    padding-bottom: 1.5rem;
    
    .support__text {
      color: #404444;
    }
  
    .text__service-delivery {
      font-size: .75rem;
      margin: 2.5rem 0 .5rem;
    }
    
    svg path {
      fill: #000;
    }
  }
  
  
  @keyframes formExpand {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.1);
    }
    60% {
      transform: scale(1);
    }
  }
  
  @keyframes formCollapse {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }