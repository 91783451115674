// TODO: Pass in classNane
.partner-pricing {
  padding: 1.25rem 2rem 0.5rem 2rem;
  background-color: #F2F3F3;
  margin: 0 -30px 0 -30px;

  .partner-pricing-type {
    display: flex;
    align-items: baseline;
  }

  .checkbox {
    font-size: .75rem;
  }

  .checkbox-icon {
    margin-right: .5em;
  }

  .partner-pricing-options.lookups {
    margin-bottom: .5rem;
  }

  .react-select--is-disabled .react-select__value-container {
    opacity: 0.3;
  }

  .partner-pricing-commission {
    font-size: .75rem;
    font-style: italic;
  }

  .checkbox + .partner-pricing-commission {
    margin-left: 2rem;
  }
}

.partner-pricing__row {
  display: flex;
  align-items: baseline;
}

.partner-pricing__no-bid {
  margin: 0 0 0 auto;
  line-height: 1;
}

.widget-create-quote__top-row {
  .toggler {
    font-size: .75rem;
    margin: 0 0 1rem 0;
    padding: 5px;
    display: inline-flex;
    align-items: center;

    .icon.icon-caret-down {
      color: inherit;
      font-size: 1.25rem;

      svg {
        fill: currentColor;
        transition: transform 200ms;
      }
    }
  
    &.toggler--collapse .icon-caret-down svg {
      transform: rotate(180deg);
    }
  }
}

.partner-data-wrapper {
  &.toggleable {
    background-color: #F2F3F3;
    padding: 0 30px;
    margin: 0 -30px 2rem -30px;
    max-height: 800px;
    opacity: 1;
    transition: max-height 500ms, opacity 500ms, margin-bottom 250ms;
    visibility: visible;
  }

  &.toggleable--collapsed {
    max-height: 0;
    overflow: hidden;
    transition: max-height 500ms, opacity 500ms, visibility 1ms 500ms, margin-bottom 350ms;
    opacity: 0;
    margin-bottom: 0;
    
    input { 
      visibility: hidden;
    }
  }
}

.partner-pricing-validation-error {
  display: flex;
  font-size: .75rem;
  margin-top: .5rem;
  align-items: center;
  color: red;

  .icon {
    margin-right: .125rem;
  }
}