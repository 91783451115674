.modal-container-support-modal {
  .popup .popup-content {
    padding-top: 0;
  }

  .support-modal__checkboxes {
    margin: 3rem 0 1rem;
    display: flex;
    flex-flow: row wrap;
    position: relative;

    .checkbox {
      flex: 1 1 50%;
      font-size: .75rem;
      margin-bottom: 1rem;
      opacity: 1;
      transition: opacity .5s;
    }

    &--fetching .checkbox {
      opacity: 0;
      transition: none;
    }
  }

  textarea {
    font-size: .875rem;
    padding: .75em;
    height: 6rem;
    border: 1px solid #C0C3C3;
    border-radius: 4px;
    flex: 1 1 auto;
    width: 100%;
    margin: 0 0 2rem;
  
    &::-webkit-input-placeholder { 
      color: #8D9393;
    }
    &::-moz-placeholder {
      color: #8D9393;
    }
    &::-ms-input-placeholder {
      color: #8D9393;
    }
  }
}