@import '~Sass/variables';

.labelled-input__wrapper {
  position: relative;
}

.labelled-input {
  font-size: .75rem;
  line-height: 1em;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid #b3b3b3;
  padding: 0 .25rem;
  overflow: hidden;
  -moz-appearance: textfield;
  transition: all 200ms;

  &:focus-within {
    box-shadow: 0 0 0 1px $focus-border-color !important;
    border-color: $focus-border-color !important;
    background: $focus-background-color !important;
  }


  &.labelled-input--has-error {
    border-color: red;
    margin-bottom: .75rem;

    .labelled-input__label {
      color: red;
    }
  }

  .labelled-input__input-container {
    flex: 1 1 100%;
    color: inherit;
    font-weight: inherit;
    font-size: 1em;
  }

  .labelled-input__input {
    flex: 1 1 auto;
    text-align: right;
    font-size: inherit;
    font-family: unset;
    font-weight: inherit;
    border: none;
    outline: none;
    color: inherit;
    line-height: 1.2;
    width: 100%;
    background: inherit;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
  }

  .labelled-input__label {
    flex: 0;
    padding: 0 0 0 .25em;
    text-align: center;
    font-size: inherit;
  }

  &.labelled-input--label-left {
    flex-direction: row-reverse;

    .labelled-input__label {
      padding: 0 .25em 0 0;
    }
  }
}

.labelled-input__error {
  position: absolute;
  color: red;
  font-size: .625rem;
  top: 70%;
  white-space: nowrap;

  .labelled-input__label {
    padding: 0 .25em 0 0;
  }
}