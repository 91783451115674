.diagnostics-page {
  height: 100%;
  margin: 0 auto;
  padding: 0 5% 6em;
  width: 100%;
  
  .page-content {
    padding: 0;
    width: 100%;
  }

  .breadcrumbs {
    margin-left: 0;
  }

  .widget {
    margin: 1rem 0 2rem;
    
    h2 { 
      margin: .5em 0;
      padding: 0;
      color: #747A7A;
      font-size: 18px;
    }
  
    .pager-row {
      display: none;
    }
  }
  
  .widget-wrapper {
    max-height: 21rem;
    border: 1px solid #E5E7E7;
    border-radius: 5px;
    box-shadow: 0 3px 8px 0 rgba(32, 38, 41, 0.1);
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    position: relative; 
  }

  .widget-scroller {
    margin: 1rem 0 0;
    height: 100%;
    overflow-x: hidden;
    position: relative;
  }

  .sortable-table {
    text-align: right;

    td {
      border-bottom: 1px solid #C0C3C3;
      vertical-align: middle;
    }
  }

  .sortable-table-column--processing_Group,
  .sortable-table-column--name,
  .sortable-table-column--owner_name,
  .sortable-table-column--owner_organization_name {
    text-align: left;
    padding-left: .75rem;
    max-width: 16rem;
    word-wrap: break-word;
  }
}