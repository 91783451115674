.jwt-form {
  margin: 0 auto;
  width: 460px;
  background: #fff;
  padding: 2rem;

  .form-title {
    padding-bottom: 10px;
    border-bottom: 2px solid #e2e6eb;
    margin-bottom: 14px;
  }

  .form-description {
    font-size: .825rem;
    font-weight: 300;
    color: #2a303c;
  }

  label {
    display: block;

    & > div {
      font-size: .75rem;
      font-weight: 400;
      margin-top: 24px;
      padding-bottom: 9px;
      color: #2a303c;
    }
  }

  input {
    height: 28px;
    width: 100%;
    padding-left: 10px;
    // font-size: 12px;
    font-weight: 400;
    border-radius: 2px;
    outline: none;
    border-width: 1px;
    border-style: solid;
    border-color: #cbd0d8;
    color: #2a303c;

    &:focus {
      border: solid 1px #009aff;
      outline: none;
    }
  }
  .jwt-poc-submit-container {
    margin-top: 1.5rem;
    text-align: right;

    button {
      box-shadow: none;
      font-weight: 500;
      text-shadow: none;
      background-color: #009aff;

      &[disabled] {
        background-color: #e2e6eb;
        border: 1px solid #e2e6eb;
        color: #919191;
        opacity: 0.7;
      }
    }
  }
}
