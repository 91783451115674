// COLORS //
////////////

// Grays: "Charcoal" and "Mineral"
$charcoal-dark-10: #1a1b1d;
$charcoal: #1d1e21;
$mineral-dark-60: #333636;
$mineral-dark-50: #404444;
$mineral-dark-40: #4d5151;
$mineral-dark-30: #5a5f5f;
$mineral-dark-20: #676c6c;
$mineral-dark-10: #747a7a;
$mineral: #818888;
$mineral-light-10: #8d9393;
$mineral-light-20: #9a9f9f;
$mineral-light-30: #a6abab;
$mineral-light-40: #b3b7b7;
$mineral-light-50: #c0c3c3;
$mineral-light-60: #cccfcf;
$mineral-light-70: #d9dbdb;
$mineral-light-80: #e5e7e7;
$mineral-light-90: #f2f3f3;
$mineral-light-95: #f8f9f9;
$white: #fff;

// blues: "blue"
$blue-dark-40: #004672;
$blue-dark-10: #0069ab;
$blue: #0075bf;
$blue-light-10: #1982c5;
$blue-light-20: #3390cb;
$blue-light-30: #4c9ed2;
$blue-light-40: #66acd8;
$blue-light-50: #7fbadf;
$blue-light-60: #99c7e5;
$blue-light-70: #b2d5eb;
$blue-light-80: #cce3f2;
$blue-light-90: #e5f1f8;

// ceruleans: "rain"
$rain-dark-30: #007997;
$rain-dark-10: #009bc2;
$rain: #00add8;
$rain-light-10: #19b5db;
$rain-light-20: #33bddf;
$rain-light-30: #4cc5e3;
$rain-light-40: #66cde7;
$rain-light-50: #7fd6eb;
$rain-light-60: #99deef;
$rain-light-70: #b2e6f3;
$rain-light-80: #cceef7;
$rain-light-90: #e5f6fb;

$white-ice: #F2FAFD;

// teals: "sea"
$sea-dark-10: #00ab9a;
$sea: #00beac;
$sea-light-10: #19c4b4;
$sea-light-20: #33cbbc;
$sea-light-30: #4cd1c4;
$sea-light-40: #66d8cd;
$sea-light-50: #7fded5;
$sea-light-60: #99e5dd;
$sea-light-70: #b2ebe6;
$sea-light-80: #ccf2ee;
$sea-light-90: #e5f8f6;

// greens: "tree"
$tree-dark-10: #30a259;
$tree: #36b463;
$tree-light-10: #4abb72;
$tree-light-20: #5ec382;
$tree-light-30: #72ca91;
$tree-light-40: #86d2a1;
$tree-light-50: #9ad9b1;
$tree-light-60: #aee1c0;
$tree-light-70: #c2e8d0;
$tree-light-80: #d6f0df;
$tree-light-90: #eaf7ef;

// Other colors
$red: #ed021e;
$purple: #647ac9;
$shadow: #202629;
$warning: #ED8702;

// Text colors
$text-color: $mineral-dark-50;
$link-button-color: $rain-dark-30;

$icon-text-color: $rain-dark-30;
$table-separator-color: $mineral-light-70;

$icon-color: $rain-dark-10;

// Buttons
$primary-button-text-color: #fff;
$primary-button-background-color: $rain-dark-10;
$primary-button-background-color--focus: $rain-dark-30;
$primary-button-background-color--active: $rain-dark-30;

$secondary-button-text-color: $mineral-dark-50;
$secondary-button-background-color: $mineral-light-70;
$secondary-button-background-color--focus: $mineral-light-50;
$secondary-button-background-color--active: $mineral-light-50;

$link-button-color--hovered: $rain;

// Navbar and sidebar
$sidebar-background-color: $mineral-dark-50;
$sidebar-selected-background-color: $mineral-dark-60;
$sidebar-hover-background-color: $charcoal;
$sidebar-text-color: $mineral-light-90;
$sidebar-hairline-color: $mineral-dark-30;
$sidebar-button-color: $white;
$sidebar-icon-color: $mineral-light-40;
$sidebar-selected-icon-color: $rain-light-40;

// Form colors
$label-color: $mineral-dark-20;
$placeholder-color: $mineral-light-10;
$input-border-color: $mineral-light-60;
$focus-border-color: $rain-dark-10;
$focus-background-color: $rain-light-90;
$selected-background-color: $rain;
$radio: $rain;
$disabled-text-color: $mineral-light-50;
$disabled-background-color: $mineral-light-90;
$disabled-border-color: $mineral-light-60;

$error: $red;
$error-background: #fcebed;
$info: $blue-dark-10;
$info-background: $blue-light-90;
$loading: #e19619;
$loading-background: #f9ecd6;
$success: $tree-dark-10;
$success-background: $tree-light-90;

// Additional corporate colors
$aws-color: #58cf83; // $tree-light-20?
$azure-color: #32b3d3; // $rain-light-20?
$gce-color: #8397ea; // ??? a lighter purple

// node statuses
$node-complete: $success;
$node-pending: $loading;
$node-error: $error;

// Background colors 
$page-background-color: $white;
$modal-background: rgba($charcoal-dark-10, 0.65);
$light-shadow: 0 3px 8px rgba($shadow, 0.10);
$medium-shadow: 0 9px 15px rgba($shadow, 0.10);
$strong-shadow: 0 9px 15px rgba($shadow, 0.10), 0 15px 35px rgba($shadow, 0.20);

$summary-header-background: repeating-linear-gradient(
  135deg,
  $sea-light-70,
  $sea-light-70 2px,
  $sea-light-60 2px,
  $sea-light-60 12px
);

// Z-INDICES //
///////////////

$node-z: 1;
$node-contents-z: 2;
$custom-drag-layer-z: 0;
$modal-z: 1000000;

// LENGTHS //
/////////////

$content-padding-side: 1.8rem;
$header-padding-side: 4rem;
$tile-padding-side: 2.25rem;
$table-padding-side: 1rem;
$column-gap: 4rem;
$tab-padding-top: 2.875rem;
$para-margin: 1rem;
$section-margin: 1.8rem;

$navbar-height: 3.75rem;

$accordion-max-height: 60vh;

$table-padding: 1rem;
$table-padding-small: 0.5rem;

$button-padding-top: 0.875em;
$button-padding-bottom: 0.8125em;
$button-padding-side: 0.5rem;

$radius: 4px;
$spinner-size: 5rem;

// Screen sizes

$tablet-screen: 40em;
$laptop-screen: 63em;
$monitor-screen: 100em;

// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i);
$base-font: 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
$base-line-height: 1.5;
$header-line-height: 1.3;

$code-font: 'Source Code Pro', Menlo, Monaco, monospace;

// Transitions
$transition: 0.4s;
$button-transition: 0.2s;
