@import '~Sass/variables';

$design-editor-border-color: $mineral-light-50;
$header-border-color: $mineral-light-80;
$solution-background-color: $rain-light-90;
$solution-border-color: $rain;

$ordered-expanded-background-color: rgba(234,247,239, 1);
$ordered-border-color: $tree;

$design-item-dropdown-z-index: 1;
$dropdown-menu-z-index: 2;

.design-editor {
  border: 1px solid $design-editor-border-color;
  border-radius: 10px;
  overflow: visible;
  position: relative;
  scroll-margin: 1rem;
  // maybe hidden?

  &--is-solution {
    border: 1px solid $solution-border-color;
    box-shadow: 0 7px 23px 0 rgba(26,27,29,0.18);
  }

  &--is-ordered {
    border: 1px solid $ordered-border-color;
  }

  .design-item-group .price-margin {
    align-items: flex-end;
  }

  .design-item-row--location .design-item-row__type {
    margin-left: -1rem;

    .location-combo .react-select__single-value .icon-location {
      transform: scale(1.4);
    }
  }

  .design-item-row__location-label--internet {
    margin-left: 1rem;
  }

  .design-item-row--location,
  .design-editor__header-row {
    .react-select__control {
      width: 20rem;
    }

    .location-type .react-select__control {
      width: auto;
    }

    .colo-lookup, .lookup__address {
      .react-select__control {
        width: 30rem;
      }
    }
  }

  .design-item-row--labels {
    width: 100%;
    padding: 0 0rem 0 1rem;
    align-items: flex-end;
    font-size: .75rem;
  }

  .design-item-row__specifications {
    padding: .5rem;
  }

  .design-item-row__specifications-row {
    align-self: flex-start;
  }

  .text-input__input {
    font-size: .75rem;
    background: #fff;

    &:focus-within {
      background-color: unset;
    }
  }

  // Hack for absolute positioning of designItemDropdown to ensure open menu gets z-index priority
  .react-select__menu {
    z-index: $dropdown-menu-z-index;
  }

  .design-item-row--location + .design-item-row--location {
    margin-top: 2rem;

    &::after {
      content: none;
    }
  }

  .loading-overlay__positioning {
    max-height: none;
    background: rgba(#ccc,0.25);

    .jumbo-loading-icon {
      font-size: 3rem;
    }
  }

  .design-items__rows .design-item-row {
    &::before {
      content: '';
      position: absolute;
      left: 28px;
      top: 0;
      height: 100%;
      border-left: 1px dashed #99c7e5;
    }
    &:first-child::before {
      top: 50%;
      height: 50%;
    }
    &:last-child::before {
      height: 50%;
    }
  }
}

.design-editor__header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  border-radius: 10px 10px 0 0;

  .design-editor--is-solution & {
    background-color: $solution-background-color;
  }

  .design-editor--is-ordered & {
    background-color: $ordered-expanded-background-color;
  }
}

.design-editor__header-options {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.design-editor__terms-row {
  padding: 1.25rem 1.25rem 1.25rem 3rem;
  border-top: 1px solid $header-border-color;
  display: flex;
  flex-flow: row nowrap;

  .checkbox {
    margin: 0 0 0 auto;
  }

  .design-editor--is-solution & {
    border: none;
  }
}

.design-editor__header-options,
.design-editor__terms-row,
.design-items__add-row {
  .react-select {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
  }
}

.design-items__add-row {
  padding: 20px 20px 20px 50px;
  background-color: #f8f9f9;
}

.design-editor__design-actions {
  .button { 
    border-radius: 4px;

    & + .button { 
      margin-left: 10px;
    }
  }
}

.addDesignTypeDropdown {
  position: absolute;
  left:0;
  top: 1.5rem;
  z-index: $design-item-dropdown-z-index;

  .react-select__control {
    width: 12rem;
  }
}

.context-button-add-link {
  position: relative;

  .button {
    position: absolute;
    left: 2.25rem;
    font-size: 1.5rem;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    background: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: .5;
    top: 0.5rem;
    transition-duration: .2s;
    border-radius: 50%;

    &:hover {
      background: #fff;
      transform: scale(1.25);
      opacity: 1;
    }
  }

 &.context-button-add-link--above-location .button {
    top: -1.5rem;
  }

  &.context-button-add-link--between-locations {
    height: 1.5rem;
    display: inline-block;
  }
}