
  .ant-tree {
    li .ant-tree-node-content-wrapper:hover {
      background: transparent;
    }

    .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled) .ant-tree-checkbox-inner {
      background-color: #19b5db;
      border-color: #19b5db;
    }

    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
      background-color: #19b5db;
    }
  }

.ant-tree-switcher {
  svg[aria-hidden] {
    visibility: visible; 
  }
}