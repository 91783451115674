.breadcrumbs {
  margin: 1em 4em 0;
  color: #878A8B;	
  font-size: 14px;	
  line-height: 16px;
  list-style: none;
  padding: 0;
}

.breadcrumb {
  display: inline-flex;
  padding: 0;
  align-items: center;

  a {
    font-weight: normal;
  }

  a, span {
    max-width: 20rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .icon-caret-next {
    font-size: .875rem;
    margin: 0 .5rem;
  }
}