@import '~Sass/variables';

// The enclosing modal container
// We make the container larger than the window so that the pre-slide-in state
// goes past the window's left edge.
.drawer-container {
  width: 150vw;
  left: -50vw;
}

.drawer {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  width: 50vw;
  min-width: 36rem;
  max-width: 50rem;
  min-height: 100%;
  top: 0;
  right: 0;
  background-color: $white;
  box-shadow: $strong-shadow;
  padding: 1em;

  .close-drawer {
    padding: 1rem;
    font-size: 1rem;
    color: $mineral-light-90;

    &:hover {
      color: $mineral-light-40;
    }
  }

  .drawer-header,
  .drawer-footer {
    flex: 0 0;
  }

  .drawer-label {
    font-size: 1.2rem;
    margin-left: .5em;
  }

  .drawer-content {
    flex: 1 1 auto;
    // scroll hack for fixed-position parent
    overflow-y: auto;
    padding: 0.9rem 0.9rem 2.9rem;
  }

  .drawer-footer {
    display: flex;
    flex-flow: row nowrap;
  }
}
