.admin-pops {
  h4 {
    font-size: 1.3125rem;
    margin: .5rem 0 .25rem;
  }

  .sortable-table {
    border: 1px solid #ddd;
    border-top: none;

    .sortable-table-column {
      border-bottom: 1px solid #efefef;
    }

    thead {
      display: none;
    }
  }

  .sortable-table-column--icon {
    padding-left: 1rem;
    width: 3.5rem;

    div {
      font-size: .625rem;
      color: #0069ab;
    }
  }

  .sortable-table-column--countryCode > div {
    font-size: .625rem;
    color: #777;
  }

  .sortable-table-group-header {
    background: #ddd;
    top: 2.5rem;
  }
}

.admin-pop-list {
  max-height: 80vh;
  overflow: auto;
  margin: 1rem .25rem 1.25rem;
  border: .75rem solid #f2f2f2;
  border-radius: .25rem;
  box-shadow: 2px 2px 2px 0px rgba(33, 33, 33, .33);

  &[open] {
    margin-bottom: 2rem;
  }
}

.admin-pop-list__header {
  padding: .5rem;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background: #f2f2f2;
  color: #007997;
  cursor: pointer;

  &::before {
    color: #007997;
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border: solid 2px;
    border-left-color: transparent;
    border-bottom-color: transparent;
    margin: 0 .75rem 0 0;
    transition: transform 0.2s;
    transform: rotate(-45deg) translate(-20%, 20%);

    details[open] > & {
      transform: rotate(135deg) translate(-20%, 20%);
    }
  }
}

.admin-pops-list__no-results {
  background: #f2f2f2;
  font-size: .75rem;
  padding-left: 1.75rem;
  color: #545454;
}