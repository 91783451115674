.modal-container-add-users-popup {
  .add-user-inputs__adder {
    margin: 2rem 0;

    .icon {
      color: #fff;
      margin-right: .25rem;
    }
  }

  .add-user-inputs__remover {
    position: absolute;
    right: 0;
    font-size: .7rem;
    padding: .875em;
  }

  .add-user-inputs {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: .5rem;
    position: relative;
    padding-right: 2rem;
    
    .add-user-input-field {
      flex: 1 1;
      margin-right: .5rem;
      vertical-align: top;
    }

    .add-user-input-field--email {
      flex: 1 1 auto;
    }

    input {
      padding: .5rem;
      font-size: .825rem;
      width: 100%;
      border: 1px solid #999;
      border-radius: 3px;
    }

    .add-user-input-field--errored {
      input {
        border-color: red;
      }
    }
  }

  .add-user-input-field-error {
    font-size: .75rem;
    color: red;
  }
}