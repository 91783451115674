.visibility-toggle {
  display: flex;
  align-items: center;

  .icon-internet,
  .icon-cloud {
    font-size: 1.5rem;
    margin-left: .25rem;
    line-height: 1.5;
    color: inherit;
  }
}

.visibility-toggle__label {
  padding-left: 1rem;
  color: #545454;
}
