
$ext-view-border-color: #ccc;
$ext-view-solution-border-color: #66ACD8;

.external-cost-view {
  position: relative;
  padding: 0;
  border-top: 1px dashed $ext-view-border-color;
  border-radius: 4px;
  width: 40%;
  margin-left: auto;
  margin-right: -.25rem;

  .labelled-input__wrapper {
    margin-left: auto;
  }

  .design-item-row--read-only-inputs {
    .price-margin {
      font-size: .75rem;
    }
  }

  &::before {
    content: none !important;
  }

  &--read-only {
    margin-top: .5rem;
  }
}

.external-cost-view__add-button.button {
  position: absolute;
  bottom: 1rem;
  right: 3rem;
  font-size: .75rem;
  display: flex;
  align-items: center;
  opacity: .5;
  cursor: pointer;

  &:hover, &:focus {
    opacity: 1;
  }

  .icon { 
    font-size: 1.25rem;
    margin-right: .25rem;
  }
}

.external-cost-view__remove-button {
  font-size: .5rem;
  cursor: pointer;
  background: #3390CB;
  color: white;
  border-radius: 4px;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: .625rem;
  margin-top: 5px;
  
  .icon {
    padding: .2rem 0;
    vertical-align: middle;
    color: #fff;
  }
}


.external-cost-view__title-bar {
  position: absolute;
  left: 0;
  top: -0.65rem;
  font-size: .75rem;
  padding: 0 .25rem;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .icon-lock {
    font-size: 1.25rem;
    margin-right: .25rem;
  }
}

.external-cost-view__title-text {
  white-space: nowrap;
  color: #777;
  user-select: none;
  background: #f2f3f3;
  padding: 0 0.25rem;
  align-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
}

.external-cost-view__content {
  display: flex;
  padding: .75rem .25rem .5rem .25rem;
  font-size: .875rem;
  color: #1A1B1D;
  font-weight: normal;
  border: 1px dashed $ext-view-border-color;
  border-top: none;
  border-radius: 4px;
  
  .external-cost-view__pricing-container {
    flex: 1 1 50%;
    width: 50%;
    display: flex;
    justify-content: space-around;
    
    .price-margin {
      margin: 0;
    }
    
    .currency-input {
      margin: 0;
    }
  }
}

.external-cost-view__remove-popconfirm {
  .ant-popover-inner-content {
    max-width: 22rem;

    &::after {
      content: ' ';
      background: rgba(33,33,33,.60);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }
  }
}
