.modal-container-fileAttachmentDescriptionPopup {
  .popup .popup-content {
    padding-top: 2px;
    padding-bottom: 2rem;
    min-height: unset;
  }

  textarea {
    height: 7rem;
  }
}