// .em,
// .em-svg {
//   height: 1.5em;
//   width: 1.5em;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   display: inline-block;
//   vertical-align: middle;
// }
.em-flag {
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 1.5em;
  padding-left: 2em;
}
.em-flag-cn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f3.png");
}
.em-flag-cn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1f3.svg"), none;
}
.em-flag-de {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ea.png");
}
.em-flag-de.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e9-1f1ea.svg"), none;
}
.em-flag-es {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f8.png");
}
.em-flag-es.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1f8.svg"), none;
}
.em-flag-ac {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1e8.png");
}
.em-flag-ac.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1e8.svg"), none;
}
.em-flag-ad {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1e9.png");
}
.em-flag-ad.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1e9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1e9.svg"), none;
}
.em-flag-ae {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1ea.png");
}
.em-flag-ae.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1ea.svg"), none;
}
.em-flag-af {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1eb.png");
}
.em-flag-af.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1eb.svg"), none;
}
.em-flag-ag {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1ec.png");
}
.em-flag-ag.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1ec.svg"), none;
}
.em-flag-ai {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1ee.png");
}
.em-flag-ai.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1ee.svg"), none;
}
.em-flag-al {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f1.png");
}
.em-flag-al.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1f1.svg"), none;
}
.em-flag-am {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f2.png");
}
.em-flag-am.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1f2.svg"), none;
}
.em-flag-ao {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f4.png");
}
.em-flag-ao.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1f4.svg"), none;
}
.em-flag-aq {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f6.png");
}
.em-flag-aq.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1f6.svg"), none;
}
.em-flag-ar {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f7.png");
}
.em-flag-ar.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1f7.svg"), none;
}
.em-flag-as {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f8.png");
}
.em-flag-as.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1f8.svg"), none;
}
.em-flag-at {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f9.png");
}
.em-flag-at.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1f9.svg"), none;
}
.em-flag-au {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1fa.png");
}
.em-flag-au.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1fa.svg"), none;
}
.em-flag-aw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1fc.png");
}
.em-flag-aw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1fc.svg"), none;
}
.em-flag-ax {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1fd.png");
}
.em-flag-ax.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1fd.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1fd.svg"), none;
}
.em-flag-az {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1ff.png");
}
.em-flag-az.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e6-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e6-1f1ff.svg"), none;
}
.em-flag-ba {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1e6.png");
}
.em-flag-ba.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1e6.svg"), none;
}
.em-flag-bb {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1e7.png");
}
.em-flag-bb.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1e7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1e7.svg"), none;
}
.em-flag-bd {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1e9.png");
}
.em-flag-bd.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1e9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1e9.svg"), none;
}
.em-flag-be {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ea.png");
}
.em-flag-be.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1ea.svg"), none;
}
.em-flag-bf {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1eb.png");
}
.em-flag-bf.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1eb.svg"), none;
}
.em-flag-bg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ec.png");
}
.em-flag-bg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1ec.svg"), none;
}
.em-flag-bh {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ed.png");
}
.em-flag-bh.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1ed.svg"), none;
}
.em-flag-bi {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ee.png");
}
.em-flag-bi.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1ee.svg"), none;
}
.em-flag-bj {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ef.png");
}
.em-flag-bj.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ef.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1ef.svg"), none;
}
.em-flag-bl {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f1.png");
}
.em-flag-bl.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1f1.svg"), none;
}
.em-flag-bm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f2.png");
}
.em-flag-bm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1f2.svg"), none;
}
.em-flag-bn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f3.png");
}
.em-flag-bn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1f3.svg"), none;
}
.em-flag-bo {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f4.png");
}
.em-flag-bo.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1f4.svg"), none;
}
.em-flag-bq {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f6.png");
}
.em-flag-bq.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1f6.svg"), none;
}
.em-flag-br {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f7.png");
}
.em-flag-br.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1f7.svg"), none;
}
.em-flag-bs {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f8.png");
}
.em-flag-bs.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1f8.svg"), none;
}
.em-flag-bt {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f9.png");
}
.em-flag-bt.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1f9.svg"), none;
}
.em-flag-bv {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1fb.png");
}
.em-flag-bv.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1fb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1fb.svg"), none;
}
.em-flag-bw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1fc.png");
}
.em-flag-bw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1fc.svg"), none;
}
.em-flag-by {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1fe.png");
}
.em-flag-by.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1fe.svg"), none;
}
.em-flag-bz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ff.png");
}
.em-flag-bz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e7-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e7-1f1ff.svg"), none;
}
.em-flag-ca {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1e6.png");
}
.em-flag-ca.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1e6.svg"), none;
}
.em-flag-cc {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1e8.png");
}
.em-flag-cc.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1e8.svg"), none;
}
.em-flag-cd {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1e9.png");
}
.em-flag-cd.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1e9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1e9.svg"), none;
}
.em-flag-cf {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1eb.png");
}
.em-flag-cf.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1eb.svg"), none;
}
.em-flag-cg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1ec.png");
}
.em-flag-cg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1ec.svg"), none;
}
.em-flag-ch {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1ed.png");
}
.em-flag-ch.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1ed.svg"), none;
}
.em-flag-ci {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1ee.png");
}
.em-flag-ci.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1ee.svg"), none;
}
.em-flag-ck {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f0.png");
}
.em-flag-ck.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1f0.svg"), none;
}
.em-flag-cl {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f1.png");
}
.em-flag-cl.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1f1.svg"), none;
}
.em-flag-cm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f2.png");
}
.em-flag-cm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1f2.svg"), none;
}
.em-flag-co {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f4.png");
}
.em-flag-co.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1f4.svg"), none;
}
.em-flag-cp {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f5.png");
}
.em-flag-cp.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f5.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1f5.svg"), none;
}
.em-flag-cr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f7.png");
}
.em-flag-cr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1f7.svg"), none;
}
.em-flag-cu {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fa.png");
}
.em-flag-cu.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1fa.svg"), none;
}
.em-flag-cv {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fb.png");
}
.em-flag-cv.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1fb.svg"), none;
}
.em-flag-cw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fc.png");
}
.em-flag-cw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1fc.svg"), none;
}
.em-flag-cx {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fd.png");
}
.em-flag-cx.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fd.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1fd.svg"), none;
}
.em-flag-cy {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fe.png");
}
.em-flag-cy.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1fe.svg"), none;
}
.em-flag-cz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1ff.png");
}
.em-flag-cz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e8-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e8-1f1ff.svg"), none;
}
.em-flag-dg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ec.png");
}
.em-flag-dg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e9-1f1ec.svg"), none;
}
.em-flag-dj {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ef.png");
}
.em-flag-dj.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ef.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e9-1f1ef.svg"), none;
}
.em-flag-dk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1f0.png");
}
.em-flag-dk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e9-1f1f0.svg"), none;
}
.em-flag-dm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1f2.png");
}
.em-flag-dm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e9-1f1f2.svg"), none;
}
.em-flag-do {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1f4.png");
}
.em-flag-do.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e9-1f1f4.svg"), none;
}
.em-flag-dz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ff.png");
}
.em-flag-dz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1e9-1f1ff.svg"), none;
}
.em-flag-ea {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1e6.png");
}
.em-flag-ea.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1e6.svg"), none;
}
.em-flag-ec {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1e8.png");
}
.em-flag-ec.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1e8.svg"), none;
}
.em-flag-ee {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1ea.png");
}
.em-flag-ee.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1ea.svg"), none;
}
.em-flag-eg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1ec.png");
}
.em-flag-eg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1ec.svg"), none;
}
.em-flag-eh {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1ed.png");
}
.em-flag-eh.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1ed.svg"), none;
}
.em-flag-england {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png");
}
.em-flag-england.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg"), none;
}
.em-flag-er {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f7.png");
}
.em-flag-er.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1f7.svg"), none;
}
.em-flag-et {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f9.png");
}
.em-flag-et.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1f9.svg"), none;
}
.em-flag-eu {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1fa.png");
}
.em-flag-eu.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ea-1f1fa.svg"), none;
}
.em-flag-fi {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1ee.png");
}
.em-flag-fi.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1eb-1f1ee.svg"), none;
}
.em-flag-fj {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1ef.png");
}
.em-flag-fj.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1ef.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1eb-1f1ef.svg"), none;
}
.em-flag-fk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f0.png");
}
.em-flag-fk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1eb-1f1f0.svg"), none;
}
.em-flag-fm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f2.png");
}
.em-flag-fm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1eb-1f1f2.svg"), none;
}
.em-flag-fo {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f4.png");
}
.em-flag-fo.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1eb-1f1f4.svg"), none;
}
.em-flag-ga {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1e6.png");
}
.em-flag-ga.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1e6.svg"), none;
}
.em-flag-gd {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1e9.png");
}
.em-flag-gd.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1e9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1e9.svg"), none;
}
.em-flag-ge {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1ea.png");
}
.em-flag-ge.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1ea.svg"), none;
}
.em-flag-gf {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1eb.png");
}
.em-flag-gf.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1eb.svg"), none;
}
.em-flag-gg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1ec.png");
}
.em-flag-gg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1ec.svg"), none;
}
.em-flag-gh {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1ed.png");
}
.em-flag-gh.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1ed.svg"), none;
}
.em-flag-gi {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1ee.png");
}
.em-flag-gi.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1ee.svg"), none;
}
.em-flag-gl {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f1.png");
}
.em-flag-gl.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1f1.svg"), none;
}
.em-flag-gm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f2.png");
}
.em-flag-gm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1f2.svg"), none;
}
.em-flag-gn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f3.png");
}
.em-flag-gn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1f3.svg"), none;
}
.em-flag-gp {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f5.png");
}
.em-flag-gp.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f5.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1f5.svg"), none;
}
.em-flag-gq {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f6.png");
}
.em-flag-gq.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1f6.svg"), none;
}
.em-flag-gr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f7.png");
}
.em-flag-gr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1f7.svg"), none;
}
.em-flag-gs {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f8.png");
}
.em-flag-gs.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1f8.svg"), none;
}
.em-flag-gt {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f9.png");
}
.em-flag-gt.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1f9.svg"), none;
}
.em-flag-gu {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1fa.png");
}
.em-flag-gu.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1fa.svg"), none;
}
.em-flag-gw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1fc.png");
}
.em-flag-gw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1fc.svg"), none;
}
.em-flag-gy {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1fe.png");
}
.em-flag-gy.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1fe.svg"), none;
}
.em-flag-hk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f0.png");
}
.em-flag-hk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ed-1f1f0.svg"), none;
}
.em-flag-hm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f2.png");
}
.em-flag-hm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ed-1f1f2.svg"), none;
}
.em-flag-hn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f3.png");
}
.em-flag-hn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ed-1f1f3.svg"), none;
}
.em-flag-hr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f7.png");
}
.em-flag-hr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ed-1f1f7.svg"), none;
}
.em-flag-ht {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f9.png");
}
.em-flag-ht.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ed-1f1f9.svg"), none;
}
.em-flag-hu {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1fa.png");
}
.em-flag-hu.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ed-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ed-1f1fa.svg"), none;
}
.em-flag-ic {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1e8.png");
}
.em-flag-ic.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1e8.svg"), none;
}
.em-flag-id {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1e9.png");
}
.em-flag-id.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1e9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1e9.svg"), none;
}
.em-flag-ie {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1ea.png");
}
.em-flag-ie.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1ea.svg"), none;
}
.em-flag-il {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f1.png");
}
.em-flag-il.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1f1.svg"), none;
}
.em-flag-im {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f2.png");
}
.em-flag-im.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1f2.svg"), none;
}
.em-flag-in {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f3.png");
}
.em-flag-in.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1f3.svg"), none;
}
.em-flag-io {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f4.png");
}
.em-flag-io.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1f4.svg"), none;
}
.em-flag-iq {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f6.png");
}
.em-flag-iq.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1f6.svg"), none;
}
.em-flag-ir {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f7.png");
}
.em-flag-ir.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1f7.svg"), none;
}
.em-flag-is {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f8.png");
}
.em-flag-is.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1f8.svg"), none;
}
.em-flag-je {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ef-1f1ea.png");
}
.em-flag-je.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ef-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ef-1f1ea.svg"), none;
}
.em-flag-jm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ef-1f1f2.png");
}
.em-flag-jm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ef-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ef-1f1f2.svg"), none;
}
.em-flag-jo {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ef-1f1f4.png");
}
.em-flag-jo.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ef-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ef-1f1f4.svg"), none;
}
.em-flag-ke {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ea.png");
}
.em-flag-ke.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1ea.svg"), none;
}
.em-flag-kg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ec.png");
}
.em-flag-kg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1ec.svg"), none;
}
.em-flag-kh {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ed.png");
}
.em-flag-kh.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1ed.svg"), none;
}
.em-flag-ki {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ee.png");
}
.em-flag-ki.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1ee.svg"), none;
}
.em-flag-km {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1f2.png");
}
.em-flag-km.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1f2.svg"), none;
}
.em-flag-kn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1f3.png");
}
.em-flag-kn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1f3.svg"), none;
}
.em-flag-kp {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1f5.png");
}
.em-flag-kp.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1f5.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1f5.svg"), none;
}
.em-flag-kw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1fc.png");
}
.em-flag-kw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1fc.svg"), none;
}
.em-flag-ky {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1fe.png");
}
.em-flag-ky.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1fe.svg"), none;
}
.em-flag-kz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ff.png");
}
.em-flag-kz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1ff.svg"), none;
}
.em-flag-la {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1e6.png");
}
.em-flag-la.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1e6.svg"), none;
}
.em-flag-lb {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1e7.png");
}
.em-flag-lb.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1e7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1e7.svg"), none;
}
.em-flag-lc {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1e8.png");
}
.em-flag-lc.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1e8.svg"), none;
}
.em-flag-li {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1ee.png");
}
.em-flag-li.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1ee.svg"), none;
}
.em-flag-lk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1f0.png");
}
.em-flag-lk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1f0.svg"), none;
}
.em-flag-lr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1f7.png");
}
.em-flag-lr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1f7.svg"), none;
}
.em-flag-ls {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1f8.png");
}
.em-flag-ls.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1f8.svg"), none;
}
.em-flag-lt {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1f9.png");
}
.em-flag-lt.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1f9.svg"), none;
}
.em-flag-lu {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1fa.png");
}
.em-flag-lu.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1fa.svg"), none;
}
.em-flag-lv {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1fb.png");
}
.em-flag-lv.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1fb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1fb.svg"), none;
}
.em-flag-ly {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1fe.png");
}
.em-flag-ly.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f1-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f1-1f1fe.svg"), none;
}
.em-flag-ma {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1e6.png");
}
.em-flag-ma.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1e6.svg"), none;
}
.em-flag-mc {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1e8.png");
}
.em-flag-mc.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1e8.svg"), none;
}
.em-flag-md {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1e9.png");
}
.em-flag-md.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1e9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1e9.svg"), none;
}
.em-flag-me {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1ea.png");
}
.em-flag-me.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1ea.svg"), none;
}
.em-flag-mf {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1eb.png");
}
.em-flag-mf.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1eb.svg"), none;
}
.em-flag-mg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1ec.png");
}
.em-flag-mg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1ec.svg"), none;
}
.em-flag-mh {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1ed.png");
}
.em-flag-mh.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1ed.svg"), none;
}
.em-flag-mk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f0.png");
}
.em-flag-mk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f0.svg"), none;
}
.em-flag-ml {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f1.png");
}
.em-flag-ml.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f1.svg"), none;
}
.em-flag-mm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f2.png");
}
.em-flag-mm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f2.svg"), none;
}
.em-flag-mn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f3.png");
}
.em-flag-mn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f3.svg"), none;
}
.em-flag-mo {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f4.png");
}
.em-flag-mo.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f4.svg"), none;
}
.em-flag-mp {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f5.png");
}
.em-flag-mp.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f5.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f5.svg"), none;
}
.em-flag-mq {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f6.png");
}
.em-flag-mq.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f6.svg"), none;
}
.em-flag-mr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f7.png");
}
.em-flag-mr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f7.svg"), none;
}
.em-flag-ms {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f8.png");
}
.em-flag-ms.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f8.svg"), none;
}
.em-flag-mt {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f9.png");
}
.em-flag-mt.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1f9.svg"), none;
}
.em-flag-mu {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fa.png");
}
.em-flag-mu.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1fa.svg"), none;
}
.em-flag-mv {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fb.png");
}
.em-flag-mv.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1fb.svg"), none;
}
.em-flag-mw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fc.png");
}
.em-flag-mw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1fc.svg"), none;
}
.em-flag-mx {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fd.png");
}
.em-flag-mx.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fd.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1fd.svg"), none;
}
.em-flag-my {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fe.png");
}
.em-flag-my.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1fe.svg"), none;
}
.em-flag-mz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1ff.png");
}
.em-flag-mz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f2-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f2-1f1ff.svg"), none;
}
.em-flag-na {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1e6.png");
}
.em-flag-na.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1e6.svg"), none;
}
.em-flag-nc {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1e8.png");
}
.em-flag-nc.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1e8.svg"), none;
}
.em-flag-ne {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1ea.png");
}
.em-flag-ne.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1ea.svg"), none;
}
.em-flag-nf {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1eb.png");
}
.em-flag-nf.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1eb.svg"), none;
}
.em-flag-ng {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1ec.png");
}
.em-flag-ng.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1ec.svg"), none;
}
.em-flag-ni {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1ee.png");
}
.em-flag-ni.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1ee.svg"), none;
}
.em-flag-nl {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1f1.png");
}
.em-flag-nl.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1f1.svg"), none;
}
.em-flag-no {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1f4.png");
}
.em-flag-no.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1f4.svg"), none;
}
.em-flag-np {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1f5.png");
}
.em-flag-np.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1f5.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1f5.svg"), none;
}
.em-flag-nr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1f7.png");
}
.em-flag-nr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1f7.svg"), none;
}
.em-flag-nu {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1fa.png");
}
.em-flag-nu.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1fa.svg"), none;
}
.em-flag-nz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1ff.png");
}
.em-flag-nz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f3-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f3-1f1ff.svg"), none;
}
.em-flag-om {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f4-1f1f2.png");
}
.em-flag-om.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f4-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f4-1f1f2.svg"), none;
}
.em-flag-pa {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1e6.png");
}
.em-flag-pa.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1e6.svg"), none;
}
.em-flag-pe {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1ea.png");
}
.em-flag-pe.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1ea.svg"), none;
}
.em-flag-pf {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1eb.png");
}
.em-flag-pf.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1eb.svg"), none;
}
.em-flag-pg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1ec.png");
}
.em-flag-pg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1ec.svg"), none;
}
.em-flag-ph {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1ed.png");
}
.em-flag-ph.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1ed.svg"), none;
}
.em-flag-pk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f0.png");
}
.em-flag-pk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1f0.svg"), none;
}
.em-flag-pl {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f1.png");
}
.em-flag-pl.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1f1.svg"), none;
}
.em-flag-pm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f2.png");
}
.em-flag-pm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1f2.svg"), none;
}
.em-flag-pn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f3.png");
}
.em-flag-pn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1f3.svg"), none;
}
.em-flag-pr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f7.png");
}
.em-flag-pr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1f7.svg"), none;
}
.em-flag-ps {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f8.png");
}
.em-flag-ps.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1f8.svg"), none;
}
.em-flag-pt {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f9.png");
}
.em-flag-pt.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1f9.svg"), none;
}
.em-flag-pw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1fc.png");
}
.em-flag-pw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1fc.svg"), none;
}
.em-flag-py {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1fe.png");
}
.em-flag-py.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f5-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f5-1f1fe.svg"), none;
}
.em-flag-qa {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f6-1f1e6.png");
}
.em-flag-qa.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f6-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f6-1f1e6.svg"), none;
}
.em-flag-re {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1ea.png");
}
.em-flag-re.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f7-1f1ea.svg"), none;
}
.em-flag-ro {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1f4.png");
}
.em-flag-ro.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f7-1f1f4.svg"), none;
}
.em-flag-rs {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1f8.png");
}
.em-flag-rs.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f7-1f1f8.svg"), none;
}
.em-flag-rw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1fc.png");
}
.em-flag-rw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f7-1f1fc.svg"), none;
}
.em-flag-sa {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1e6.png");
}
.em-flag-sa.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1e6.svg"), none;
}
.em-flag-sb {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1e7.png");
}
.em-flag-sb.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1e7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1e7.svg"), none;
}
.em-flag-sc {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1e8.png");
}
.em-flag-sc.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1e8.svg"), none;
}
.em-flag-scotland {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png");
}
.em-flag-scotland.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg"), none;
}
.em-flag-sd {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1e9.png");
}
.em-flag-sd.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1e9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1e9.svg"), none;
}
.em-flag-se {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ea.png");
}
.em-flag-se.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1ea.svg"), none;
}
.em-flag-sg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ec.png");
}
.em-flag-sg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1ec.svg"), none;
}
.em-flag-sh {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ed.png");
}
.em-flag-sh.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1ed.svg"), none;
}
.em-flag-si {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ee.png");
}
.em-flag-si.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1ee.svg"), none;
}
.em-flag-sj {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ef.png");
}
.em-flag-sj.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ef.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1ef.svg"), none;
}
.em-flag-sk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f0.png");
}
.em-flag-sk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1f0.svg"), none;
}
.em-flag-sl {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f1.png");
}
.em-flag-sl.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1f1.svg"), none;
}
.em-flag-sm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f2.png");
}
.em-flag-sm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1f2.svg"), none;
}
.em-flag-sn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f3.png");
}
.em-flag-sn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1f3.svg"), none;
}
.em-flag-so {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f4.png");
}
.em-flag-so.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1f4.svg"), none;
}
.em-flag-sr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f7.png");
}
.em-flag-sr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1f7.svg"), none;
}
.em-flag-ss {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f8.png");
}
.em-flag-ss.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1f8.svg"), none;
}
.em-flag-st {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f9.png");
}
.em-flag-st.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1f9.svg"), none;
}
.em-flag-sv {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1fb.png");
}
.em-flag-sv.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1fb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1fb.svg"), none;
}
.em-flag-sx {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1fd.png");
}
.em-flag-sx.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1fd.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1fd.svg"), none;
}
.em-flag-sy {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1fe.png");
}
.em-flag-sy.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1fe.svg"), none;
}
.em-flag-sz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ff.png");
}
.em-flag-sz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f8-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f8-1f1ff.svg"), none;
}
.em-flag-ta {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1e6.png");
}
.em-flag-ta.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1e6.svg"), none;
}
.em-flag-tc {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1e8.png");
}
.em-flag-tc.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1e8.svg"), none;
}
.em-flag-td {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1e9.png");
}
.em-flag-td.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1e9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1e9.svg"), none;
}
.em-flag-tf {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1eb.png");
}
.em-flag-tf.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1eb.svg"), none;
}
.em-flag-tg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1ec.png");
}
.em-flag-tg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1ec.svg"), none;
}
.em-flag-th {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1ed.png");
}
.em-flag-th.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1ed.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1ed.svg"), none;
}
.em-flag-tj {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1ef.png");
}
.em-flag-tj.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1ef.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1ef.svg"), none;
}
.em-flag-tk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f0.png");
}
.em-flag-tk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f0.svg"), none;
}
.em-flag-tl {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f1.png");
}
.em-flag-tl.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f1.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f1.svg"), none;
}
.em-flag-tm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f2.png");
}
.em-flag-tm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f2.svg"), none;
}
.em-flag-tn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f3.png");
}
.em-flag-tn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f3.svg"), none;
}
.em-flag-to {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f4.png");
}
.em-flag-to.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f4.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f4.svg"), none;
}
.em-flag-tr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f7.png");
}
.em-flag-tr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f7.svg"), none;
}
.em-flag-tt {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f9.png");
}
.em-flag-tt.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f9.svg"), none;
}
.em-flag-tv {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1fb.png");
}
.em-flag-tv.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1fb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1fb.svg"), none;
}
.em-flag-tw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1fc.png");
}
.em-flag-tw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1fc.svg"), none;
}
.em-flag-tz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1ff.png");
}
.em-flag-tz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f9-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1ff.svg"), none;
}
.em-flag-ua {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1e6.png");
}
.em-flag-ua.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fa-1f1e6.svg"), none;
}
.em-flag-ug {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1ec.png");
}
.em-flag-ug.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fa-1f1ec.svg"), none;
}
.em-flag-um {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f2.png");
}
.em-flag-um.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fa-1f1f2.svg"), none;
}
.em-flag-un {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f3.png");
}
.em-flag-un.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fa-1f1f3.svg"), none;
}
.em-flag-uy {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1fe.png");
}
.em-flag-uy.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1fe.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fa-1f1fe.svg"), none;
}
.em-flag-uz {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1ff.png");
}
.em-flag-uz.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1ff.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fa-1f1ff.svg"), none;
}
.em-flag-va {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1e6.png");
}
.em-flag-va.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fb-1f1e6.svg"), none;
}
.em-flag-vc {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1e8.png");
}
.em-flag-vc.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1e8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fb-1f1e8.svg"), none;
}
.em-flag-ve {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1ea.png");
}
.em-flag-ve.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fb-1f1ea.svg"), none;
}
.em-flag-vg {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1ec.png");
}
.em-flag-vg.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1ec.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fb-1f1ec.svg"), none;
}
.em-flag-vi {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1ee.png");
}
.em-flag-vi.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1ee.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fb-1f1ee.svg"), none;
}
.em-flag-vn {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1f3.png");
}
.em-flag-vn.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1f3.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fb-1f1f3.svg"), none;
}
.em-flag-vu {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1fa.png");
}
.em-flag-vu.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fb-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fb-1f1fa.svg"), none;
}
.em-flag-wales {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png");
}
.em-flag-wales.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg"), none;
}
.em-flag-wf {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fc-1f1eb.png");
}
.em-flag-wf.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fc-1f1eb.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fc-1f1eb.svg"), none;
}
.em-flag-ws {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fc-1f1f8.png");
}
.em-flag-ws.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fc-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fc-1f1f8.svg"), none;
}
.em-flag-xk {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fd-1f1f0.png");
}
.em-flag-xk.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fd-1f1f0.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fd-1f1f0.svg"), none;
}
.em-flag-ye {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fe-1f1ea.png");
}
.em-flag-ye.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fe-1f1ea.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fe-1f1ea.svg"), none;
}
.em-flag-yt {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fe-1f1f9.png");
}
.em-flag-yt.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fe-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fe-1f1f9.svg"), none;
}
.em-flag-za {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ff-1f1e6.png");
}
.em-flag-za.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ff-1f1e6.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ff-1f1e6.svg"), none;
}
.em-flag-zm {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ff-1f1f2.png");
}
.em-flag-zm.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ff-1f1f2.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ff-1f1f2.svg"), none;
}
.em-flag-zw {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ff-1f1fc.png");
}
.em-flag-zw.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ff-1f1fc.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ff-1f1fc.svg"), none;
}
.em-flag-fr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f7.png");
}
.em-flag-fr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1eb-1f1f7.svg"), none;
}
.em-flag-gb {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1e7.png");
}
.em-flag-gb.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ec-1f1e7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ec-1f1e7.svg"), none;
}
.em-flag-it {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f9.png");
}
.em-flag-it.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f9.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ee-1f1f9.svg"), none;
}
.em-flag-jp {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ef-1f1f5.png");
}
.em-flag-jp.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ef-1f1f5.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1ef-1f1f5.svg"), none;
}
.em-flag-kr {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1f7.png");
}
.em-flag-kr.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f0-1f1f7.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f0-1f1f7.svg"), none;
}
.em-flag-ru {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1fa.png");
}
.em-flag-ru.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1f7-1f1fa.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f7-1f1fa.svg"), none;
}
.em-flag-us {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f8.png");
}
.em-flag-us.em-svg {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f8.png");
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1fa-1f1f8.svg"), none;
}