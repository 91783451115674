.tooltip {
  &.ant-tooltip-placement-top, 
  &.ant-tooltip-placement-topLeft, 
  &.ant-tooltip-placement-topRight {
    padding: 0;
  }

  &.tooltip--full-width {
    max-width: none;
  }

  // override!!!
  &.ant-tooltip-placement-top .ant-tooltip-arrow {
    transform: translateX(-50%) !important;
  }

  .ant-tooltip-content {
    border-radius: 4px;
    box-shadow: 0 0 .5em;
    color: rgba(26, 26, 29, 0.75);
    background-color: rgba(26, 26, 29, 0.85);
  }

  .ant-tooltip-arrow {
    top: 100%;
    bottom: 0;
    font-size: .75rem; // width of arrow
    height: 1em;
    width: 1em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-top: .6em solid;
    
    &::before {
      content: none;
    }
  }

  .ant-tooltip-inner {
    background-color: transparent;
    padding: .5em 1em;
  }
}