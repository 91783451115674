.delete-folder__table td {
  text-align: center;
}

.delete-item-modal__error {
  color: red;
  margin: 1em 0;
}

.delete-item-modal__denied-quotes {
  .sortable-table {
    th, td {
      padding-left: 1em;
      border-bottom: 1px solid #C0C3C3;
    }
  }
}