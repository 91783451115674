@import '~Sass/variables';

.location-combo {
  position: relative;
  display: flex;
  flex-flow: row nowrap;

  .lookup.location-type {
    flex: 0 0 0;

    .react-select__control {
      background-color: #f2f2f2;
      transition: all .2s;
    }
    .react-select__control--is-focused {
      z-index: 1;
    }

    .react-select__menu {
      min-width: 15rem;
    }
  }

  .lookup.location-type + .lookup {
    flex: 1 1 auto;
    
    .react-select__control {
      border-left: none;
    }

    .react-select__control:not(.react-select__control--is-disabled) {
      border-radius: 0 4px 4px 0;
      background-color: hsl(0,0%,100%);

      &.react-select__control--is-focused {
        border-left: 1px solid;
        background: $focus-background-color;
      }
    }

    &.error .react-select__control{
      border-color: red;
      border-left: 1px solid red;
    }

    .react-select__dropdown-indicator {
      display: none;
    }
  }

  .react-select__single-value--is-disabled {
    color: inherit;
  }

  .lookup.locationA, .lookup.locationZ {
    .react-select__group {
      padding: 0;
    }

    .react-select__menu-list {
      margin-top: 0;
      padding-top: 0;

      .icon-location {
        align-self: baseline;
        font-size: 1.75rem;
        margin-left: -.25rem;
        margin-right: .25rem;      
      }
    }

    .react-select__group-heading {
      margin: 0;
      padding: .5rem;
      background-color: #F2F3F3;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 2;

      .icon-history {
        font-size: 1.25rem;
        margin-right: .25rem;
      }
    }
  }

  .lookup__address--showing-floor-suite {
    margin-bottom: 1rem;
  }

  .floor-suite {
    position: absolute;
    top: calc(100% - 1rem);
    right: 0;
    text-align: right;
    padding: .25rem .5rem;
    max-width: 100%;

    &--expanded {
      z-index: 1;
      margin-top: .25rem;
      border: 1px solid #E5E7E7;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0 9px 15px 0 rgba(32,38,41,0.1);
    }

    .text-input {
      text-align: left;
      margin-bottom: .5rem;
    }
  }

  .toggleable {
    max-height: 10rem;
    opacity: 1;
    transition: max-height 500ms;
    visibility: visible;
    overflow: hidden;
  }
  
  .toggleable--collapsed {
    max-height: 0;
    overflow: hidden;
    transition: border 500ms, max-height 500ms;
    opacity: 0;
    
    input { 
      visibility: hidden;
    }
  }

  .toggler.button-link {
    margin: 0 0 .25rem;
    font-size: .75rem;
    padding: .125rem .25rem;
    display: inline-flex;
    align-items: center;
    width: 100%;

    .icon.icon-caret-down {
      flex: 0 0;
      color: inherit;
      font-size: 1.25rem;

      svg {
        fill: currentColor;
        transition: transform 200ms;
      }
    }
  
    &.toggler--collapse .icon-caret-down svg {
      transform: rotate(180deg);
    }
  }

  .floor-suite__toggler-text {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}    