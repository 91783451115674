.manual-pricing {
  margin-top: 3rem;
  
  .icon-question-mark {
    color: #0069AB;
    font-size: 1.5rem;
    margin: 0 .5rem;
  }  
}

.manual-pricing__header {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #E5E7E7;
  padding-bottom: .25rem;
  color: #202629;
}

.manual-pricing__content {
  padding: 1rem 2.5rem;
  color: #333636;
  font-size: 1rem;

  ul {
    margin-top: 0.5rem;
  }
  
  li {
    list-style-type: disc;
    padding: 0;
  }
}
