@import '~Sass/variables';

$quote-requirements-bg-color: $mineral-light-90;
$quote-requirements-color: $mineral-dark-50;
$quote-requirements-icon-color: $mineral-dark-20;
$quote-requirements-title-color: $mineral-dark-20;
$divider-color: $mineral-light-60;

.quote-spec {
  background-color: $quote-requirements-bg-color;
  color: $quote-requirements-color;
  padding: 0 1.25rem .5rem;
  min-height: 6rem;
  position: relative;
  border-radius: 0 0 8px 8px;

  .loading-icon {
    width: 100%;
    text-align: center;
  }

  .quote-spec__value {
    color: $quote-requirements-color;
    text-align: left;
    line-height: 1.2;
    font-size: .875rem;
    font-weight: 500;
  }

  .quote-spec__label {
    font-size: .75rem;
    font-weight: 500;
  }

  .quote-spec-options {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .label {
      font-size: .75rem;
    }
  }

  .quote-spec-location {
    flex: 1 1 auto;
    padding-left: 2rem;
    position: relative;
    margin-bottom: .75rem;

    .icon {
      position: absolute;
      top: -.1rem;
      left: 0;
      font-size: 1.5rem;
      color: $quote-requirements-icon-color;
    }

    .location-icon--has-tooltip {
      cursor: pointer;
    }

    .quote-spec-location__xconn {
      font-size: .75rem;
      margin-top: .125rem;
      position: relative;
      display: inline-flex;

      .icon { 
        position: relative;
        margin: 0 0.125rem 0 -0.125rem;
        font-size: 1rem;
        cursor: default;
      }
    }
  }

  .quote-spec-location__user-rate,
  .quote-spec-location__ipv4 {
    position: relative;
    font-weight: 400;
    font-size: .75rem;
  }

  .quote-spec__follow-up {
    font-weight: bold;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon {
      font-size: 1.25rem;
      color: inherit;
    }
  }

  .quote-spec-connection {
    flex: 0 0 auto;
    display: flex;
    margin-bottom: .75rem;

    > div + div {
      margin-left: 2rem;
    }
  }

  .dt {
  }

  .dd {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }

  .quote-spec__toggle-button.button {
    font-size: .65rem;
  }
}

.quote-spec__bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.quote-spec__tags {
  flex: 1 1 100%;
  font-size: .75rem;

  // &:empty {
  //   margin: 0;
  // }
}

.quote-spec__tag {
  flex: 0 0 auto;
  display: inline-block;
  margin-right: 1rem;
  background: #999;
  padding: 0.1rem 0.4rem;
  border-radius: 4px;
  overflow: hidden;
  color: white;

  .quote-spec__tag-extra {
    max-width: 0;
    opacity: 0;
    display: inline-block;
    white-space: nowrap;
    transition: all 350ms ease-in;
    transition-delay: 300ms;
  }

  &:hover .quote-spec__tag-extra {
    max-width: 100%;
    opacity: 1;
    transition: all 350ms ease-out;
    transition-delay: 0ms;
  }
}

.quote-spec-title {
  color: $quote-requirements-title-color;
  font-size: .75rem;
  border-top: 1px solid $divider-color;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: .25rem;
  min-height: 1rem;

  .icon-user {
    margin-top: 2px;
  }

  time::before {
    content: '-';
    margin: 0 .25rem;
  }
}

.quote-spec-advanced {
  .quote-spec-advanced-options,
  .quote-spec-partner-data {
    display: flex;
    flex-flow: row wrap;
    font-size: .875rem;
    line-height: 18px;
    color: #C0C3C3;
  
    .advanced-option { 
      margin-right: 3em;
      margin-top: 1em;
    }

    .advanced-option__value {
      color: #fff;
    }
  }
}

.quote-spec-disclaimer {
  margin-top: 1rem;
  font-size: .65rem;
  line-height: 1.2;
}

.quote-location-tooltip__user-input {
  font-size: .75rem;
  
  dt {
    font-weight: bold;
  }

  dd {
    line-height: 1.2;
    margin: 0 0 .5rem;
  }
}

.quote-spec-location__latlong {
  font-weight: 400;
  font-size: .75rem;
  color: #999;
  margin-top: .25rem;
}