$drawer-padding: 1.75rem;

.modal-container-importQuotes {
  .drawer {
    width: auto;
    padding: 0;
    justify-content: space-between;
  }

  .close-drawer {
    margin: 0;
  }

  .drawer-content {
    position: relative;
    padding: 0;
  }

  .scrollable-content {
    position: absolute;
    z-index: 0;
    width: 100%;
    padding: 1rem 0;
  }

  .notification--warning {
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .lookup {
    &.folder,
    &.customer {
      .react-select__control {
        background-color: #F2F3F3;
        color: #000;
      }
    }

    &.folder {
      margin: 1.25rem 0 1rem;
  
      .react-select__dropdown-indicator {
        display: none;
      }
    }
  }

  .import-quotes-modal-section-title {
    font-size: .75rem;
    color: #5A5F5F;
    margin: 2em 0 .5em;
  }

  .import-file-label__error {
    color: red;
  }

  .import-quotes-modal-section {
    position: relative;
    font-size: .75rem;
    padding: 1rem;
    background-color: #F2F3F3;
    display: flex;
    align-items: center;

    &--error {
      border: 1px solid red;
    }

    .import-button {
      font-size: .75rem;
      padding: .75em 2em;
    }

    .icon-spreadsheet {
      font-size: 1.5rem;
      margin-right: .25rem;
    }

    .import-file-label {
      padding-left: 1em;
    }

    .download-template-button {
      display: inline-flex;
      align-items: center;
      font-size: .75rem;

      &[disabled] {
        opacity: .5;
      }
    }

    .jumbo-loading-icon {
      font-size: 2rem;
    }
  }

  .toggler {
    margin: 0 0 0 $drawer-padding;
    font-size: .75rem;
    padding: 5px;
    display: inline-flex;
    align-items: center;

    .icon.icon-caret-down {
      color: inherit;
      font-size: 1.25rem;

      svg {
        fill: currentColor;
        transition: transform 200ms;
      }
    }
  
    &.toggler--collapse .icon-caret-down svg {
      transform: rotate(180deg);
    }
  }

  .toggled-container {
    margin: 0;
  }

  .partner-pricing {
    margin: .5rem 0 0;
  }

  .partner-pricing-options .lookup { 
    margin: 1rem 0; 
  }

  .partner-data-wrapper.toggleable {
    padding: 0;
    margin: 0;
  }

  .text-input {
    position: relative;
    height: 50px;
    font-size: 14px;
    color: #5A5F5F;
    border: 1px solid #D9DBDB;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .text-input__input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem 0 0 1rem;
    font-size: 14px;
  }
  
  .text-input__label {
    position: absolute;
    font-size: .875rem;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    transition: all 300ms;
  
    input:focus + &,
    input:valid + & {
      top: 25%;
      font-size: .675rem;
    }
  }

  .import-data-container {
    padding: 1rem $drawer-padding 3rem;
  }

  .loading-overlay {
    background: rgba(255, 255, 255, 0.5);
  }
}