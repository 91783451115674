.support-band {
  margin: 3em auto 0;
  font-weight: normal;
  color: #747A7A;
  font-size: .9375rem;
  text-align: center;

  .icon-support {
    font-size: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: .2em;
    margin-top: -2px;
  }

  .support__text--need-help {
    font-weight: 500;
  }

  .support__link {
    margin: 0 4px;
    white-space: nowrap;
  }
}