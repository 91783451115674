@import '~Sass/variables';

.tile {
  align-self: stretch; // tiles should be equal height in a display:flex group
  position: relative;
  width: 100%; // flexbox equal-width hack
  margin: 1rem 0;
  box-shadow: $medium-shadow;
  background-color: $white;
  padding: $tile-padding-side;
  transition: $transition;

  &:hover {
    box-shadow: $strong-shadow;
  }

  &.requirements-tile {
    max-width: 420px;
  }
  
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
