.modal-container-bulk-comment-modal {
  p {
    font-size: .75rem;

    .icon {
      font-size: 1.25rem;
      margin-right: 2px;
    }
  }

  .text-input {
    margin-top: 1rem;
    height: 8rem;

    .text-input__input-wrapper {
      height: 100%;
    }
  }

  .text-input__input--textarea {
    font-size: 1rem;
    line-height: 1.4;
    padding: .75rem 1rem;
  }
}