.quote-vendor-report {
  .sortable-table {
    margin-top: 1rem;
    color: #5A5F5F;
    font-size: .875rem;
  }

  .sortable-table-column__header--proximity {
    width: 15%
  }

  .sortable-table-column__header--result {
    width: 16%
  }

  .sortable-table-column__header--description {
    width: 20%
  }

  td.sortable-table-column {
    height: 3rem;
    border-bottom: 1px solid #eee;
    vertical-align: middle;
  }

  .sortable-table-column--icon {
    width: .1px;
  }

  .icon-vendor-api,
  .icon-vendor-rate-card {
    font-size: 1.25rem;

    svg {
      height: 1.6em;
      width: 1em;
    }
  }

  .sortable-table-column--productType {
    width: 10%;
    font-size: .75rem;
  }

  .sortable-table-column--result {
    white-space: nowrap;
  }

  .sortable-table-column--proximity {
    .proximity::before {
      content: ' ';
      margin-right: 0.25rem;
      vertical-align: -2px;
      font-size: 0.875rem;
      width: 1em;
      height: 1em;
      display: inline-block;
      border: 3px solid #C0C3C3;
      background-color: #fff;
      border-radius: 50%;
    }
    .proximity--On-Net::before {
      border-color: #36B463;
    }
    .proximity--Near-Net::before {
      border-color: #F2AB4D;
    }
    .proximity--Off-Net::before {
      border-color: #ED021E;
    }
  }

  .status-icon {
    display: inline-flex;
    align-items: center;

    .icon { 
      margin-right: .125rem;
    }
  }

  .proximity__via {
    font-size: .625rem;
    color: #747474;
  }
}

.quote-vendor-report__header {
  margin: 1rem 0 3rem;
  display: flex;
  align-items: center;
  gap: 3rem;

  h3 {
    padding: 0;
    margin: 0;
  }

  .labelled-input {
    border-radius: 4px;
    font-size: .875rem;

    .icon {
      font-size: 1.25rem;
    }
  }

  .labelled-input__input {
    text-align: left;
    font-size: .875rem;
    padding: .25rem;
  }

  .checkbox {
    margin-left: auto;
  }
}