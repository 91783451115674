@import '~Sass/variables';

.quote-comment {
  margin-bottom: 1rem;
  
  &--internal {
    background: $rain-light-90;

    .quote-comment__header {
      border-color: #B2D5EB;
    }
  }
}

.quote-comment__header {
  color: #818888;
  font-size: .75rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  border-bottom: 1px solid #D9DBDB;

  .icon {
    font-size: 1.5rem;
    vertical-align: middle;
  }

  .icon-internet,
  .icon-cloud {
    margin-left: .25rem;
  }
}

.quote-comment__user {
  color: $rain-dark-30;
}

.quote-comment__message {
  color: #1A1B1D;
  padding: .75rem 1.35rem 1.5rem;
  white-space: pre-line;
}

.quote-comment__form-textarea {
  padding: 1rem;
  font-size: .825rem;
  line-height: 1rem;
  width: 100%;
  height: 3rem;
  min-height: 3rem;
  background-color: rgba(242,243,243,0.5);
  border-color: #cccfcf;
  border-radius: 3px;
  transition: min-height .1s, background-color .3s;

  .quote-comment__form:hover &,
  &:active, 
  &:focus,
  &:valid {
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(33,33,33,.3);
    min-height: 6rem;

    & + .quote-comment__form-actions {
      display: flex;
      opacity: 1;
    }
  }
}

.quote-comment__form-actions {
  opacity: 0;
  display: none;
  transition: opacity .5s;
  flex-flow: row nowrap;
  align-items: center;

  .icon {
    font-size: 1.5rem;
    line-height: 1.5;
    color: inherit;
  }
}

.quote-comments__submit-button {
  margin-left: auto;
}