@import '~Sass/variables';

$folder-text-color: $mineral-dark-30;

.modal-container-move-quote-modal {
  .popup-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .selectable-folder-items {
    margin: 0;
    padding: 0;
    cursor: pointer;

    .selectable-folder-item {
      color: $folder-text-color;
      list-style: none;
      padding: .75em;
      margin: 0;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      transition: background-color .2s;

      &:hover {
        background-color: $focus-background-color;
      }

      & + .selectable-folder-item {
        border-top: 1px solid $table-separator-color;
      }

      .icon {
        font-size: 2rem;
        transform: translateY(2px);
        margin-right: .35em;
        align-self: center;
      }

      &.--selected {
        color: #e9e9e9;
        background-color: $selected-background-color;
      }
    }
  }
}