@import '~Sass/variables';

$border-color: $mineral-light-60;

.search-input {
  height: 100%;
  position: relative;
  display: flex;
  line-height: 3.5rem;
  background-color: $mineral-light-95;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  transition: all 200ms;

  &.search-input--errored {
    background-color: #FCEDEE;
    border-bottom: 1px solid red;
    color: #000;

    .icon-exclamation-point-circle-outline {
      color: red
    }

    .search-input__error-label {
      color: red;
      font-size: 11px;
      font-weight: bold;
      display: inline;
      line-height: 3.5em;
      height: 1em;
    }
  }

  &.search-input--collapsed {
    background-color: transparent;
    border: none;

    .search-input__input,
    .search-input__error-label {
      display: none;
    }
  }

  &.search-input--expanded:focus-within {
    box-shadow: 0 0 0 1px $focus-border-color;
    border-color: $focus-border-color;
    background-color: $blue-light-90;
  }
}

.search-input__button.button {
  padding: 1rem;
}

.search-input__icon {
  font-size: 2em;
}

.search-input__form {
  vertical-align: top;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  height: 100%;
  padding: 0;
  font-size: 14px;
  width: 240px;
  transition: width 200ms;

  .search-input--collapsed & {
    width: 0;
  }
}

.search-input__input {
  height: 100%;
  background: transparent;
  border: none;
  color: $text-color;
  outline: none;
  font-size: .875rem;
  font-family: $base-font;

  &::placeholder {
    color: #999;
  }
}

.search-input__cancel {
  position: absolute;
  right: 2rem;
  z-index: 2;
}