.create-quote-page {
  margin-bottom: 3rem;

  .page-content {
    padding-bottom: 1rem;
  }

  .support-band {
    margin-top: 1rem;
  }
}

.page-status--confirm-order {
  margin: .5rem 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;

  .icon {
    color: #ED8702;
    font-size: 1.5rem;
    margin-right: .25rem;
  }
}