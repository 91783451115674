.lookup.location-type {
  .react-select__control {
    display: inline-flex;
    border-radius: 4px 0 0 4px;
  }

  .react-select__value-container {
    font-size: 1.375rem;
    width: 1.5em;
    text-align: right;
  }

  .react-select__single-value {
    overflow: visible;
  }

  .react-select__dropdown-indicator {
    padding-left: 0;
  }
}