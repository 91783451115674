// Imports
@import 'variables';
@import 'ant_overrides';
@import 'typography/typography';
@import 'theme';

// Accessibility

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

// General

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline-color: $rain-dark-10;
}

// Block-level elements

body {
  overflow-x: hidden;
  margin: 0;
  font-family: $base-font;
  line-height: $base-line-height;
  color: $text-color;

  &.scroll-locked {
    // "disable" scrolling when modals are visible
    overflow: hidden;
  }
}

p {
  margin-top: 0;
}

ul {
  list-style-type: square;
}

li {
  padding-bottom: 0.5em;
}

hr {
  border-width: 0;
  border-bottom: 1px solid $mineral-light-60;

  &.secondary {
    border-bottom-color: $rain-light-50;
  }
}

address {
  font-style: normal;
}

// Inline elements

svg {
  // follows the text color of the surrounding element
  // but is overrideable within the elements of the svg
  fill: currentColor;

  text {
    stroke-width: 0;
  }
}

img {
  width: 100%;

  &.inline { // rarely, we do want it displayed inline
    width: auto;
  }
}

code {
  font-family: $code-font;
  background-color: $blue-light-90;
  padding: 0.125em 0.15em;
}

pre {
  margin-top: $base-line-height * 1rem;
  margin-bottom: $base-line-height * 1rem;
  background-color: $blue-light-90;
  padding: 1rem;

  code {
    background-color: unset;
    padding: unset;
  }
}

samp {
  font-family: $code-font;
}

// Headers

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1.5 * $header-line-height * 1rem;
  margin-bottom: $header-line-height * 1rem;
  line-height: $header-line-height;
}

h1,
.h1 {
  font-size: 1.75rem;
  font-weight: 500;
}

h2,
.h2 {
  font-size: 1.525rem;
  font-weight: 500;
}

h3,
.h3 {
  font-size: 1.325rem;
  font-weight: 500;
}

h4,
.h4 {
  font-size: 1.25rem;
  font-weight: 500;
}

h5,
.h5 {
  font-size: 1rem;
  font-weight: 700;
}

h6,
.h6 {
  font-size: 0.875rem;
  font-weight: 700;
}

// Anchors

a {
  color: inherit;
  font-weight: 500;
}

.link {
  color: $link-button-color;
  font-weight: inherit;

  &:hover {
    color: $link-button-color--hovered;
  }
}
textarea {
  font-family: inherit;
  
  &:focus {
    box-shadow: 0 0 0 1px $focus-border-color !important;
    border-color: $focus-border-color !important;
    background: $focus-background-color !important;
  }
}

.no-select {
  -webkit-touch-callout: none; //* iOS Safari
    -webkit-user-select: none; // Safari
     -khtml-user-select: none; // Konqueror HTML
       -moz-user-select: none; // Firefox
        -ms-user-select: none; // Internet Explorer/Edge
            user-select: none; // Non-prefixed version
}

// COMPONENTS //
////////////////

.secure-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.secure-content {
  flex: 1;
  display: flex;
  position: relative;
  //height: calc(100vh - #{$navbar-height});
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
}

.header {
  flex: 0 0 auto; // needed when it's in a flex context
  position: relative;
  margin-left: $header-padding-side;
  margin-right: $header-padding-side;
}

// Flex groups

.flex-columns {
  display: flex;

  > * {
    flex: 1 1 100%;
    margin-right: $column-gap;

    &:last-child {
      margin-right: 0;
    }
  }
}

.flex-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  > * {
    flex: 1 1 100%;
  }

  // sizes -- you'll almost always want to add a size class to .flex-group,
  // probably based on your needed breakpoint.

  // TODO: Just get rid of this. If we want space between the elements, say so,
  // don't futz around with sizes.
  
  &.small {
    flex-wrap: nowrap;
    justify-content: space-between;

    > * {
      margin-right: 1.2rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.medium {
    @media screen and (min-width: $laptop-screen) {
      flex-wrap: nowrap;
      justify-content: space-between;

      > * {
        flex-grow: 0;
        margin-right: 1.2rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

// Column hacking

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $content-padding-side;
}

// COMMON FLEXBOX STUFF

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flex-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &.even-spacing {
    justify-content: space-between;
  }

  &.centered {
    justify-content: center;
  }
}

.input-div {
  margin: 0.5rem;
}

// MODALS

// the modal goes above the content, and is non-interactive
#modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
  z-index: 3;
}

// SPINNER

.spinner {
  font-size: $spinner-size;
}

.spinner-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

// GRID LAYOUT

.twelve-column-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 4rem;
  grid-gap: 1rem;
}

// CONTAINER

.container {
  height: 100%;
  background-color: $page-background-color;
  padding: $content-padding-side $content-padding-side 0;
}

.patterned {
  background-color: #fff;
  background-image: url('~Static/images/cube-pattern_@2x.png');
  background-size: 256px, 100%;
  background-repeat: repeat, no-repeat;
}

.patterned--dark {
  background-color: #1D1E21;
  background-image: url('~Static/images/cubes-pattern-dark_@2x.png');
  background-size: 256px, 100%;
  background-repeat: repeat, no-repeat;
}

// INPUT
input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

// Override Chrome autofill has yellow background on autofilled inputs
@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: inherit;
  }
}