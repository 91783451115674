.quote-attachments {
  .ant-upload-drag {
    margin-bottom: 1rem;
  }
  
  .notification {
    margin: 2.5rem 0;
  }
  
  .sortable-table {
    margin-top: 1rem;

    td {
      vertical-align: top;
      color: #5A5F5F;
      border-bottom: 1px solid #D9DBDB;
      white-space: nowrap;
    }
  }

  .sortable-table-column--icon,
  .sortable-table-column--actions {
    width: .1%;
  }

  td.sortable-table-column--name {
    white-space: normal;
  }

  td.sortable-table-column--description {
    font-style: italic;
    white-space: normal;
  }

  .sortable-table-column--size {
    text-transform: uppercase;
    text-align: right;
    padding-right: 2rem;
  }

  .sortable-table-column--icon {
    padding: 0 0.5rem;
  
    .icon {
      color: #4C9ED2;
      font-size: 1.5rem;
    }

    .icon-label {
      display: block;
      color: #4C9ED2;
      font-size: .625rem;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      margin-top: -.25rem;
    }
  }
  .sortable-table-column--isInternal {
    .icon {
      font-size: 1.5rem;
      color: #5A5F5F;
      margin-right: .25rem;
    }
  }
}