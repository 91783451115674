@import '~Sass/variables';

// Variables

$button-text-color: $white;
$button-shadow-color: $charcoal-dark-10;

$disabled-button-background-color: $rain-light-70;
$disabled-button-shadow-color: $mineral-light-10;

$secondary-button-shadow-color: $charcoal-dark-10;

$disabled-secondary-button-background-color: $mineral-light-60;
$disabled-secondary-button-shadow-color: $mineral-light-20;

$tile-button-background-color: $mineral-light-90;
$tile-button-border-color: $blue-light-20;
$tile-button-text-color: $charcoal;
$tile-button-hover-background-color: $blue-light-20;
$tile-button-hover-border-color: $blue-light-20;
$tile-button-hover-text-color: $white;
$tile-button-active-background-color: $blue-dark-10;
$tile-button-active-border-color: $blue-dark-10;

$disabled-tile-button-background-color: $mineral-light-90;
$disabled-tile-button-border-color: $blue-light-60;
$disabled-tile-button-text-color: $mineral-light-10;

$flat-button-background-color: rgba($mineral-light-90, 0);
$flat-button-hover-background-color: $mineral-light-90;
$flat-button-border-color: rgba($text-color, 0);
$flat-button-hover-border-color: $text-color;

.button,
.split-button {
  // the default styling of a button is "primary"
  display: inline-block;
  box-shadow: 0 1px 0 $button-shadow-color;
  border-width: 0;
  border-radius: $radius;
  padding: $button-padding-top 2em $button-padding-bottom;
  background-color: $primary-button-background-color;
  line-height: 1;
  font-family: $base-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  color: $primary-button-text-color;
  text-shadow: 0 1px 0 0 rgba(0,0,0,0.2);
  cursor: pointer;
  transition: $button-transition;

  // overflow rules are only applied when the button width is limited, within
  // button groups or popup footers, for example.
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  &:hover {
    background-color: $primary-button-background-color--focus;
  }

  &:active {
    outline: 0;
    background-color: $primary-button-background-color--active;
  }

  &.disabled,
  &:disabled {
    background-color: $disabled-button-background-color;
    box-shadow: 0 1px 0 $disabled-button-shadow-color;
    color: rgba($button-text-color, 80%);
    text-shadow: 0 1px rgba(#000, 0.15);
    cursor: not-allowed;
  }

  // Secondary buttons are dark on light
  &.secondary {
    box-shadow: 0 1px 0 $secondary-button-shadow-color;
    background-color: $secondary-button-background-color;
    color: $secondary-button-text-color;
    text-shadow: none;

    &:hover {
      background-color: $secondary-button-background-color--focus;
    }

    &:active {
      background-color: $secondary-button-background-color--active;
    }

    &.disabled,
    &:disabled {
      box-shadow: 0 1px 0 $disabled-secondary-button-shadow-color;
      background-color: $disabled-secondary-button-background-color;
    }
  }

  // Button Types: "tile", "transparent", "flat", and "link"

  // Tile buttons fill their containers, and are styled distinctively
  &.button-tile {
    width: 100%;
    box-shadow: none;
    border: 1px dashed $tile-button-border-color;
    background-color: $tile-button-background-color;
    padding: 1em;
    font-weight: 500;
    font-size: 0.75rem;
    color: $tile-button-text-color;
    text-shadow: none;

    &:hover {
      background-color: $tile-button-hover-background-color;
      border-color: $tile-button-hover-border-color;
      color: $tile-button-hover-text-color;
    }

    &:active {
      background-color: $tile-button-active-background-color;
      border-color: $tile-button-active-border-color;
    }

    &.disabled,
    &:disabled {
      background-color: $disabled-tile-button-background-color;
      border-color: $disabled-tile-button-border-color;
      color: $disabled-tile-button-text-color;
    }
  }

  // Transparent buttons fill their containers, and "reverse" on hover
  &.button-transparent {
    width: 100%;
    box-shadow: none;
    border: 1px dashed $white;
    background-color: rgba($white, 0);
    padding: 1em; // TODO
    color: $white;
    font-size: 0.75rem;
    text-shadow: none;
    transition: $transition; // longer because it's bigger

    &:hover {
      background-color: rgba($white, 1);
      border-style: solid;
    }

    &:active {
      background-color: $tile-button-active-background-color;
    }

    &.disabled,
    &:disabled {
      border-color: rgba($white, 0.50);
      color: rgba($white, 0.50);

      &:hover {
        background-color: rgba($white, 0);
        border-style: dashed;
        color: rgba($white, 0.50);
      }
    }
  }

  // Flat buttons have a transparent background until hovered/focused
  &.button-flat {
    box-shadow: none;
    border: 1px solid $flat-button-border-color;
    border-radius: $radius;
    background-color: $flat-button-background-color;
    padding: 0.3125rem $button-padding-side 0.25rem; // yes, rem
    font-size: 1em;
    color: $text-color;
    text-shadow: none;

    &:hover {
      border-color: $flat-button-hover-border-color;
      background-color: $flat-button-hover-background-color;
    }

    &.disabled,
    &:disabled {
      color: $disabled-text-color;
      border-color: $flat-button-border-color;
      background-color: $flat-button-background-color;
    }
  }

  // inline will look like an actual standard link, but THE SAME!
  &.button-inline {
    margin: 0;
    padding: 0;
    text-decoration: underline;
    box-shadow: none;
    background-color: transparent;
    color: inherit;
    font-weight: 500;
    text-shadow: none;
  }

  // Link buttons look like a standard link, but lighter
  &.button-link {
    box-shadow: none;
    margin-left: -$button-padding-side;
    margin-right: -$button-padding-side;
    background-color: transparent;
    padding-left: $button-padding-side;
    padding-right: $button-padding-side;
    font-size: 1em;
    color: $link-button-color;
    text-shadow: none;

    &:hover {
      color: $link-button-color--hovered;
    }

    &.secondary {
      background-color: transparent;
    }

    // .icon {
    //   color: $rain-light-40;
    // }

    &.disabled,
    &:disabled {
      outline-width: 0;
      background-color: transparent;
      color: $disabled-text-color;

      .icon {
        color: inherit;

        svg {
          fill: currentColor;
        }
      }
    }
  }

  // Sizes: only "small" for now

  // Small
  &.small {
    font-size: 0.75rem;
  }
  
  // More complicated button have multiple items in them
  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.25em;
    font-weight: normal;
    white-space: nowrap;
  }

  p {
    margin-bottom: 0;
  }
}

.split-button {
  padding: 0;
  display: flex;
  align-items: center;
  height: 39px;
  margin: auto 20px auto 0;
  background: none;
  box-shadow: none;
  overflow: visible;
  
  .split-button__button.button {
    margin: 0;
    border-radius: $radius 0 0 $radius;
    padding-right: 1.5em;
  }

  .split-button__split {
    display: inline-block;
    width: .5px;
    height: 100%;
    padding: .25rem 0;
    margin-right: -.5px;
    opacity: .5;
    border-top: .375rem solid $primary-button-background-color;
    border-bottom: .375rem solid $primary-button-background-color;
    box-shadow: 0 1px 0 $button-shadow-color;
    background: #ccc;

    &--disabled {
      border-color: $disabled-button-background-color;
    }
  }

  .split-button__context-button.button {
    padding: 0 .5rem;
    margin: 0;
    border-radius: 0 $radius $radius 0;

    .icon {
      transform: scale(1.5);
      vertical-align: middle;
      color: #fff;
    }
  }
}