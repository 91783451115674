.tooltip.tooltip__quote-tooltip {
  max-width: 20rem;

  .ant-tooltip-content {
    border-radius: 2px;
    background-color: rgba(26, 26, 29, 0.95);
  }
}

.quote-tooltip {
  padding: 0 .5rem;

  .icon {
    font-size: 1.25rem;
  }

  .icon-least-cost {
    font-size: 1rem;
    width: 1.25rem;
    margin-left: 1px;
  }

  .icon-port,
  .icon-calendar {
    margin-top: -2px;
    margin-right: 2px;
  }

  .icon-internet, 
  .icon-data-center {
    margin-right: 2px;
  }

  .icon-location,
  .icon-internet, 
  .icon-data-center {
    margin-top: -2px;
  }
}

.quote-tooltip__specification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .75rem;
  margin-top: .25rem;

  .flex-row {
    flex: inherit;
  }
}

.quote-tooltip__location {
  display: flex;
  align-items: flex-start;
  line-height: 1.2;
  position: relative;
  margin-bottom: .4rem;
}

.quote-tooltip__location:first-of-type {
  &::after {
    content: ' ';
    position: absolute;
    top: 1rem;
    height: calc(100% - 0.7rem);
    left: 9px;
    width: 0px;
    border-left: 1px dotted white;
  }
}