.quote-designs__unsent-designs-message {
  text-align: right;
  color: #5B6061;
  font-size: .75rem;
  font-style: italic;
  margin-top: -.75rem;
}

.quote-designs__unsent-designs-button.button {
  font-size: .875rem;
  padding: 0;
  align-items: center;
  margin: .125rem 0 1rem;
  display: inline-flex;

  .icon {
    margin-right: .25rem;
    font-size: 1rem;
  }
}

.quote-designs__no-bid {
  p {
    text-align: center;
    margin: 2rem 1rem;
    font-size: 1rem;
  }
}