@import '~Sass/variables';

.feedback {
  position: relative;
  margin-bottom: $para-margin;
  padding: $para-margin $para-margin $para-margin 2.5rem;
  font-size: 0.875rem;
  color: $mineral-dark-60;

  > :first-child  {
    margin-top: 0;  
  }

  > :last-child  {
    margin-bottom: 0;  
  }

  .feedback-icon {
    position: absolute;
    top: 0.875rem;
    left: 0.5rem;
    font-size: 1rem;
  } 

  &.small {
    padding-top: $button-padding-top;
    padding-bottom: $button-padding-bottom;
    font-weight: 500;

    .feedback-icon {
      top: 0.625rem;
    }
  }

  &.error {
    border-bottom: 1px solid $error;
    background-color: $error-background;
  }

  &.info {
    border-bottom: 1px solid $info;
    background-color: $info-background;
  }

  &.success {
    border-bottom: 1px solid $success;
    background-color: $success-background;
  }

  &.strong {
    color: $white;

    &.error {
      background-color: $error;
    }

    &.info {
      background-color: $info;
    }

    &.success {
      background-color: $success;
    }
  }
}
