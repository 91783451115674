@import '~Sass/variables';

// Radio components -- ant design overrides
.ant-radio-wrapper {
  color: $text-color;
  font-family: $base-font;
  font-size: 0.875rem;

  .right { // right-aligned icons, e.g.
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }

  &:hover {
    .ant-radio {
      .ant-radio-inner.ant-radio-inner {
        // Yes, this is correct. I'm overriding the ant css specificity even
        // though it's cascaded /after/ this css.
        border-color: $radio;
      }
    }
  }
}

.ant-radio-wrapper-checked {
  font-weight: bold;
}

.ant-radio {
  margin-left: 6px;
  vertical-align: -0.125rem;

  .ant-radio-inner {
    border-width: 2px;
  }

  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: $radio;
      background-color: $radio;
    
      &::after {
        top: 2px;
        left: 2px;
        background-color: $white;
      }
    }
  }

  &.ant-radio:hover .ant-radio-inner,
  &.ant-radio-focused .ant-radio-inner {
    border-color: $radio;
  }
}

.radio-group {
  margin-bottom: 2rem;

  // &.ant-radio-group {
  //   width: 100%;
  // }

  .ant-radio-wrapper {
    display: block;
    height: 2.625rem;
    padding-left: 1rem;
    line-height: 2.625rem;
  }
}

// Disabled Radios
.ant-radio-disabled {
  .ant-radio-inner.ant-radio-inner {
      border-color: $mineral-light-60 !important;
      background-color: $mineral-light-90;
  }

  + span {
    color: $mineral-light-40;
  }

  &.ant-radio-checked {
    .ant-radio-inner.ant-radio-inner {
        background-color: $mineral-light-60;
    }
  }
}

.radio-accordion-header {
  .ant-radio-wrapper {
    background-color: $mineral-light-90;
    border-top: 1px solid $mineral-light-20;
    width: 100%;
    transition: $button-transition;

    &:last-child {
      border-bottom: 1px solid $mineral-light-20;
    }

    &:hover {
      background-color: darken($mineral-light-90, 6%);
    }
  }

  .ant-radio-wrapper-checked {
    background-color: $radio;
    border-color: $radio;

    &:hover {
      background-color: darken($radio, 6%);
    }
  }
}

.ant-radio-group {
  .ant-radio-button-wrapper {
    &:hover {
      color: $radio;
    }

    &:not(.ant-radio-button-wrapper-disabled) {
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }  

    &.ant-radio-button-wrapper-checked,
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    &.ant-radio-button-wrapper-checked:hover {
      background-color: $radio;
      border-color: $radio;
      color: #fff;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      background-color: darken($radio, 6%);
      //box-shadow: 0 0 2px 1px $radio !important;
    }
  }
}