@import '~Sass/variables';

// Variables
$tab-text-color: $charcoal;
$active-tab-color: $rain;
$disabled-tab-color: $mineral-light-20;

// Override Ant Design's tab-wide styling
.ant-tabs {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  font-family: unset;
  font-size: unset;
  line-height: unset;
  color: unset;
  box-sizing: inherit;
  list-style: inherit;
  zoom: unset;

  .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    color: $tab-text-color;
  }

  .ant-tabs-ink-bar {
    height: 4px;
    background-color: $active-tab-color;
    z-index: 0;
  }

  .ant-tabs-nav {
    color: $mineral-dark-30;
    margin-bottom: 1.5rem;

    &::before {
      border-bottom-color: $mineral-light-40;
    }

    .ant-tabs-tab {
      margin: 0;
      padding: .5rem 1.5rem;
      &:hover {
        color: $tab-text-color;
      }
    }

    .ant-tabs-tab-btn {
      font-size: .875rem;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $tab-text-color;
      text-shadow: none;
      font-weight: 500;
    }

    .ant-tabs-tab-disabled {
      color: $disabled-tab-color;
    }
  }

  &:not(.ant-tabs-vertical) {
    >.ant-tabs-content>.ant-tabs-tabpane {
      background-color: $white;
      padding-bottom: 1rem;
    }
  }

  .ant-tabs-extra-content {
    .ant-tabs-new-tab {
      &:hover {
        color: $tab-text-color;
        border-color: $tab-text-color;
      }
    }
  }

  // Add padding for typical tab usages (although workspaces need _no_ padding)
  .tab-container {
    margin-top: $tab-padding-top;
    margin-bottom: $tab-padding-top;
    padding: 0;
  }

  .flex-tab {
    display: flex;
    flex: 1;

    .main-content {
      display: flex;
      flex: 1;
      flex-grow: 3;
      flex-direction: column;
      margin-right: 2rem;
    }
  }

  // Badges within tabs
  .ant-tabs-tab .badge {
    min-width: 1.9em;
    margin-right: 0.25em;
    border: 1px solid $active-tab-color;
    border-radius: 100em;
    background-color: $white;

    .background {
      display: none;
    }

    .value {
      color: $active-tab-color;
    }
  }

  .ant-tabs-tab-active .badge {
    color: $active-tab-color;
    border-style: solid;

    .background {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100em;
    }

    .value {
      color: $white;
      position: relative;
      font-weight: 400;
    }
  }

  .ant-tabs-tab-disabled {
    .badge {
      color: $disabled-tab-color;
      border-style: dotted;
      border-color: $disabled-tab-color;

      .value {
        color: $disabled-tab-color;
      }
    }

    &.ant-tabs-tab-active {
      .badge {
        border-style: solid;
        color: $disabled-tab-color;

        .value {
          color: $white;
        }
      }
    }
  }
}

.tab-badge {
  display: inline-block;
  background-color: $rain-dark-10;
  color: #fff;
  margin-left: .5rem;
  font-size: .75rem;
  text-align: center;
  line-height: 1.5em;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  vertical-align: middle;
  font-weight: 400;
}