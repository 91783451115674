@import '~Sass/variables';

.pager {
  list-style: none;
  padding: 0; 
  margin: .5em 0;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  li {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 0 2px;
    border-radius: 4px;
    box-shadow: none;
    background-color: #fff;
    color: $link-button-color;
    border: 1px solid #fff;
    transition: border-color 150ms;
    padding: 0;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
    overflow: hidden;

    &.disabled {
      opacity: .7;
      color: #c0c3c3;
      outline: none;
      cursor: default;
      
      a {
        outline: none;
      }
      .icon {
        color: #c0c3c3;
      }
    }

    a {
      display: inline-block;
      color: inherit;
      background-color: inherit;
      width: 100%;
      height: 100%;

      .icon {
        padding: 0;
      }
    }

    &:hover:not(.disabled) {
      background-color: #fff;
      border-color: $link-button-color;
    }

    &.active, &.active:hover {
      background-color: $primary-button-background-color;
      color: #fff;
    }
  }

  &.isLoading {
    li, a {
      opacity: .7;
      cursor: wait;
    }
  }
}

.pager-row {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
}

.pager-status {
  color: #878A8B;	
  font-size: 14px;
  padding: 6px 0;
}