@import '~Sass/variables';

$exact-match-icon-color: $tree-dark-10;
$near-match-icon-color: $warning;

.related-designs {
  margin-bottom: 2rem;
}

.related-designs__header {
  display: inline-flex;
  font-size: 1rem;
}

.related-designs__table {
  color: $mineral-dark-60;

  .sortable-table-column {
    padding: .75rem .5rem .75rem 0;
    border-bottom: 1px solid $table-separator-color;
  }

  .sortable-table-column__header {
    padding: .125rem 0;
  }

  .sortable-table-column--from,
  .sortable-table-column--to {
    width: 20%;
    min-width: 6rem;
  }
  .sortable-table-column--requirements {
    width: 15%;
    
    div {
      display: flex;
      align-items: center;
    }

    .icon {
      color: $mineral;
      font-size: 1.25rem;
      margin-right: .25rem;
    }
  }

  .sortable-table-column--vendor-product {
    width: 15%;
    padding-right: 1rem;
  }
  
  .sortable-table-column--status-date {
    width: 10%;

    div { 
      text-transform: capitalize;
    }
  }
  
  .sortable-table-column--mrc-nrc {
    text-align: right;
    padding-right: 1.5rem;
    width: .1%;

    .sortable_table_column_data--primary {
      font-size: 1rem;
    }
  }

  .sortable-table-column--actions {
    padding: .75rem .5rem;
    text-align: right;
    width: .1%;
  }
}

.related-design-location {
  display: flex;
  align-items: flex-start;
  line-height: 1.3;

  .icon-location, .icon-internet {
    font-size: 1.5rem;
    margin-top: -.25rem;  }
}

.related-design-location__pop-name {
  font-weight: 500;
}

// .related-design-location__line1,
// .related-design-location__line2 {
//   font-size: .75rem;
// }

.related-design-location__floor-suite {
  font-size: .675rem;
}

.related-design-location__match {
  font-size: .75rem;
  margin-top: .25rem;

  .icon {
    font-size: 1.25rem;
    margin-right: .125rem;
  }

  .icon-checkmark-circle-filled {
    color: $exact-match-icon-color;
  }
  .icon-scale {
    color: $near-match-icon-color;
  }
}

.related-designs-design__advanced-requirements {
  font-size: .75rem;
  margin-top: .5rem;
  font-style: italic;
  color: #545454;
}