@import '~Sass/variables';

// Variables
$toggle-width: 2rem;
$toggle-size: 1rem;
$toggle-padding: 0.125rem;
$toggle-small-width: 1.5rem;
$toggle-small-size: 0.75rem;
$toggle-small-padding: 0.0675rem;
$toggle-button-color: $white;
$toggle-off-background-color: $disabled-text-color;

// some styling by Matt Smith
// https://codepen.io/AllThingsSmitty/pen/MmxxOz

.toggle {
  display: inline-block;
  position: relative;
  height: $toggle-size + 2 * $toggle-padding;
  text-indent: $toggle-width + 2 * $toggle-padding + 0.5rem;
  font-size: $toggle-size;
  font-weight: 500;

  input {
    display: none;

    &:checked {
      + .slider {
        background-color: $radio;

        &::before {
          transform: translateX($toggle-width - $toggle-size - 2 * $toggle-padding);
        }
      }
    }
  }

  .slider {
    position: absolute;
    width: $toggle-width;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: $toggle-size + 2 * $toggle-padding;
    background-color: $toggle-off-background-color;
    cursor: pointer;
    transition: $transition;

    &::before {
      content: '';
      position: absolute;
      width: $toggle-size;
      height: $toggle-size;
      left: $toggle-padding;
      border-radius: 50%;
      bottom: $toggle-padding;
      background-color: $toggle-button-color;
      transition: $transition;
    }
  }

  &.disabled {
    .slider {
      background-color: $disabled-border-color;
      cursor: not-allowed;

      &::before {
        background-color: $disabled-background-color;
      }
    }

    input:checked {
      + .slider {
        background-color: $disabled-border-color;
      }
    }
  }

  &.small {
    height: $toggle-small-size + 2 * $toggle-small-padding;
    text-indent: $toggle-small-width + 2 * $toggle-small-padding + 0.5rem;
    font-size: $toggle-small-size;

    input:checked {
      + .slider {
        ::before {
          transform: translateX($toggle-small-width - $toggle-small-size - 2 * $toggle-small-padding);
        }
      }
    }
    .slider {
      width: $toggle-small-width;
      border-radius: $toggle-small-size + 2 * $toggle-small-padding;

      &::before {
        width: $toggle-small-size;
        height: $toggle-small-size;
        left: $toggle-small-padding;
        bottom: $toggle-small-padding;
      }
    }
  }
}

