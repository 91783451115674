@import '~Sass/variables';

.react-select {
  &.locationA, 
  &.locationZ {
    .react-select__menu-notice--no-options {
      text-align: left !important;
      color: #545454 !important;
      line-height: 1.3;
      font-weight: bold;
    }

    .react-select__input input {
      opacity: 1 !important;
    }
  }

  .address-option__icon--pop.icon-data-center {
    color: #999;
  }

  .address-option__city-state,
  .address-option__pop-name {
    font-size: .75rem;
    font-weight: 500;

    & + .address-option__address {
      font-size: .75rem;
      margin-top: .125rem;
      color: #777;
    }
  }

  .address-option__address {
    font-size: .875rem;
    color: #404444;
  }

  :active > {
    .address-option__address {
      color: #fff;
    }
  }

  .address-option__site-code-address {
    line-height: 1.2;
    font-size: .75rem;
    margin-top: 2px;
  }

  .address-option__advanced-search {
    //background-color: #E6E7E7;
    // hacky since not certain there is a way to target createable option only
    margin: -8px -12px;
    padding: 8px 24px 8px 12px;
    display: flex;
    flex-flow: column nowrap;
    font-size: .875rem;
    line-height: 1.2;

    .fs-12 {
      font-size: .75rem;
    }

    em {
      font-weight: 500;
      text-decoration: underline;
    }
  }

  // .react-select__option--is-focused .address-option__advanced-search {
  // }

  .address-option__unvalidated {
    color: #777;
    display: flex;
    flex-flow: row nowrap;
    font-size: .75rem;
    line-height: 1.2;
  }

  .address-option__unvalidated-only {
    display: flex;
    flex-flow: row nowrap;
    // background-color: #FDF6EC;

    .icon.icon {
      color: #EF8700;
    }

    .address-option__input-value {
      color: #545454;
    }

    .address-option__not-found {
      margin-top: .25rem;
      font-size: .75rem;
      color: #333;
      font-weight: 500;
    }
  }

  .react-select__menu-list {
    padding-top: 0;
  }

  .react-select__group {
    padding: 0;
  }

  .react-select__group-heading {    
    position: sticky;
    z-index: 1;
    top: 0;
    background: #f2f2f2;
    padding: .5rem;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
}

.react-select__option-text--next {
  padding-right: 1em;

  &::after {
    content: '〱';
    position: absolute;
    top: 0;
    height: 100%;
    right: .75em;
    opacity: .3;
    color: #545454;
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    transition: all .2s;

    .react-select__option--is-focused & {
      opacity: 1;
      right: .5em;
    }
  }
}

// .react-select__option--is-focused.react-select__option--is-selected {
//   .address-option__unvalidated-only, 
//   .address-option__unvalidated {
//   }
// }

.address-option__sublabel {
  font-size: .625rem;
  color: #999;
}

.menu-list-footer__google {
  width: 6rem;
  text-align: center;
  margin: .25rem auto 0;
}