@import '~Sass/variables';

.button-bar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $mineral-light-70;
  width: 100%;
  right: 0;
  bottom: 0;
  padding: 1rem $content-padding-side;

  > * {
    margin-right: $content-padding-side;

    &:last-child {
      margin-right: 0;
    }
  }
}
