.pricing-desk__table {
  .sortable-table-row--manual {
    background-color: #FDEDEE;
    border-bottom: 1px solid #ED021E;
  }

  .sortable-table-row--review, 
  .sortable-table-row--validation {
    background-color: #FDF6ED;
    border-bottom: 1px solid #ED8702;

    &.sortable-table-row--selected {
      background-color: #F9DBB3;
    }
  }

  td {
    padding-left: 0;

    &:first-child {
      padding-left: 5px;
    }
  }

  .sortable-table-row {
    transition: background-color 300ms;
  }

  .sortable-table-row--selected {
    background-color: #FBCCD2;
  }

  .sortable-table-column--status {
    width: .1%;
  }

  .sortable-table-column--name {
    width: 30%;
  }

  .sortable-table-column--folder {
    width: 22.5%;
  }

  .sortable-table-column--actions {
    width: .1%;
    padding: .25rem;

    .icon {
      font-size: 1.5rem;
    }
  }

  .pricing-desk__quote-reference {
    font-size: .75rem;
    margin-top: .25rem;
  }
}

.pricing-desk__status-icon {
  font-size: 1.5rem;
  
  .sortable-table-row--manual & {
    color: red;
  }

  .sortable-table-row--review &,
  .sortable-table-row--validation & {
    color: #D57901;
  }
}

.widget .pricing-desk__table td:nth-child(2) {
  padding-left: 5px;
  width: 100%;
}

.pricing-desk-widget {
  .tab-badge {
    padding: 0 .375rem;
    border-radius: .5rem;
    width: auto;
  }
}

.pricing-desk__orders-table {
  td {
    border-bottom: 1px solid #eee;

    &:first-child {
      padding-left: .5rem;
    }
  }

  // &.pricing-desk__orders-table--network_engineering_unapproved .unapproved-role--engineering,
  // &.pricing-desk__orders-table--pricing-desk_unapproved .unapproved-role--pricing,
  // &.pricing-desk__orders-table--csa_unapproved .unapproved-role--csa {
  //   font-weight: 500;
  //   font-style: italic;
  // }
}

.order-validation-table__missing-approvals {
  display: flex;
  align-items: center;
  margin-top: .125rem;

  .icon {
    font-size: 1.25rem;
  }

  .approval-detail {
    padding-right: .25rem;
    margin-right: .75rem;
    flex: 0 0;
    display: inline-flex;
    font-weight: 500;
    white-space: nowrap;
    font-size: .75rem;
    align-items: center;

    &--rejected {
      border: 1px dashed transparent;

      border-radius: 4px;
      cursor: pointer;
      transition: border 2s;
    
      &:hover {
        border: 1px dashed red;
        transition: border .5s;
      }

      b {
        display: inline-flex;
      }
    }
  }
}