.modal-container-clone-folder-popup.modal-container {
  .popup-content {
    padding-top: 0;
    overflow: visible;
  }

  .clone-folder__checkbox {
    margin: 1rem 0 .5rem;
    font-size: .75rem;
    display: flex;
  }

  .text-input__new-folder {
    flex-direction: row-reverse;

    .text-input__icon.icon {
      text-align: right;
      padding: 0;
      color: #19B5DB;
    }
  }

  .react-select {
    > div {
      transition: opacity .5s;

      &.react-select--is-disabled {
        opacity: .5;
      }
    }
  }

  .flex-row > div {
    align-self: flex-start;
    padding-right: 1.5rem;

    &:last-child {
      padding-right: 0;
    }
  }

  .flex--2-over-3 {
    flex: 0 0 66.6%;
    
    + div {
      flex: 1 1;
    }
  }

  .flex-row--3-col > div {
    flex: 0 0 33.3%;
  }

  .clone-folder__form-errors,
  .clone-folder__spec-errors {
    color: red;
    margin: 1rem 0;
    font-size: .875rem;
    font-weight: 500;
  }

  .clone-folder__spec-errors {
    div {
      display: list-item;
      list-style-position: inside;
    }
  }

  .currency.lookup {
    .react-select__single-value {
      padding: 0;
      margin: 0;
    }

    .react-select__value-container {
      padding: 0 0 0 8px;
    }

    .react-select__dropdown-indicator {
      padding: 0 2px;
    }
  }
}