@import '~Sass/variables';

$checkbox-border-width: 2px;

.checkbox {
  position: relative;
  display: inline-block;
  font-size: 0.875rem;

  [type='checkbox'] {
    position: absolute;
    left: -9999em;
  }

  label {
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;
  }

  .checkbox-icon {
    position: relative;
    flex: 0 0 1em;
    width: 1em;
    height: 1em;
    margin-right: 0.65em;
    top: $checkbox-border-width;
    border: $checkbox-border-width solid $input-border-color;
    background-color: $white;
    color: $input-border-color;
    transition: all 200ms;

    .radio-group & {
      border-radius: 50%;
    }

    .icon-checkbox-checkmark {
      font-size: 1em;
      top: -$checkbox-border-width;
      left: -$checkbox-border-width;
      transform: scale(1.25);
      color: $mineral-light-90;
    }
  }

  :focus, :hover {
    & + label .checkbox-icon {
      border-color: $rain-dark-10;
    }

    &:checked + label .checkbox-icon {
      outline: 2px solid $rain-dark-10;
    }
  } 

  :checked + label,
  :indeterminate + label {
    .checkbox-icon {
      border-color: $radio;
      background-color: $radio;
      color: $white;
    }
  }

  :disabled {
    + label {
      color: $mineral-light-40;

      .checkbox-icon {
        border-color: $disabled-border-color;
        background-color: $disabled-background-color;
      }
    }

    &:checked + label,
    &:indeterminate + label {
      .checkbox-icon {
        background-color: $disabled-border-color;
      }
    }
  }

  &.checkbox--errored {
    .checkbox-icon {
      color: red;
      border-color: red;
    }
  }

  .icon {
    position: absolute;
    top: -1px;
    left: -2px;
    font-size: 1.25rem;
  }
}