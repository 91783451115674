@import '~Sass/variables';

// Variables
$sidebar-expanded-width: 16rem;

$sidebar-background-color: $mineral-light-90;
$sidebar-hover-background-color: $mineral-light-80;

$sidebar-selected-background-color: $mineral-light-80;
$sidebar-selected-hover-background-color: $mineral-light-80;

// $sidebar-subroute-background-color: $charcoal;
// $sidebar-subroute-hover-background-color: $charcoal-dark-10;
$sidebar-separator-color: $mineral-light-60;

$sidebar-support-background-color: $mineral-light-70;

$sidebar-text-color: $mineral-dark-50;
$sidebar-button-color: $mineral-dark-50;
$sidebar-icon-color: $mineral;
$sidebar-selected-icon-color: $rain-light-40;

.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  width: 0;
  background-color: $sidebar-background-color;
  white-space: nowrap;
  color: $sidebar-text-color;
  transition: $transition;
  z-index: 2;

  .sidebar-section {
    position: relative;
    overflow: hidden;

    // we set the actual max-height inline in Sidebar.jsx; this is a fallback
    max-height: 100rem;
    
    transition: $transition;

    &.collapsed {
      max-height: $navbar-height;
    }
  }

  .sidebar-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: $navbar-height;
    border-bottom: 1px solid $sidebar-separator-color; // TODO
    padding: 0.5rem 1.125rem;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: normal;
    color: $sidebar-button-color;
    $transition: $button-transition;

    &:hover {
      background-color: $sidebar-selected-background-color;
      color: $sidebar-button-color;

      .icon {
        color: $icon-color;
      }
    }

    &.selected {
      background-color: $sidebar-selected-background-color;

      &:hover {
        background-color: $sidebar-selected-hover-background-color;
      }

      .icon {
        color: $icon-color;
      }
    }

    // &.subroute {
    //   height: 2.5rem;
    //   border-bottom-width: 0;
    //   background-color: $sidebar-subroute-background-color;

    //   &:last-of-type {
    //     border-bottom-width: 1px;
    //   }

    //   &:hover {
    //     background-color: $sidebar-subroute-hover-background-color;
    //   }
    // }

    &.disabled {
      // TODO
      color: $disabled-text-color;
      cursor: initial;

      &:hover {
        color: $sidebar-background-color;
      }
    }

    .icon {
      color: $sidebar-icon-color;
      transition: $transition ease-in-out;
    }

    .title {
      display: none;
      padding-left: 1rem;
    }
  }

  .sidebar-icon {
    display: block;
    padding-right: 0;
    font-size: 1.5rem;
    line-height: 0;
  }

  .control {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: $navbar-height - 1rem;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.5rem;
    border-left: 1px solid $sidebar-separator-color;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1;
    color: $sidebar-text-color;
    transition: $transition;
    cursor: pointer;

    &:hover {
      color: $sidebar-selected-icon-color;
    }

    .icon {
      padding-right: 0;
      line-height: 0;
      font-size: 1.5rem;
      transform: scaleY(1);
      transition: $button-transition;
    }
  }

  .menu-icon {
    opacity: 0;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    transition: $transition;
    pointer-events: none;
  }

  &.expanded {
    min-width: $sidebar-expanded-width;

    .control {
      display: inline-flex;

      &.collapsed {
        .icon {
          transform: scaleY(-1);
        }
      }
    }
    
    .title {
      display: inline;
    }

    .sidebar-footer {
      min-width: $sidebar-expanded-width;
    }
  }

  .sidebar-footer {
    position: fixed;
    bottom: 1em;
    left: 0;
    width: 0;
    min-width: 0;
    overflow: hidden;
    transition: inherit;
  }

  .colophon {
    position: relative;
    text-align: center;
    color: $sidebar-text-color;
    font-weight: 400;
    font-size: 0.75rem;
    width: $sidebar-expanded-width;

    a {
      text-decoration: underline;
      font-weight: inherit;
      color: inherit;

      &:hover {
        color: $link-button-color;
      }
    }
  }

  .sidebar-support {
    margin-bottom: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: $sidebar-support-background-color;
    color: $sidebar-text-color;
  
    .sidebar-support-icon {
      padding: 1rem;
    }

    .icon {
      font-size: 1.5rem;
      margin: auto 0;
      color: #818888;
      padding: 0;
    }

    .sidebar-support-links {
      padding: .5rem 0;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      align-items: start;
      align-self: stretch;
    }

    .support__link {
      letter-spacing: 1px;
    }
  }

  .sidebar-footer-legal-links {
    display: flex;
    flex-flow: column;

    a + a {
      margin-top: .25rem;
    }
  }
}
