@import '~Sass/variables';

// Variables
$button-text-color: $white;
$button-background-color: $blue-light-20;
$button-hover-background-color: $blue-dark-10;
$button-active-background-color: $blue-dark-40;

$secondary-button-background-color: $mineral;
$secondary-button-hover-background-color: $mineral-dark-40;
$secondary-button-active-background-color: $mineral-dark-60;

.popup {
  position: absolute;
  width: 66.6667%;
  min-width: 38rem;
  max-width: 57rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: $strong-shadow;
  border-bottom: 1px solid $charcoal-dark-10;
  background-color: $white;

  .popup-header {
    position: relative;
    padding: 1.5rem 1+$content-padding-side 1.375rem $content-padding-side;
    z-index: 1;

    .popup-label {
      font-size: 1.325rem;
      font-weight: 500;
    }

    &.scrolled {
      box-shadow: 0 3px 4px -1px rgba($shadow, 0.20);
    }
  }

  .close-popup {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translate(0, -50%);
    padding: 1.375rem 1rem 1.325rem;
    font-size: 1.5rem;
    line-height: 0;
    color: $charcoal-dark-10;

    &:hover {
      color: $button-background-color;
    }

    &.minimize {
      padding-top: 0.5rem;
    }
  }

  .popup-content {
    width: 100%;
    height: 100%;
    min-height: 14rem;
    max-height: calc(100vh - 8rem); // screen height - header, footer, and extra margin
    overflow-y: auto;
    padding: 0 $content-padding-side $content-padding-side;
    color: $mineral-dark-30;
  }

  .popup-footer {
    display: flex;
    bottom: 0;
  }
}
