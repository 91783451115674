@import '~Sass/variables';

.grid-section {
  position: relative;
  box-shadow: $medium-shadow;
  background-color: $white;
  padding: 1rem;
  overflow: hidden;
}

.grid-section-gray {
  background-color: $mineral-light-90;
}
