.admin-page {
  .header-bar {
    background: #ddd;
    padding: 1em 2em;
    margin-bottom: 1rem;

    .checkbox {
      margin-left: 2rem;
    }

    .checkbox-label {
      font-size: .75rem;
      margin-left: -.25rem;
    }
  }

  .header-bar-title {
    display: inline-block;
  }

  .checkbox label {
    color: #545454;
  }

  .lookup.organizations {
    margin: 1rem 0;
    width: 20rem;
  }

  .react-select__option {
    .icon-avatar.icon-avatar--no-users {
      color: #999;
    }
  }
}