.pricing-desk-filter {
  flex: 1 1 100%;
  display: flex;
  flex-flow: row nowrap;
  //justify-content: space-between;
  background-color: #eee;
  padding: 1em;
  transition: all .2s;
  opacity: 1;
  margin-top: 1rem;

  &.--collapsed {
    height: 0;
    padding: 0 1em;
    visibility: hidden;
    opacity: 0;
    margin-top: 0rem;

    .pricing-desk-filter__reset-button, .lookup {
      display: none;
    }
  }

  .react-select__option .checkbox-label {
    align-self: center;
  }
}

.pricing-desk-filter__reset-button {
  align-self: center;
  margin-left: auto;
  overflow: visible;
}