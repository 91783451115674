.modal-container-transfer-quote-modal,
.modal-container-transfer-folder-modal {
  .popup .popup-content {
    padding-top: 0;
    overflow: visible;
    min-height: auto;
  }

  .react-select__menu-portal {
    position: relative;
    inset: 0;
  }
}