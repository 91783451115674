@import '~Sass/variables';

$folder-icon-color: $rain-light-70;

.quote-manager {
  position: relative;
  color: #5A5F5F;

  .icon-quote {
    font-size: 1.875rem;

    svg { 
      fill: #4C9ED2;
    }
  }

  .icon-folder {
    font-size: 2.125rem;
    color: $folder-icon-color;

    svg {
      stroke: $icon-color;
      stroke-width: .8px;
      stroke-linejoin: round;
    }
  }

  .sortable-table-row--import-error {
    background: #F8F9F9;

    .icon-quote svg {
      fill: #A7ACAC;
    }
  }
  
  &.--isLoading {
    min-height: 200px;

    .loading-overlay {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .status-icon__text {
    min-width: 100px;
    line-height: 1.2;
  }
}

.quote-manager__table {
  th, td {
    border-bottom: 1px solid #C0C3C3;
    vertical-align: middle;
  }
  
  .sortable-table-column--type {
    width: 0.1%;
    vertical-align: baseline;
    position: relative;
  }

  .sortable-table-column--name {
    width: 35%;
    word-break: break-word;
    padding-right: 2em;

    .text-link {
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .sortable-table-column--reference {
    text-align: right;
  }

  .sortable-table-column--bandwidth {
    text-align: right;
    width: 15%;
    padding-right: 5%;
  }
  
  .sortable-table-column--date {
    width: 10%;
    padding-right: 1em;
  }
    
  .sortable-table-column--status {
    width: 15%;

    .status-icon__substatus {
      font-size: .75rem;
      color: #999;
      line-height: .75;
      padding-left: 1.7rem;
    }
  }

  .sortable-table-column--actions {
    width: 1px;
  }

  .sortable-table-column__header--status {
    padding-left: .75em;
  }

  .status-icon {
    display: flex;
    flex-flow: row nowrap;

    .icon {
      margin-right: 0.125rem;
      margin-top: -4px;
    }
  }
}

.quote-manager__quote-count {
  //font-size: .75rem;
  color: #A7ACAC;
}

.quote-manager__folder-badge {
  position: absolute;
  top: 1.75rem;
  right: 0.75rem;
  padding: 1px 3px 0;
  color: white;
  font-weight: 500;
  border-radius: 4px;
  font-size: 0.75rem;
  background: $icon-color;
}