@import '~Sass/variables';

.text-input {
  position: relative;
  height: 50px;
  font-size: .875rem;
  border: 1px solid $input-border-color;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &--has-error {
    border-color: red;
    overflow: visible;
  }

  &--small {
    height: 2.5rem;
  }

  &--no-height {
    height: unset;
    
    .text-input__input-wrapper {
      height: unset;
    }
  }

  &:focus-within {
    box-shadow: 0 0 0 1px $focus-border-color !important;
    border-color: $focus-border-color !important;
    background: $focus-background-color !important;
  }

  .text-input__error {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: .625rem;
  }
}

.text-input__input-wrapper {
  position: relative;
  flex: 1 1 100%;
  height: 100%;
  color: #111;
  z-index: 0;
  display: flex;
}

.text-input__input {
  color: inherit;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: .7rem 0.625rem 0;
  font-size: 14px;
  z-index: 0;
  background: transparent;

  &.text-input__input--disabled {
    background: #f3f3f3;
    color: #747A7A;
    opacity: .9;

    &:focus {
      background: $focus-background-color !important;
    }
  }
  
  &.text-input__input--textarea {
    padding-top: 1.125rem;
  }

  &.text-input__input--is-clearable {
    padding-right: 2rem;
  }

  &.text-input__input--has-label::placeholder {
    color: #999;
    opacity: .75;
    transition: opacity 250ms;
  }

  &.text-input__input--has-label:not([readonly]):not(:focus)::placeholder {
    opacity: 0;
  }
}

.text-input__label {
  position: absolute;
  font-size: .875rem;
  top: 50%;
  left: .625rem;
  white-space: nowrap;
  transition: all 300ms;
  transform: translateY(-50%);
  width: calc(100% - 1rem);
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: -1;
  color: #747A7A;

  .text-input__input:focus + &,
  .text-input__input--has-value + & {
    top: 25%;
    font-size: .625rem;
    font-weight: bold;
    z-index: 1;
  }

  .text-input__input--textarea + & {
    top: 1rem;
  }

  .text-input__input--textarea:focus + &,
  .text-input__input--has-value.text-input__input--textarea + & {
    top: .625rem;
  }

  &.text-input__label--disabled {
    color: #545454;
    z-index: 1;
  }
}

.text-input__error {
  color: red;
  margin-top: 7px;
}

.text-input--has-icon {
  display: flex;

  .text-input__label {
    width: calc(100% - 3rem);
  }

  .text-input__icon {
    flex: 0 0 2rem;
    font-size: 1.5rem;
    text-align: center;
  }
  
  &.text-input--has-error {
    .text-input__icon {
      color: red !important;
    }
  }
}

.button.button.text-input__clear-button {
  position: absolute;
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 2rem;
  text-align: center;

  .icon {
    font-size: .625rem;
    color: hsl(0,0%,80%);
  }

  &:hover .icon {
    color: hsl(0,0%,60%);
  }

  svg {
    stroke: currentColor;
    stroke-width: 2.5px;
  }
}