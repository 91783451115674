@import '~Sass/variables';

.tagged-input {
  width: 100%;
  position: relative;

  input {
    width: 100%;
  }

  button {
    background-color: $white;
    position: absolute;
    right: 0;

    box-shadow: none;
    border: 1px solid $mineral-light-20;
    border-radius: $radius;
    padding: 0.1rem 0 0 0.2rem;
    line-height: 1;
    white-space: nowrap;
    font-family: $base-font;
    font-size: 1rem;
    color: $mineral-light-20;
    transition: $button-transition;

    &:hover {
      background-color: $mineral-dark-50;
      border-color: $mineral-dark-50;
    }

    &.focus {
      border-color: $mineral-dark-50;
    }

    .icon {
      display: block;

      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
}
