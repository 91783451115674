.related-designs-filter {
  font-size: .875rem;
  flex: 1 1 100%;
  margin: 1rem 0 2rem;

  > * + * {
    margin-left: .5rem;
  }

  .lookup {
    display: inline-block;
  }

  .react-select__control {
    width: 5rem;
    height: unset;
  }

  .react-select.service-type {
    .react-select__control {
      width: 6rem;
    }
  }

  .react-select.location-radius {
    .react-select__control {
      width: 8rem;
    }
  }

  .react-select__indicators {
    display: none;
  }

  .react-select__menu {
    overflow: visible;
    width: fit-content;
  }

  .react-select__option {
    white-space: nowrap;
  }
}