html, body, #root, #root > div, .unsupported-browser, .unsupported-browser__table {
  height: 100%;
}

.unsupported-browser {
  nav {
    width: 100%;
    position: absolute;
    top: 0;

    img {
      width: 94px;
      height: 28px;
      border: 0;
    }
  }
    
  .unsupported-browser__table {
    display: table;
    padding-top: 60px;
    width: 100%;
    text-align: center;
    color: #0075BF;
  }

  .unsupported-browser__section {
    height: 40%;
    display: table-cell;
    padding: 16px;
    font-size: 28px;

    .icon {
      font-size: 10rem;
      display: block;
      margin: auto;
    }
  }

  .recommended-browsers__section {
    height: 60%;
    width: 100%;
    vertical-align: top;
    color: #fff;
    background-color: #0075BF;

    p {
      margin: 0 auto;
      padding: 32px 0;
      width: 320px;
    }
  }

  .recommended-browsers__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      width: 96px;
      text-align: center;
      display: inline-block;
    }

    .icon {
      margin: 0 auto 8px;
      padding: 0;
      font-size: 48px;
      display: block;
    }
  }
}