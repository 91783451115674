.service-metrics-wrapper {
  overflow: hidden;
}

.service-metrics-grid {
  th {
    text-orientation: sideways;
    writing-mode: vertical-rl;
    border: 1px solid #777;
    background: #0075bf;
    color: #fff;
    padding: .5rem .25rem;
    font-weight: normal;
    // position: sticky;
    // top: 0;
    // z-index: 1;

    &.service-metrics-grid__keystone {
      visibility: hidden;
    }
  }

  td {
    border: 1px solid #777;
    width: 1rem;
    height: 1rem;
    position: relative;
    cursor: pointer;
    text-align: center;
    background: #52c065;
    transition: background .75s;

    &:first-child {
      background: #0075bf;
      color: #fff;
      padding: .25rem .5rem;
    }

    &.no-hover {
      background: #747a7a;
    }

    &:hover {
      background: #0075bf;
    }
  }

  // td:hover::after,
  // th:hover::after { 
  //   content: '';  
  //   background-color: lightblue;
  //   position: absolute;  
  //   left: 0;
  //   height: 10000px;
  //   top: -5000px;
  //   width: 100%;
  //   z-index: -1;
  // }

  // td:hover::before {
  //   background-color: lightblue;
  //   content: '';  
  //   height: 100%;
  //   top: 0;
  //   left: -5000px;
  //   position: absolute;  
  //   width: 10000px;
  //   z-index: -1;
  // }

  .service-metrics-grid__row-header {
    white-space: nowrap;
  }

  .tooltip {
    position: absolute;
    padding: 6px 10px;
    text-align: left;
    bottom: 100%;
    z-index: 1;
    border-radius: 6px;

    white-space: nowrap;
    background: rgba(0,0,0,.85);
    color: #fff;
    opacity: 0;
    pointer-events: none;
    transform: scale3d(.2,.2,1) translateX(-92%);
    transition: all .2s ease-in-out;
  }

  .tooltip:after {
    display: block;
    opacity: 0;
    pointer-events: none;
  }

  .tooltip:after {
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.85);
    border-left: 6px solid transparent;
    content: '';
    height: 0;
    top: 100%;
    width: 0;
    margin: 0 auto -.75rem;
  }

  .tooltip.expand:after {
    transform: translate3d(-10%,-42%,0);
    transition: all .1s ease-in-out;
  }
  
  td:hover .tooltip.expand,
  td:hover .tooltip.expand:after {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateX(-42%);
  }

  td:hover .tooltip.expand:after {
    transition: all .2s .1s ease-in-out;
  }

  ul {
    margin: .5rem 0;
    padding: 0;
    list-style: none;
  }

  li {
    font-size: .875rem;
    padding: 0;
  }

  li::before {
    content: ' ';
    display: inline-block;
    font-size: .875rem;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background-color: #52c065;
    margin-right: .5rem;
  }
}