.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .1);
  z-index: 1;
  margin: auto 0;
}

.loading-overlay__positioning {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;  
  height: 100%;
  max-height: 50vh;
  text-align: center;
}