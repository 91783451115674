@import '~Sass/variables';

$dropdown-font-size: 0.875rem;
$dropdown-text-color: $mineral-dark-60;
$dropdown-background-color: $white;
$dropdown-opened-background-color: $mineral-dark-40;
$dropdown-opened-text-color: $white;
$dropdown-selected-option-color: $rain-light-50;

.dropdown {
  margin-bottom: $para-margin;
  .ant-select-selection__choice__content{
    .checkbox{
      display: none;
    }
    
  }
  .ant-select {
    width: 100%;
    font-size: $dropdown-font-size;
    color: $dropdown-text-color;
    transition: $button-transition;

    &:hover {
      box-shadow: $medium-shadow;
    }
  }

  // Focus state overrides
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:active,
  .ant-select-selection:focus {
    box-shadow: $medium-shadow;
  }

  .ant-select-disabled {
    color: $disabled-text-color;

    &:hover {
      box-shadow: none;
    }
  
    .ant-select-selection {
      border-color: $disabled-border-color;
    }
  }

  .ant-select-selection {
    height: 2.5em;
    border-width: 0;
    border-bottom: 1px solid $rain-light-20;
    border-radius: 0;
    background-color: $mineral-light-90;
    padding-top: 0.625em;
    padding-bottom: 0.625em;
    font-family: $base-font;
    font-size: $dropdown-font-size;    
    transition: $button-transition;
  }

  .ant-select-selection__rendered {
    margin-left: 0.75em; 
    margin-right: 0.75em;
    line-height: 1.4;
  }

  .ant-select-selection__placeholder {
    color: $placeholder-color;
  }

  .ant-select-arrow {
    margin-top: -0.5em;
    font-size: $dropdown-font-size;
    color: $mineral-light-10;
  }

  // Opened
  .ant-select-open {
    color: $dropdown-opened-text-color;

    .ant-select-selection {
      border-color: $dropdown-opened-background-color;
      background-color: $dropdown-opened-background-color;
    }

    .ant-select-arrow {
      color: $dropdown-opened-text-color;
    }
    .ant-select-search__field{
      color: $dropdown-opened-text-color;
    }    
  }

  // Inline Lookups
  &.inline {
    display: inline;

    .ant-select {
      width: auto;
    }
  }

  .small &,
  &.small {
    font-size: 0.75rem;
  }
}

// Dropdown contents are rendered in a separate modal
.ant-select-dropdown {
  box-shadow: $medium-shadow;
  border-radius: 0;
  background-color: $dropdown-background-color;
  font-size: $dropdown-font-size;
  font-family: $base-font;
  color: $dropdown-text-color;

  // push it up a little to kiss the bottom edge of the dropdown selector
  > div {
    transform: translateY(-4px);
    margin-bottom: -4px;
  }
}

.ant-select-dropdown-menu {
  margin-top: 0;
}

.ant-select-dropdown-menu-item {
  height: 2.5em;
  line-height: 2;
  color: inherit;

  &:first-child,
  &:last-child {
    border-radius: 0;
  }
}

// The current selection(s)
.ant-select-dropdown-menu-item-selected {
  background-color: $dropdown-selected-option-color;
  font-weight: normal;

  &.ant-select-dropdown-menu-item-active:hover {
    background-color: $rain-light-30;
  }
}

.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item:hover {
  background-color: $dropdown-selected-option-color;
  font-weight: normal;
  color: $dropdown-text-color;
}

.ant-select-dropdown-menu-item-disabled {
  color: $disabled-text-color;

  &:hover {
    background-color: $dropdown-background-color;  
    color: $disabled-text-color;
  }
}

// Multiple selections
.ant-select-selection--multiple {
  .ant-select-selection__choice {
    border-color: $mineral-light-60;
    border-radius: $radius;
    background-color: $dropdown-background-color;
    color: $dropdown-text-color;
  }
  
  .ant-select-selection__rendered > ul > li,
  > ul > li {
      margin-top: -0.375rem;
      height: 2em;
      line-height: 2em;
  }
}



// Checkmark for multiples
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:after,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover:after {
    color: $mineral-dark-20;
}

// Tagged Dropdowns
.tagged-dropdown {
  width: 100%;

  // Inline dropdowns' sizes depend on the size of their contents
  &.inline {
    display: inline-block;
    width: auto;

    .ant-btn {
      width: auto;
    }
  }

  .ant-btn {
    width: inherit;
    height: auto;
    min-height: 2.375em; // for accidentally empty buttons
    box-shadow: none;
    border-color: $mineral-light-40;
    background-color: $mineral-light-90;
    padding: $button-padding-top 2.75em $button-padding-bottom 0.75em;
    text-align: left;
    line-height: normal;
    font-family: $base-font;
    font-size: $dropdown-font-size;
    color: $text-color;

    // We don't want to "transition" width while rendering `inline` Dropdowns
    // at componentDidMount!
    transition: width 0s;

    &:hover {
      border-color: $mineral-light-20;
      background-color: $mineral-light-90;
    }

    &:focus {
      border-color: $mineral-light-20;
      background-color: $mineral-light-90;
      color: $dropdown-text-color;
    }

    &:disabled {
      border-color: $disabled-text-color;
      background-color: $mineral-light-90;
      color: $disabled-text-color;
    }


    // sizes
    .small > & {
      font-size: 0.75rem;
    }

    .tiny > & {
      font-size: 0.7rem;
    }
  }

  &.inline {
    width: auto;

    .hidden-menu {
      // styled like the menu's to fit in the button, but hidden offscreen
      position: absolute;
      left: -9999em;
      padding: $button-padding-top 2.75em $button-padding-bottom 0.75em;
      list-style-type: none;
    }
  }
}

.dropdown-caret { // the down-caret
  position: absolute;
  top: 50%;
  right: 0.75em;
  transform: translateY(-50%);
  padding-right: 0;
  line-height: 0;
  font-size: 0.675em;

  .ant-btn:active > &,
  .ant-btn:focus > & {
    position: absolute;
  }
}

.ant-dropdown {
  z-index: $modal-z + 1; // so that modals can have dropdowns!
  font-family: inherit;
}

.ant-dropdown-menu {
  // ends in the middle of the sixth list item
  max-height: 12.75em;
  overflow: auto;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $mineral-light-90;
}

// Tagged dropdowns
.tagged-dropdown {
  .tag-list {
    margin-top: 0.75rem;

    &:empty {
      // save space for tags before they appear--one row's worth
      height: 2.5rem;
    }
  }

  .generated-tag {
    display: inline-block;

    .ant-tag-text {
      color: $dropdown-background-color;
    }

    .ant-tag {
      height: 2rem;
      margin: 0 0.5rem 0.5rem 0;
      background: $mineral-light-20;
      padding: 0.3125rem 0.75rem;
      font-size: 0.75rem;

      &:hover {
        opacity: 0.85;
      }
    }

    .icon {
      margin: 0.125rem;
      padding-right: 0;
      padding-left: 0.25rem;
      color: $mineral-light-40;
    }
  }
}
