.modal-container-send-order-review-modal {
  .popup .popup-content {
    padding-top: 0;
  }

  .lookup, .text-input {
    margin-top: 1rem;
  }

  .react-select__icon {
    font-size: 1rem;
  }

  .text-input__input--textarea {
    min-height: 5rem;
  }

  .loading-overlay {
    background-color: rgba(0,0,0,.2);
  }
}

.send-order-review__note {
  font-size: .75rem;
}

.send-order-review__download {
  display: flex;
  align-items: center;
  margin: 1rem .5rem;

  .icon {
    font-size: 1.125rem;
    transform: rotate(270deg);
  }

  .button {
    margin: 0;
    font-size: .75rem;
    padding-left: .375rem;
    text-decoration: underline;
  }
}