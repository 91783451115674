.modal-container-addNewProviderModal {
  div {
    font-size: 16px;
    line-height: 24px;
    color: #5B6061;
  }

  input {
    padding: 1em .75em;
    background-color: rgba(242,243,243,0.5);
    width: 100%;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #C0C3C3;
    margin-top: 20px;

    &.error {
      border-color: red;
    }
  
    &:focus {
      outline: none;
      border: 2px solid #7FBADF;
      background: #fff;
    }
  }

  .popup-content {
    max-height: none;
    margin-bottom: 2em;
  }
}