@import '~Sass/variables';

// all of the measurements are in em, so that badges slot in within their
// contexts

.badge {
  display: inline-block;
  position: relative;
  min-width: 2em;
  padding: 2px 0.6em;
  font-size: 0.875em;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100em;
  }

  .value {
    color: $white;
    position: relative;
    font-weight: 400;
  }

  &.inverted {
    min-width: 1.5em;
    box-shadow: 0 0 0 1px; // no color; it inherits
    border-radius: 100em;
    padding: 2.5px 0.54em;
    line-height: 1.3;
    text-align: center;

    .background {
      display: none;
    }

    .value {
      color: inherit;
    }
  }
}
