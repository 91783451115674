@import '~Sass/variables';

$focus-icon-color: $rain-dark-30;
$large-icon-color: $rain;
$large-icon-text-color: $rain-dark-10;

.whats-next {
  margin-top: 1.5rem;

  .icon-question-mark {
    font-size: 1.5rem;
    margin: 0 .5rem;
  }
}

.whats-next__header {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #E5E7E7;
  padding-bottom: .25rem;
  color: #202629;
}

.whats-next__links {
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;

  li {
    list-style-type: none;
    width: 11rem;
    padding: 0;
    text-align: center;
    cursor: pointer;
    transition: transform 100ms;

    & > a {
      display: block;
      text-decoration: none;
    }

    .whats-next__link-text {
      font-size: 1rem;
      color: $large-icon-text-color;
      line-height: 1.2;
      font-weight: 400;
      transition: color 100ms;
    }

    &:hover {
      transform: scale(1.1);

      // .whats-next__link-text,
      // .icon {
      //   color: $focus-icon-color
      // }
    }
  }

  .icon {
    color: $large-icon-color;
    font-size: 4.5rem;
    margin-bottom: .75rem;
    transition: all 100ms;
  }

  .button--transparent {
    background: none;
    border: none;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    width: 100%;
    cursor: pointer;
  }
}