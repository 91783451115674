@import '~Sass/variables';

.admin-audit {
  .timeline-item__description {
    font-size: .875rem;
    padding: 1rem;
    background: $blue-light-90;

    .icon {
      margin-right: .5rem;
    }

    .icon-add {
      color: $success;
    }

    .icon-subtract {
      color: $error;
    }
  }
}
