@import '~Sass/variables';

.react-select__layout {
  position: relative;
}

.react-select__control {
  height: 50px;
  font-size: .875rem;

  &.react-select__control--is-focused {
    background: $focus-background-color;
  }
}

.react-select__icon {
  font-size: 22px;
  margin-right: 0.25rem;

  svg {
    vertical-align: middle;
  }
}

.react-select--error { 
  margin: 5px 0 0;
  font-size: .675rem;
  color: red;
}

.react-select.error {
  .react-select__control {
    border: 1px solid red;
    box-shadow: none;
  }

  .react-select__value-container {
    border: none;
    outline-color: red;

    .error-icon {
      margin-top: 3px;
    }
  }
}

.lookup {
  &.react-select--multi-chips {
    .react-select__control {
      height: auto;
      min-height: calc(50px - .75rem);
      font-size: 0.875rem;
    }

    .react-select__value {
      flex-wrap: wrap;
      padding-top: .75rem;
      align-items: end;
    }

    .react-select__value:not(.react-select__value--no-label) {
      .react-select__input input {
        padding-top: 0 !important;
      }
    }
  }
  
  .react-select__value-container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    min-width: 0;
  }

  .react-select__value {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
  }

  .react-select__control {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
  }

  .react-select__value-container {
    overflow: visible;
  }

  .react-select__single-value {
    max-width: calc(100% + 1em);
    color: inherit;
  }

  .react-select__menu-notice--no-options {
    text-align: left;
  }

  .react-select__group-heading {
    color: $rain-dark-30;
    margin: .5em 0 .25em;
  }

  .react-select__control {
    border:1px solid $input-border-color;

    &--is-focused {
      box-shadow: 0 0 0 1px $focus-border-color;
      border-color: $focus-border-color;

      &:hover {
        border-color: $focus-border-color;
      }
    }
    // TODO? &--is-disabled { }
    // borderColor: isDisabled ? colors.neutral10 : isFocused ? colors.primary : colors.neutral20
  }

  .react-select__option {
    &--is-focused {
      background: $focus-background-color;
    }

    &--is-selected {
      background: $selected-background-color;

      .icon {
        color: #fff;
      }
    }

    &--is-selected.react-select__option--is-focused {
      color: #fff;
    }

    &:active {
      background-color: $rain-dark-10;
      color: #fff;

      .icon {
        color: #fff;
      }
    }
  }

  .react-select__multi-value {
    min-width: auto;
  }
}

// Override page default of hiding aria-hidden elements
.react-select__indicators {
  [aria-hidden="true"] {
    visibility: visible;
  }
}

.react-select__label {
  position: absolute;
  font-size: .875rem;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 300ms;
  color: #999;

  .react-select__control--is-focused &,
  .react-select__value-container--has-value &:not(.react-select__label--no-value-rendered) {
    top: 8px;
    font-size: .625rem;
    font-weight: bold;
    color: unset;
  }
}

.react-select__menu-list {
  font-size: .875rem;
}

.react-select__option {
  line-height: 1.2;

  .icon:not(.icon-checkbox-checkmark) {
    margin-right: 6px;
    font-size: 1.25rem;
  }

  .checkbox-label {
    line-height: 1rem;
  }
}

.react-select__option-text--no-icon {
  flex: auto;
}

.react-select__value-container--not-searchable {
  height: 100%;

  .react-select__value {
    height: 100%;
  }
}

// overrides
.react-select {
  .react-select__menu {
    margin-top: 3px;
  }

  .react-select__option {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;

    &--is-disabled {
      cursor: default;
    }
  }
}

.react-select__input {
  width: 100%;

  input {
    width: 100% !important;
  }
}

.react-select__multi-value__remove {
  [aria-hidden] {
    visibility: visible;
  }
}

.react-select__value:not(.react-select__value--no-label) {
  .react-select__single-value {
    top: 65%;
  }
  .react-select__input input {
    padding: 12px 0 0 !important;
  }
}

.lookup-menu-header {
  padding: 5px 1em;
  color: #545454;
  font-size: .75rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  opacity: .5;
  transition: opacity 300ms;

  &:hover {
    opacity: 1;
  }
}

.modal-container-country-lookup {
  .popup-header {
    padding-top: 2.5rem;
  }

  .popup-content {
    max-height: none;
    min-height: auto;
    overflow: visible;
    padding: .5rem;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 3rem);
  }

  .popup-footer {
    display: none;
  }
}

.lookup-menu-header__text-button {
  border: none;
  font-weight: 300;
  cursor: pointer;
}

.react-select__clear-indicator {
  cursor: pointer;
}

.react-select--editable {
  .react-select__input input {
    opacity: 1 !important;
  }

  .react-select__control--is-focused {
    .react-select__single-value {
      display: none;
    }
  }
}