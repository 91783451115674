.admin-quoting-options {
  .quote-options__group {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 2rem;
    
    & + .quote-options__group {
      padding-top: 2rem;
      border-top: 1px solid #D9DBDB;
    }

    .quote-options__group-description {
      font-size: .875rem;
    }

    h4 {
      font-size: 1.3125rem;
      margin: .5rem 0 .25rem;
    }

    .quote-options-groups-subheader {
      font-size: .875rem;
      color: #555;
      margin-top: 1rem;
      font-weight: 500;
    }

    .toggleable {
      max-height: 87.5rem;
      opacity: 1;
      transition: max-height 500ms, opacity 500ms;
      visibility: visible;
    }

    .toggleable--collapsed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 500ms, opacity 500ms, visibility 1ms 500ms;
      opacity: 0;
      
      input { 
        visibility: hidden;
      }
    }

    .toggler {
      margin: 0 0 0 .25rem;
      font-size: .75rem;
      padding: 5px;
  
      .icon.icon-caret-down {
        color: inherit;
        margin-left: .25rem;
  
        svg {
          fill: currentColor;
          transition: transform 200ms;
        }
      }
    
      &.toggler--collapse .icon-caret-down svg {
        transform: rotate(180deg);
      }
    }
  }
  
  .quote-options-save-container {
    text-align: center;
    margin: 2rem 0;
  }

  // .ant-tree {
  //   display: flex;
  //   flex-flow: row wrap;

  //   > li {
  //     flex: 1 1 25%;
  //   }
  // }
  .checkbox {
    .checkbox-icon {
      // margin-left: 1.5rem;
      font-size: 1rem;
      border-radius: 2px;
    }

    .icon-checkbox-checkmark {
      transform: scale(1.5);
    }
  }

  .loading-overlay {
    position: fixed;
  }

  .loading-overlay__positioning {
    max-height: 100vh;
  }
}

.admin-quoting-options__excluded-carrier-header {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-quoting-options__excluded-carrier-header-text {
  color: #5A5F5F;
  font-size: .75rem;
}

.admin-quoting-options__excluded-carrier-lookup {
  display: inline-block;

  .react-select__control {
    height: auto;
  }
}

.sortable-table__excluded-carriers {
  width: 50%;

  td {
    vertical-align: middle;
    border-bottom: 1px solid #D9DBDB;
  }

  .sortable-table-column--icon {
    width: 2rem;
    font-size:1.5rem;
  }

  .sortable-table-column--label {
    width: 100%;
    color: #5A5F5F;
  }

  .sortable-table-column--remove {
    font-size: 1.5rem;
  }
}
