@import '~Sass/variables';

// VARIABLES //
///////////////

$panel-light-gray: #ccc;

.accordion {
  margin-bottom: 2 * $para-margin;
  font-size: 1rem; // we're using ems to size text; vide infra .small

  // the first and last elements should /not/ add extra padding inside the accordion
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0 !important;
  }

  .panel {
    &.expanded {
      > .panel-header {
        border-bottom-color: $mineral-dark-40;
        background-color: $mineral-dark-40;
        color: $white;

        &:hover {
          border-bottom-color: $mineral-dark-60;
          background-color: $mineral-dark-60;
        }

        > .control {
          transform: translateY(-50%) scaleY(-1); // rotate(180deg);
        }

        small,
        .label { // labels
          color: $mineral-light-80;
        }
      }

      > .panel-content {
        max-height: $accordion-max-height;
        overflow: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    &.disabled {
      > .panel-header {
        border-color: $disabled-border-color;
        background-color: $disabled-background-color;
        color: $disabled-text-color;
        cursor: not-allowed;
      }
    }

    // contentless panels remove some of their visual affordance
    &.empty {
      > .panel-header {
        border-bottom-width: 0;
      }

      > .panel-content {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .panel-header {
    position: relative;
    display: block;
    width: 100%;
    border-width: 0;
    border-bottom: 1px solid $rain-light-20;
    background-color: $mineral-light-90;
    padding: $button-padding-top 2em $button-padding-bottom 1em;
    text-align: left;
    text-decoration: none;
    font-size: 1em;
    color: inherit;
    line-height: 1.2;
    transition: $button-transition;

    &:hover {
      background-color: $panel-light-gray;
      outline-width: 0;
      color: $charcoal;

      .icon[role='decorative'] {
        opacity: 1;
      }
    }

    &.icon { // with the icon="..." prop, show an icon on the left
      padding-left: 3.5rem; // 1 + 1.5 + 1
    }

    .icon[role='decorative'] {
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      line-height: 0;
      font-size: 1.5rem;
      opacity: 0.85;
    }

    .control {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) scaleY(1); //rotate(0deg);
      padding: 0.5em 0.5em 0.5em 0.3em;
      line-height: 0; // perfectly centered on the "line"
      font-size: 1.5em;
      transition: 2 * $transition ease-in-out;
    }

    .right { // right-aligned icons, e.g.
      position: absolute;
      top: 50%;
      right: 2.5rem;
      transform: translateY(-50%);
    }

    h3,
    h4,
    h5 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1em;
      font-weight: 500;
    }

    small,
    .label { // labels
      font-size: 0.75em;
      color: $label-color;
    }
  }

  .panel-content {
    max-height: 0; // Hack for transition
    overflow-y: hidden;
    box-shadow: $medium-shadow;
    padding: 0 1rem;
    transition: 2 * $transition ease-in-out;

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  // With radio buttons
  &.radio {
    .panel-header {
      padding-left: 0.5em;
      text-align: left;
      font-size: 0.875rem;
      color: $mineral-dark-30;

      .ant-radio-wrapper {
        margin-right: 1em;
      }
    }

    // darken the radio button
    .ant-radio-inner {
      border-color: $mineral-light-10;
    }

    > .panel {
      &.expanded {
        .panel-header {
          font-weight: bold;
        }

        .ant-radio-inner {
          border-color: $radio;
        }
      }
    }
  }

  // Additional Contexts: inline, table, etc.

  // inline
  &.inline {
    max-height: $accordion-max-height;
    overflow: auto;
    margin-bottom: 2 * $para-margin;
    font-size: 0.875rem;

    > .panel {
      &:last-of-type {
        > .panel-header {
          border-bottom-color: $panel-light-gray;
        }
      }

      &.expanded {
        > .panel-header {
          background-color: $mineral-light-90;
          border-bottom-color: $panel-light-gray;

          &:hover {
            background-color: #eee; // slightly darker
          }
        }
      }

      > .panel-header {
        border-color: $panel-light-gray;
        background-color: inherit;
        color: inherit;

        &:hover,
        &:focus {
          background-color: $mineral-light-90;
        }

        > .control {
          color: $mineral-light-20;
        }
      }

      &.disabled {
        > .panel-header {
          background-color: $mineral-light-90;
        }
      }
    }
  }

  // widget
  &.widget {
    > .panel {
      &:last-of-type {
        > .panel-header {
          border-bottom-color: $panel-light-gray;
        }
      }

      &.expanded {
        > .panel-header {
          background-color: #555;
          border-bottom-color: $panel-light-gray;

          &:hover {
            background-color: #333;
          }
        }
      }

      > .panel-header {
        border-color: $panel-light-gray;
        background-color: $mineral;
        color: $white;

        &:hover,
        &:focus {
          background-color: #555;
        }

        > .control {
          color: $white;
        }
      }

      &.disabled {
        > .panel-header {
          background-color: $mineral-light-90;
        }
      }
    }
  }

  // Success states override all colors

  // Success
  .panel > .panel-header.success {
    border-bottom-width: 1px;
    border-bottom-color: $success;
    background-color: $success-background;
    color: $success;

    &:hover,
    &:focus {
      background-color: darken($success-background, 4%);
    }

    .control,
    small,
    .label {
      color: $success;
    }
  }

  .panel.expanded > .panel-header.success {
    border-bottom-color: $success;
    background-color: $success-background;
    color: $success;

    &:hover,
    &:focus {
      background-color: darken($success-background, 4%);
    }
  }

  // Error
  .panel > .panel-header.error {
    border-bottom-width: 1px;
    border-bottom-color: $error;
    background-color: $error-background;
    color: $error;

    &:hover,
    &:focus {
      background-color: darken($error-background, 4%);
    }

    .control,
    small,
    .label {
      color: $error;
    }
  }

  .panel.expanded > .panel-header.error {
    border-bottom-color: $error;
    background-color: $error-background;
    color: $error;

    &:hover,
    &:focus {
      background-color: darken($error-background, 4%);
    }
  }

  // Additional sizes: so far, only small

  // Small - this only affects font size and padding
  &.small {
    font-size: 0.75rem;

    > .panel > .panel-header {
      padding-top: 0.5rem;
      padding-bottom: 0.4375rem;
    }
  }
}
