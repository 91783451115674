.quote-manager-page {
  .page-header {
    flex: 0 0 auto;
    margin-bottom: .5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .page-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .header-wrap {
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: visible;
    z-index: 2;

    .quote-manager-filter {
      flex: 1 1 100%;

      &:not(.--collapsed) { 
        margin-top: .5rem;

         + .quote-manager-notifications {
          top: 95%;        
        }
      }
    }
  }

  .quote-manager-actions {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.5rem;

    .button {
      font-size: .875rem;
  
      .icon { 
        margin-right: .25rem;
        font-size: 1.5rem;
        vertical-align: -.45rem;
      }
    }

    .filter-button {
      margin: 0 .5rem 0 auto;
      font-size: 1rem;
      padding: 0 .5rem;
    }
  }

  .quote-manager-notifications {
    flex: 1 1 100%;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  .quote-manager-content {
    flex: 1 1 auto;
    width: 100%;
  }

  .action-notification {
    width: 100%;
    margin: 0 auto;
    left: 0;
    top: 0;
    position: sticky;
    opacity: .95;
    z-index: 1;
  }

  .filter-description {
    margin: 0 0 1em;
    color: #454545;
  }
}

// Performance styling fix to prevent bg and border going transparent when filter opens - not working!
.quote-manager__importing-notification {
  width: 100%;
}

.quote-manager__context_menu {
  z-index: 2;
}