@import '~Sass/variables';

.design-approval {
  display: inline-flex;
  align-items: center;
  border: 1px dotted transparent;
  border-radius: 4px;
  margin-left: -.25rem;

  &:hover {
    border: 1px dotted;

    .design-approval__button {
      display: inline-flex;
    }
  }
}

.design-approval__icon {
  font-size: 1.25rem;
}

.design-approval--rejected,
.design-approval__icon--rejected {
  color: $red;
  
  .design-approval__button {
    background-color: $red;
  }
}

.design-approval--approved,
.design-approval__icon--approved {
  color: $tree-dark-10;

  .design-approval__button {
    background-color: $tree-dark-10;
  }
}

.design-approval__text {
  font-size: .75rem;
}

.design-approval__button {
  padding: 0.3rem;
  display: none;
  border-radius: 0 4px 4px 0;
  margin-left: .5rem;
  align-self: stretch;

  .icon {
    color: white;
    font-size: .625rem;
  }
}

.design-approval__tooltip {
  time {
    text-transform: lowercase;
  }
}

.button.design-approval-button {
  display: inline-flex;
  align-items: center;
  padding-left: .5rem;
  padding-right: .75rem;

  .icon {
    font-size: 1.5rem;
    color: white;
    margin-right: .125rem;
  }
} 
  
.design-approval-button--accept {
  background-color: $tree-light-10;

  &:hover, &:active {
    background-color: $tree-dark-10;
  }
}

.design-approval-button--reject {
  background-color: lighten($red, 10%);

  &:hover, &:active {
    background-color: $red;
  }

  .icon {
    transform: rotate(45deg);
  }
}

.button.button.design-approval-button--waiting {
  color: #BD6C01;
  border: 1px solid #ED8702;
  background: #fdf6ed;
  box-shadow: none;
  font-weight: 400;
  font-style: italic;
}