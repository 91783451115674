.modal-container-advanced-location-modal {
  .text-input {
    margin-top: 1rem;
  }

  .advanced-location__mpoe {
    margin-top: .25rem;
    color: #0069AB;
    font-size: .65rem;
  }

  .flex-row--2-col {
    justify-content: space-between;

    & > div {
      flex: 0 0 calc(50% - .5rem)
    }
  }

  .popup .popup-content {
    padding-top: 0;
  }
}