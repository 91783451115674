.steps {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  font-size: .875rem;
  margin-bottom: 2rem;
}

.steps__step {
  flex: 1 1;
  position: relative;
  text-align: center;

  &--pending {
    .steps__step-badge {
      background-color: #E5E7E7;
      color: #333636;
    }
    
    .steps__step-title {
      color: #333636;
    }
  }

  &--current {
    .steps__step-badge {
      background-color: #0075BF;
      color: #fff;
    }
    
    .steps__step-title {
      color: #0069AB;
      font-weight: 500;
    }
  }

  &--completed {
    .steps__step-badge {
      background-color: #30A259;

      .icon {
        color: #fff;
      }
    }
    
    .steps__step-title {
      color: #257D45;
      font-weight: 500;
    }

    .icon-checkmark {
      font-size: 1.5rem;
    }
  }

  & + &::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    border-top: 1px dashed #0069AB;
    right: 50%;
    top: 0.75rem;
    z-index: -1;
  }
}

.steps__step-badge {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: inline-block;
  line-height: 1.5rem;
  margin-bottom: .25rem;
}