.modal-container.modal-container-import-error-popup {
  .popup {
    max-height: 100vh;
  }

  .popup-content {
    padding: 0;
    // Note: on smaller screen or landscape mobile, the modal content will be prevented from scrolling.
    // We may need to add max screen media height check to enforce auto or scroll overflow 
    // overflow: visible;
  }

  .popup--is-collapsed {
    .popup-content {
      overflow: visible;
    }
  }
  
  .widget-create-quote {
    border-bottom: none;
    box-shadow: none;
    border-radius: 0;
    padding-bottom: 0;
  }

  .advanced-options {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }

  .react-select__menu-list {
    max-height: 200px;
  }

  .create-quote-button-container {
    display: none;
  }
}