.quote-history {
  position: relative;
}

.quote-history-icon--quote-failed_to_price {
  color: red;
}

.quote-history-icon--quote-priced,
.quote-history-icon--quote-repriced {
  color: green;
}