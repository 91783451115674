.lookup.customer {
  .react-select__group-heading {
    margin: 0;
    padding: .5rem;
    background-color: #F2F3F3;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;

    .icon-history {
      font-size: 1.25rem;
      margin-right: .25rem;
    }
  }
}