@import '~Sass/variables';

// node background
$node-background-color: $mineral-light-80;
$node-item-background-color: #fff;

$link-background-color: #fff;
$link-item-background-color: $mineral-light-90;

$gray-text-color: $mineral-dark-10;
$alt-icon-color: $mineral-light-10;

$solution-border-color: $rain;
// link background
// link item background
$ext-view-border-color: #ccc;
$ext-view-solution-border-color: $blue-light-40;

$offnet-background-color: rgba(253,229,232,0.8);
$node-border-radius: 6px;

.design-item-group {
  display: flex;
  flex-flow: row nowrap;

  & > * {
    flex: 1 1 auto;
  }
}

.design-item-row { 
  padding: 1.25rem .5rem 1.25rem 1.25rem;
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: top;
  font-size: .875rem;
  margin: 0 .75rem 0 1rem;
}

// nodes
.design-item-row--node {
  background: $node-background-color;
  padding: 0 .75rem .75rem 1.25rem;
  
  .design-editor & {
    padding-right: 0;
  }

  &.design-item-row--location {
    padding-bottom: 0;
    font-weight: 500;

    .react-select {
      font-weight: 400;
    }
    
    .design-item-row__type {
      background: $node-background-color;
    }
  }  

  .design-item-group--type {
    background: $node-background-color;
  }

  .design-item-row__type {
    background: #fff;
    padding: .5rem 20px .5rem 1rem;
    border-radius: 8px 0 0 8px;
    padding-right: 20px;

    .design-editor & {
      padding-right: 0;
    }
  }

  .design-item-row__specifications {
    background: #fff;
    border-radius: 0 8px 8px 0;
    padding-right: .5rem;
  }

  &-start {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &-end {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}


// links
.design-item-row--link {
  margin: 0 .75rem 0 1rem;
  padding: .75rem 0 .75rem 1.25rem;

  & + & {
    padding-top: 0;
  }

  .design-item-row__type {
    background: $link-item-background-color;
    padding: .5rem 1rem;
    border-radius: 8px 0 0 8px;
    padding-right: 20px;

    .design-editor & {
      padding-right: 0;
    }
  }

  &.design-item-row--is-editing .design-item-row__specifications {
    border-radius: 0;
  }

  .design-item-row__specifications {
    background: $link-item-background-color;
    padding: 1rem 1rem 1rem 0;
    border-right-width: 0;
    border-radius: 0 8px 8px 0;
  }
  
  .design-item-group--actions {
    border-radius: 0 8px 8px 0;
    background: #f2f2f3;
    padding: 0.75rem 0;
  }

  &.design-item-row--offnet {
    .design-item-row__type,
    .design-item-row__specifications,
    .design-item-group--actions {
      background: $offnet-background-color;
    }
  }
}

.design-editor {
  .design-item-row--node:not(.design-item-row--location):focus-within {
    .design-item-row__type,
    .design-item-row__specifications {
      border-color: $focus-border-color;
    }
  }

  .design-item-row--link:focus-within {
    .design-item-row__type,
    .design-item-row__specifications,
    .design-item-group--actions {
      border-color: $focus-border-color;
    }
  }
}

.design-item-row__title {
  font-size: .75rem;
  color: $gray-text-color;
  margin: .125rem 0;
}

// editable design row react overrides
.design-item-group--type {
  flex: 0 0 16.25rem;
  display: flex;

  .react-select__control {
    height: 40px;
  }
  
  .text-input__label,
  .react-select__label {
    font-size: .75rem;
    color: #999;
  }

  .text-input__input:focus + .text-input__label,
  .text-input__input--has-value + .text-input__label,
  .react-select__control--is-focused .react-select__label, 
  .react-select__value-container--has-value .react-select__label {
    top: 25%;
    font-size: .625rem;
    font-weight: bold;
    color: #1D1E21;
  }

  .design-item-row:not(.design-item-row--location) & 
  .react-select__value:not(.react-select__value--no-label) 
  .react-select__single-value {
    font-size: .75rem;
  }
}

.design-item-row__icon {
  width: 30px;
  padding-right: 20px;
  flex: 0 0 auto;
  margin: auto 0;
  z-index: 0;

  .icon-internet,
  .icon-location { 
    svg {
      font-size: 1.125rem;
      transform: scale(1.8);
    }
  }

  .icon-nid, .icon-cross-connect, .icon-port,
  .icon-least-cost, .icon-router, 
  .icon-extended-demarc, .icon-network-allocation,
  .icon-out-of-band, .icon-other-charges, 
  .icon-sd-wan, .icon-class-of-service,
  .icon-royalty {
    color: $alt-icon-color;
    font-size: 1.25rem;
    margin-left: -1.75px;
    transform: scale(1.5);

    .design-item-row--node & {
      background: $node-background-color;
    }

    .design-item-row--link & {
      background: #fff;
    }
  }
}

.design-item-row--location {
  .design-item-group--type {
    padding: .5rem 0 .25rem;
  }

  .design-item-row__type {
    padding-left: 0;
  }
  .context-menu-button .icon {
    font-size: 1.25rem;
    transition: transform .3s;

    &:hover {
      transform: scale(1.25);
    }
  }
}

.design-item-row__location-label {
  &--readonly {
    white-space: nowrap;

    .location-field--intrabuilding {
      font-size: .75rem;
    }
  }
  &--internet {
    font-size: .875rem;
    line-height: 1.3em;
  }
}

.design-item-row__location-label-latlong {
  font-size: .75rem;
  color: #777;
}

.design-item-row__type,
.design-item-row__specifications,
.design-item-group--actions {
  border: 2px solid transparent;
}

.design-item-row__specifications {
  flex: 1 1 auto;
  align-items: center;
  display: grid;
  border-left: none;
  transition: border 50ms;
}

.design-item-row__specifications-row {
  display: flex;
}

.design-item-group--actions {
  border-left: none;
}

.design-item-row__type {
  flex: 1 1 auto;
  color: #1D1E21;
  align-self: stretch;
  line-height: 1.25;
  border-right: none;
  transition: border 50ms;

  .react-select__menu-list {
    padding: 0;
  }
  
  .react-select__menu-notice {
    display: none;

    + .react-select__option--add-provider {
      margin: 0;
    }
  }

  .react-select__option--add-provider {
    padding: 8px;
    position: relative;
    border-top: 1px solid #ccc;
    margin-top: 4px;
    width: 100%;

    &:hover {
      background: #DEEBFF;
    }
  }

  .vendor-reference-icon > .icon {
    font-size: 1rem;
    transform: scale(1.25);
    margin-left: .5rem;
    cursor: help;
  }
}

.design-item-row__type .design-item-row__type2-vendor {
  font-size: .75rem;
  font-style: italic;
  font-weight: normal;
}

.design-item-group--pricing {
  > * {
    flex: 1 1 0;
  }
}

.design-item-group--speeds {
  row-gap: 6px;

  > div {
    min-width: 4rem;
    flex: 2 2;
    line-height: 1.2;
  }

  > .design-item--term {
    white-space: nowrap;
  }
}

.design-item-group--actions {
  flex: 0 0 auto;

  svg {
    color: #3390CB;
    opacity: .1;
    transition: opacity 100ms;

    .design-item-row:hover &,
    .design-item-row:focus-within & {
      opacity: 1;
    }
  }
}

// Disable the up arrow icon when in the first non-location design-item row 
// or down arrow when in the last
.design-item__action--disabled {
  outline: none;
  opacity: .3;
  cursor: default;
}

.design-item-group--speeds {
  flex: 6 6;

  .design-item--broadband-bandwidth {
    white-space: nowrap;

    .icon {
      font-size: 1.1rem;
      color: #999;
      margin-left: -4px;

      .design-item-row--totals & {
        color: black;
      }
    }
  }

  .design-editor & {
    flex: 3 3 30%;

    > div {
      flex: 1 1 33%;
      text-align: center;
    }

    .react-select__control {
      margin: 0 auto;
    }
  }
}

.design-item-group--pricing {
  flex: 2 2;
}

.design-item-row__product-input {
  min-height: 40px;
  width: 210px;
  font-size: .75rem;
  margin-top: 6px;
  border-radius: 4px;
}

.design-item-row__proximity-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: .375rem;

  .react-select {
    flex: 0 0 auto;
    width: 49%;
  }
}

.design-item-group--actions {
  width: calc(26px + 0.75rem);
}

.design-item__actions {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  &--arrows-disabled {
    justify-content: center;
  }
}

// TODO: FIX THIS AFTER GETTING SOME SLEEP!
.design-item-row--undefined {
  display: none;
}

.connectivity-icon {
  font-size: 0.875rem;
  width: 1em;
  height: 1em;
  display: inline-block;
  border: 3px solid #C0C3C3;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  margin-left: 1.5px;


  &.connectivity-icon--On-Net {
    border-color: #36B463;
  }
  &.connectivity-icon--Near-Net {
    border-color: #F2AB4D;
  }
  &.connectivity-icon--Off-Net {
    border-color: #ED021E;
  }
 
  &.connectivity-icon--loc-z-On-Net {
    border-right-color: #36B463;
    border-bottom-color: #36B463;
  }
  &.connectivity-icon--loc-z-Near-Net {
    border-right-color: #F2AB4D;
    border-bottom-color: #F2AB4D;
  }
  &.connectivity-icon--loc-z-Off-Net {
    border-right-color: #ED021E;
    border-bottom-color: #ED021E;
  }

  &[class*="connectivity-icon--loc-z"] {
    transform: rotate(45deg);

    &::after {
      content: ' ';
      width: 1.1em;
      height: 2px;
      background-color: #fff;
      display: block;
      transform: rotate(-45deg) translateX(1px);
      z-index: 0;
      position: absolute;
      left: -5px;
      top: 50%;
    }
  }
}

.design-editor__design-items .design-item-row__location-label--readonly {
  margin-left: 1rem;
}

.design-rollup--expanded {
  box-shadow: 0 7px 23px 0 rgba(26,27,29,0.18);
}

.design-rollup--solution {
  .design-rollup__summary {
    .design-rollup__external-costs {
      .external-cost-view, .external-cost-view__content {
        border-color: $ext-view-solution-border-color;
      }
      .toggler {
        background: #B2D5EB;
      }
    }
  }
}

.design-item-pricing {
  display: flex;
  flex-flow: row nowrap;
}

.design-item-group--speeds,
.design-item-group--pricing {
  .react-select__control {
    height: 39px;
    width: 5rem;
  }

  .react-select__menu {
    width: auto;
  }

  .react-select__option {
    white-space: nowrap;
  }

  .react-select__value-container {
    padding: 0;
    height: 100%;
  }

  .react-select__single-value {
    font-size: .75rem;
    width: 100%;
    max-width: 100%;
    margin: 0;
    top: 50%;
    text-align: right;
    padding: 0 .25rem;

    .design-item-row--totals & {
      font-weight: bold;
      color: rgb(116, 122, 122); // disabled
    }
  }

  .react-select__control--is-disabled {
    background-color: #fff;
    border-color: #b3b3b3;
  }

  .react-select__single-value--is-disabled {
    color: #000;
  }
}

.design-item-group--speeds {
  .react-select__input {
    input {
      text-align: center;
      caret-color: rgba(129, 129, 129, .3);
    }
  }
}

.design-item-group__bandwidth--with-upload {
  .icon {
    font-size: .75rem;
    margin-left: 2px;
    color: #b3b3b3;
  } 
}

.design-item__upload {
  width: 70px;
  height: 39px;
  font-size: .75rem;
  line-height: 1;
  margin: 6px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;

  .design-item-row--totals & {
    font-weight: bold;
    border-color: #b3b3b3;
  }
}

.design-item-group--pricing { 
  .price-margin {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    text-align: right;
    line-height: 1;
    margin-bottom: .25rem;
  }

  .price-margin__margin {
    color: #676C6C;
    font-size: .75rem;
    line-height: 1;
  }

  .monthly-pricing, 
  .install-pricing {
    align-items: baseline;
    //height: 100%;
  }

  .currency-input,
  .percentage-input {
    width: 70px;
    height: 39px;
    font-size: .75rem;
    line-height: 1;
    font-family: inherit;
    color: inherit;
    text-align: center;
    margin-bottom: 6px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .design-item-row--totals & {
      font-weight: bold;
      border-color: #b3b3b3;
    }
  }
}
  
.design-item-type,
.design-item-speed {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.design-item-speed {
  width: 200px;
  justify-content: space-evenly;
}

.design-item-mrc {
  width: fit-content;
  margin-left: auto;

  &--has-vendor-cost {
    text-decoration: underline;
    cursor: pointer;
    transition: color .3s;

    &:hover {
      color: $rain-light-30;
    }
  }
}

.design-item-mrc__tooltip {
  transform: translateY(-0.5rem);
}

.design-item__description {
  margin: .75rem 0 0;
  font-size: .75rem;
  font-style: italic;
}

.design-item-row__add-item-button {
  align-self: center;
}

// .design-item-row--node-start {
//   border-top: 1px solid red;
// }
// .design-item-row--node-end {
//   border-bottom: 1px solid blue;
// }