@import '~Sass/variables';

.modal-container-order-quote-confirmation-popup {
  .popup .popup-header {
    padding: 1rem 1.8rem 0;

    .popup-label {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      color: #111;
    }
  }

  .icon-checkmark-circle-outline {
    font-size: 2.5rem;
    color: green;
    margin-left: -.75rem;
  }

  .popup-content {
    color: #333;
    max-height: none;

    div + div {
      margin-top: 1rem;
    }
  }
  
  .order-confirmation-thank-you {
    font-size: 1.25rem;
    color: #111;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  a:not(.button) {
    color: $link-button-color;
    font-weight: normal;

    &:hover {
      color: $link-button-color--hovered;
    }
  }

  p {
    line-height: 1.3;
    margin-bottom: 1.5em;
  }
}
