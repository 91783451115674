.pricing-desk-page {
  .header-wrap {
    flex-wrap: wrap;
  }

  .page-header {
    flex: 1 1;
    white-space: nowrap;

    & > span {
      text-transform: capitalize;
    }
  }

  .page-actions {
    flex: 1 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;

    .button {
      margin: 0;
      padding: .5rem;
      position: relative;
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;

      .icon {
        font-size: 1.5rem;
        margin-right: 2px;
        vertical-align: middle;
      }

      .icon-envelope {
        transform: translateX(-2px);
      }

      .icon-subtract-circle-outline {
        font-size: 1.25rem;
        margin-right: 4px;
      }
    }
    
    .bulk-action-button {
      overflow: visible;
    }

    .bulk-action-button__label {
      font-size: .875rem;
      padding-right: 2px;
    }

    .bulk-action-button__details {
      font-size: .625rem;
      position: absolute;
      bottom: 0rem;
      left: 2.2rem;
    }
  }

  .action-notification {
    width: 100%;
    margin: 0 auto;
    left: 0;
    top: 0;
    position: sticky;
    opacity: .95;
    z-index: 2;
  }

  .selected-notification {
    position: sticky;
    top: 0;

    .notification__content {
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.pricing-desk__bulk-assign-menu {
  z-index: 1;
}