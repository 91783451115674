@import '~Sass/variables';

.error-page {
  .container {
    height: 100vh;
    background-color: $error;
    color: $white;
  }
  
  .header {
    margin: (-$content-padding-side) (-$content-padding-side) 0;
    background-color: $white;
    padding-top: 8rem;
    padding-bottom: 1rem;
    text-align: center;
    color: $error;
  }

  .banner {
    width: auto;
  }

  .message {
    width: 25rem;
    margin: 2.3rem auto;
    text-align: center;

    p {
      margin-bottom: 2rem;
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }
  }

  .nav-buttons {
    margin-top: $content-padding-side;

    > * {
      margin-right: 2rem;
    }
  }
}

.not-found-page {
  .container {
    background-color: $rain-dark-10;
  }

  .header,
  .button-transparent:hover {
    color: $rain-dark-10;
  }
}

.unexpected-error-page {
  .container {
    background-color: $blue;
  }

  .header,
  .button-transparent:hover {
    color: $blue;
  }
}
