@import '../variables';

// Special classes
.hidden {
  display: none;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// use for "formatted" "paragraphs" returned from AJAX calls
.pre-line {
  white-space: pre-line;
}

// Color classes

.medium-weight {
  font-weight: 500;
}

.blue {
  color: $info;
}

.green {
  color: $success;
}

.yellow {
  color: #f4a622;
}

.red {
  color: $error;
}

.gray {
  color: $mineral-light-40;
}

// Dev classes

.todo {
  font-family: 'Comic Sans', 'Chalkboard', fantasy;
}
