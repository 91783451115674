.modal-container.modal-container-reject-design-modal {
  .popup-content {
    padding-top: 0;
  }

  textarea { 
    width: 100%;
    margin: 1rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: .5rem;
    min-height: 5rem;
  }
}
