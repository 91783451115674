.modal-container-newFolderPopup {
  .drawer {
    width: auto;
    padding: 0;
  }

  .drawer-content {
    padding: 1rem 1.75rem;
  }

  .close-drawer {
    padding: 1rem;
    font-size: 1rem;
    color: #f2f3f3;
    margin: 0;
  }
}