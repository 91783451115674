@import '~Sass/variables';

.tile {
  .banner {
    position: absolute;
    top: 0;
    right: -1px;

    img {
      margin: 0;
      max-width: none;
      max-height: none;
      padding: 0;
    }

    .banner-content {
      position: absolute;
      top: 0;
      right: 0;
      width: 70.7%; // √2 / 2;
      height: 48px; // height of the image

      // 1. Center on the top right corner
      // 2. Rotate
      // 3. Bring "down" in the new orientation
      transform: translate(50%, -50%) rotate(45deg) translate(0, 79px);
      padding: 0.375rem;
      line-height: 1.2;
      color: white;
      text-shadow: 1px 1px 0px rgba(#000, 0.4);
      pointer-events: none;
    }
  }
}