@import '~Sass/variables';

$node-background-color: $mineral-light-80;
$node-item-background-color: #fff;
$table-header-color: $mineral-dark-10;

// TODO: REMOVE ME into designitemrow.scss

.design-rollup {
  .design-item-group--actions {
    display: none;
  }
}

.design-items__header {
  padding: 10px 20px;
  font-size: .75rem;
  display: flex;

  .design-item-group--speeds,
  .design-item-group--pricing {
    color: $table-header-color;
    align-self: flex-end;

    .price-margin {
      margin: 0;
    }
  }
}

.icon-text {
  color: $icon-text-color;
  display: inline-flex;
  align-items: flex-end;
  white-space: nowrap;

  .icon {
    font-size: 1.25rem;
    margin-right: .25rem;
  }
}
// .design-items__rows:not(:empty) { 
//   border-top: 1px solid #C0C3C3;
// }

// .design-items__add-row {
//   border-top: 1px solid #C0C3C3;
// }

.design-items__add-row {
  border-top: 1px solid #C0C3C3;
}

.design-editor__notification-errors {
  ul {
    list-style-type: disc;
    padding: 0 1rem;
    margin: .5rem 0 0;
  }
}
