.sortable-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th {
    background: #fff;
    border-bottom: 1px solid #C0C3C3;
    color: #818888;
    font-size: .75rem;	
    font-weight: 500;
    text-transform: uppercase;
    padding: .25rem .75rem .25rem 0;
    cursor: pointer;
    white-space: nowrap;
    position: sticky;
    top: 0;
  }

  th.--not-sortable {
    cursor: default;
  }

  td {
    font-size: 0.875rem;
    padding: 0.625rem .75rem 0.625rem 0;
    vertical-align: top;
    line-height: 1.3;
  }

  .sortable-table-group-header {
    color: #007997;
    background-color: #F2F3F3;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .675rem;
    margin: 0;
    padding: .5rem;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 1.5rem;
  }
}

.sortable-table-column__header {
  &::after {
     content: ' ';
     position: absolute;
     margin-left: .15rem;
   }

   &.--sort-descending::after {
     content: '↓';
   }

   &.--sort-ascending::after {
     content: '↑';
   } 
 }

 .sortable-table--selectable .sortable-table-row {
   cursor: pointer;
 }

 .sortable-table-column__stacked-header {
   div {
     display: inline-block;
   }
 }

 .sortable-table__context-menu {
   color: #404444;
   font-size: .875rem;
   text-transform: capitalize;

   .context-menu-item--unselected .react-contexify__item__content {
     padding-left: 2.5rem;
   }
 }

.sortable_table_column_data--primary {
  font-weight: 500;
  line-height: 1.2;
}

.sortable_table_column_data--secondary {
  font-size: .75rem;
  margin-top: .25rem;
}