@import '~Sass/variables';

// Variables
$button-text-color: $white;
$button-background-color: $blue-light-20;
$button-hover-background-color: $blue-dark-10;
$button-active-background-color: $blue-dark-40;

$secondary-button-background-color: $mineral;
$secondary-button-hover-background-color: $mineral-dark-40;
$secondary-button-active-background-color: $mineral-dark-60;

// the default button is styled as a "secondary" button
.modal-button {
  flex: 50% 1 1;
  height: 2.5rem;
  border-radius: 0;
  border-width: 0;
  font-size: 0.875rem;
}