$validation-expanded-background-color: rgba(253,246,237, 1);
$ordered-expanded-background-color: rgba(234,247,239, 1);

.design-totals {
  margin: 1rem 0 0;
  padding: 1rem 2rem;
  border-radius: 0 0 10px 10px;
  background-color: #e5f6fb;

  .design-rollup--validation & {
    background-color: $validation-expanded-background-color;
  }

  .design-rollup--ordered & {    
    background: $ordered-expanded-background-color;
  }

  .design-totals__cost,
  .design-totals__commission,
  .design-totals__margin,
  .design-totals__rate,
  .design-totals__enduser-margin,
  .design-totals__enduser-rate,
  .design-totals__internal-enduser-view {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .design-totals__commission,
  .design-totals__margin,
  .design-totals__enduser-margin {
    font-size: .75rem;
    color: #545454;
  }

  .design-totals__cost,
  .design-totals__rate,
  .design-totals__enduser-rate {
    font-size: 1rem;
    font-weight: 500;
  }

  .design-totals__internal-enduser-view {
    margin: .5rem 0 0;
    font-size: .625rem;
    font-weight: 400;
    color: #007997;

    .design-editor & {
      margin: .5rem 0 -.5rem;
    }

    .percentage-input {
      color: #777;
      font-size: .75rem;
      width: 4rem;
    }

    .currency-input {
      color: #777;
      font-size: .75rem;
      width: 5rem;
    }
  }

  .design-totals--label {
    flex: 2 2;
  }

  .design-totals--pct {
    flex: 1 1;
    text-align: right;
    display: inline-flex;
    justify-content: flex-end;
  }

  .design-totals--mr,
  .design-totals--nr {
    flex: .5 .5;
    text-align: right;
  }

  .currency-input,
  .percentage-input {
    background: #fff;
    width: 5rem;
    float: right;
    margin: 0.25rem 0;
    font-size: .875rem;
    border-radius: 4px;

    &:active {
      background: #fff;
    }

    &.labelled-input--has-error {
      margin-bottom: .75rem;
    }
  }

  .currency-input {
    font-size: 1rem;
    width: 6rem;
    margin-right: -.5rem;
  }
}