@import '~Sass/variables';

// Variables
$navbar-background-color: $white;
$navbar-border-color: $mineral-light-70;
$navbar-text-color: $white;
$navbar-hover-color: $rain-light-40;

nav {
  display: flex;
  height: $navbar-height;
  justify-content: space-between;
  align-items: center;
  background-color: $navbar-background-color;
  line-height: 0; // for vertical centering
  font-size: 1.525rem;
  color: $navbar-text-color;
  border-bottom: 1px solid $navbar-border-color;

  .sidebar-toggle {
    display: inline-flex;
    flex-direction: column;
    width: $navbar-height;
    margin-right: 1.125rem;
    //background-color: $mineral-dark-60;
    padding: 0.625rem 1.125rem 1.125rem;
    font: inherit;
    font-size: .6875rem;
    color: #007997;
    border: none;
    border-bottom: 1px solid $navbar-border-color;
    cursor: pointer;
    z-index: 3;

    &:hover {
      //background-color: $charcoal;

      .icon {
        color: $navbar-hover-color;
      }
    }

    .icon {
      display: inline;
      width: 1.5rem;
      height: 1rem;
      margin-bottom: 1rem;
      padding-right: 0;
      line-height: 0; // center;
      font-size: 1.5rem;
      color: $mineral;
    }

    .title {
      font-weight: 300;
    }
  }

  h1 {
    font-weight: 500;
    font-size: 1.325rem;
    margin-top: 1.625rem;
  }

  .button.navbar-button {
    padding: 1.125rem;
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $mineral-light-60;

      .icon {
        color: $mineral-light-60;
      }
    }

    .icon {
      padding-right: 0;
      line-height: 0;
      color: $white;
    }
  }

  .navbar-right {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }

  .navbar-dropdown {
    position: absolute;
    top: $navbar-height;
    right: 0;
    z-index: 1;
    margin: 0;
    border-top: 1px solid $mineral-light-40;
    background-color: $navbar-background-color;
    padding: 0.5rem 1rem;
    list-style: none;
    line-height: $base-line-height;
    text-align: right;
    font-size: 1rem;

    li {
      display: list-item;

      &:empty { // if we're missing a user
        display: none;
      }
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $navbar-hover-color;
      }
    }
  }
}
