@import '~Sass/variables';

$view-all-button-border-color: $mineral-light-80;

.dashboard {
  height: 100%;

  .widget {
    margin: 1em 0;

    .pager-row {
      display: none;
    }
  }

  .widget h2 { 
    margin: .5em 0;
    padding: 0;
    color: #747A7A;
    font-size: 18px;
  }

  .widget-wrapper {
    height: 295px;
    border: 1px solid #E5E7E7;
    border-radius: 5px;
    box-shadow: 0 3px 8px 0 rgba(32, 38, 41, 0.1);
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    position: relative; 
  }

  .widget-scroller {
    margin: 1rem 0 0;
    height: 100%;
    overflow-x: hidden;
    position: relative;
    border-top: 1px solid #e5e7e760;
  }
}

.dashboard-content {
  margin: 0 auto;
  padding: 0 5% 6em;
  width: 100%;

  .page-content {
    padding: 0;
    width: 100%;
  }

  .breadcrumbs {
    margin-left: 0;
  }
}

.dashboard__widgets { 
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &--external {
    .widget--request-quote { 
      order: 1;
    }
    .widget--quote-manager { 
      order: 2;
    }
    .widget--pricing-desk { 
      order: 3;
    }
  }

  &--internal {
    .widget--pricing-desk { 
      order: 1;
    }
    .widget--quote-manager { 
      order: 2;
    }
    .widget--request-quote { 
      order: 3;
    }
  }
}

.widget--request-quote {
  width: 100%;
}

.widget--pricing-desk,
.widget--quote-manager {
  flex: 0 0 49%;
}

.widget.widget--pricing-desk {
  .pricing-desk-widget {
    overflow: hidden;
    flex: 1 1 100%;
  }

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-content-holder {
    overflow: hidden auto;
  }

  .ant-tabs-content {
    min-height: 100%;

    // offset tab-bar height to position inline with QM loader
    .loading-overlay__positioning {
      transform: translateY(-28px);
    }  
  }

  th,
  td:nth-child(n+3) { 
    display: none;
  }
}

.widget .quote-manager__table {
  th,
  .sortable-table-column--date,
  .sortable-table-column--actions,
  .sortable-table-column--reference,
  .sortable-table-column--bandwidth,
  .sortable-table-column--owner {
    display: none;
  }

  .sortable-table-column--type {
    padding-left: 0.5rem;
  }

  .status-icon__substatus {
    font-size: .75rem;
    color: #999;
    line-height: .75;
    padding-left: 1.7rem;
  }

  .sortable-table-column--status {
    width: 1%;
    padding-left: 2%;
  }
}

.widget-button-link.link {
  flex: 0 0;
  text-decoration: none;
  font-size: .875rem;
  border-top: 1px solid $view-all-button-border-color;
  border-radius: 0 0 5px 5px;
  padding: 1rem 1.5rem;
  display: block;
  text-align: center;
  transition: background-color 200ms;

  &:hover {
    background-color: $mineral-light-90;
  }
}

// Responsive Design tweaks...
@media only screen and (max-width: 960px) {
  .dashboard {
    .breadcrumbs {
      margin-left: 2.5%;
    }

    .dashboard-content {
      padding: 0 0 2em;
      width: 95%;
    }
  }
}

// Responsive Design tweaks...
@media only screen and (max-width: 768px) {
  .widget--pricing-desk,
  .widget--quote-manager {
    flex: 0 0 100%;
  }

  .widget-create-quote {
    .lookups, 
    .options {
      flex-wrap: wrap;

      > .lookup.lookup, 
      > .location-combo,
      > .location-combo-container {
        flex: 0 0 100%;
        max-width: initial;
        margin-left: 0;
      }

      > * + * {
        margin-top: .4em;
      }

      // .lookup + .lookup {
      //   margin-top: .4em;
      // }
    }
  }
}