@import '~Sass/variables';
@import '~Sass/theme';

// VARIABLES //
///////////////
$icon-size: 1.375rem; // 22px
$icon-padding: 0.125rem; // 2px
$small-icon-ratio: 0.8;
$big-icon-ratio: 1.25;
$giant-icon-ratio: 1.5;
$huge-icon-ratio: 1.75;

.icon {
  color: $icon-color;
  padding-right: $icon-padding; // assuming they're on the left
  display: inline-block;
  height: 1em;
  width: 1em;
  line-height: 1;
  vertical-align: -0.125em;

  &.small,
  &.small svg {
    height: $small-icon-ratio * 1em;
    width: $small-icon-ratio * 1em;
    vertical-align: ((0.8 - $small-icon-ratio) / 4) * 1em;
    // 0.8: the icon is a 20 x 20 shape in a 24 x 24 viewBox, so 0.8(em) is the
    // relative height of the neighboring text
    // 4: vertical-align lengths are twice as much as you expect; half again
    // (to center) of half
  }

  &.big,
  &.big svg {
    height: $big-icon-ratio * 1em;
    width: $big-icon-ratio * 1em;
    vertical-align: -0.125em; // ((0.8 - $big-icon-ratio) / 4) * 1em;
  }

  &.giant,
  &.giant svg {
    height: $giant-icon-ratio * 1em;
    width: $giant-icon-ratio * 1em;
    vertical-align: ((0.8 - $giant-icon-ratio) / 4) * 1em;
  }

  &.huge,
  &.huge svg {
    height: $huge-icon-ratio * 1em;
    width: $huge-icon-ratio * 1em;
    vertical-align: ((0.8 - $huge-icon-ratio) / 4) * 1em;
  }

  // svg {
  //   vertical-align: top;
  // }
}

img.icon { // for Icons with a "src" prop
  width: auto;
  height: 1.3rem;
  vertical-align: middle;
}

// INDIVIDUAL ICON STYLING //
/////////////////////////////

// Grab icon
// This should be left-aligned to its container, so we push it over, and then
// need to remove some of the excess padding on the right.
.icon-grab {
  position: relative;
  left: -0.375em;
  padding-right: 0;  
}

// ANIMATIONS: SPINNING, ETC. //
////////////////////////////////

// Animations go on the svg itself, not the container, so that other transforms
// can apply to those without interference/overriding

// Spinning

.spinning {
  svg {
    animation: spin ($transition * 4) infinite linear;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// ICON COMPONENTS //
/////////////////////
///
/// Note that the name of the icon comes /first/ for these, whereas standard
/// icons have a class of ".icon-*"

// SuccessIcon

.success-icon {
  padding-right: 0;
  font-size: 1.5rem;
  color: $success;
}

.warning-icon {
  padding-right: 0;
  font-size: 1.5rem;
  color: #ED8702;
}

// LoadingIcon

.loading-icon {
  padding-right: 0;
  font-size: 1.5rem;
}

// JumboLoadingIcon

.jumbo-loading-icon {
  padding-right: 0;
  font-size: 6rem;
  color: $blue;
}

// ErrorIcon

.error-icon {
  padding-right: 0;
  font-size: 1.5rem;
  color: $error;
}

// InfoIcon

.info-icon {
  padding-right: 0;
  font-size: 1.5rem;
  color: $info;
}

.status-icon {
  &--expired,
  &--error,
  &--validation-error,
  &--manual,
  &--manual-pricing-needed,
  &--error-pricing {
   svg {
     fill: #ED021E;
   } 
  }

  &--design-validation,
  &--pending,
  &--pricing,
  &--review,
  &--draft {
   svg {
     fill: #ED8702;
   } 
  }

  &--quoted,
  &--ordering-draft-saved,
  &--ordering-review,
  &--ordering-pending-approval,
  &--ordered,
  &--manually-quoted,
  &--manually-ordered,
  &--edited {
   svg {
     fill: #30A259;
   }
  }

  &--closed,
  &--no-bid {
    svg {
      fill: #999;
    }
  }

  &--no-bid .icon {
    transform: rotate(45deg);
  }

  .icon {
    font-size: 1.75em;
    vertical-align: middle;
  }
}

.icon-label {
  display: inline-flex;
  align-items: center;
}