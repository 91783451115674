.quote-manager-filter {
  display: flex;
  flex-flow: row wrap;
  gap: .25rem .75rem;
  //justify-content: space-between;
  background-color: #eee;
  padding: 1em;
  transition: all .2s;
  opacity: 1;

  &.--collapsed {
    height: 0;
    padding: 0 1em;
    visibility: hidden;
    opacity: 0;

    .quote-manager-filter__reset-button, .lookup {
      display: none;
    }
  }

  .react-select__control--is-disabled {
    .react-select__label {
      opacity: .3;
    }
  }

  .react-select__option .checkbox-label {
    align-self: center;
  }
}

.quote-manager-filter__all-org-users-checkbox {
  font-size: .75rem;
  padding-left: 0.25rem;
  overflow: visible;
  white-space: nowrap;
}


@media screen and (min-width: 768px) {
  .quote-manager-filter {
    flex-flow: row nowrap;
  }
}