.admin-pricing {
  padding: .25rem;

  h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 38rem;
  
    .checkbox {
      font-size: .875rem;
      font-weight: normal;
      margin-left: 6rem;
    }

    &:first-child {
      margin-top: .5rem;
    }
  }

  .margins-container {
    width: 50%;
    display: flex;
    margin: 0 0 2em;

    input {
      padding: .5rem;
      font-size: .825rem;
      border: 1px solid #999;
      border-radius: 3px;
      margin-right: 2rem;
    }
  
    label > input {
      display: block;
    }
  }

  .admin-pricing-options {
    display: flex;
    flex-flow: column nowrap;
  }

  .forceCrossConnect.lookup {
    margin-left: 1px;
    width: 17rem;
  }

  .currency.lookup {
    width: 10rem;
    font-weight: 400;
  }

  .ipPricingRate.lookup {
    width: 15rem;
    font-weight: 400;
  }
}