@import '~Sass/variables';

$quote-header-info-height: 72px;

.quote-order {
  margin: 0 auto;
  padding: 1rem;
  box-shadow: 0 0 5px rgba(33,33,33,.3);

  .checkbox {
    align-items: center;

    .checkbox-icon {
      margin-right: .5rem;
    }

    .checkbox-label {
      font-size: .75rem;
    }
  }

  .react-select__value {
    color:#111;

    .react-select__single-value {
      top: 62%;
    }
  }

  // Order review HTML overrides
  .quote-order-sections {
    html { 
      width: 100%;
    }

    .em_main_table {
      width: 100% !important;
    }

    .em_main_table > tbody > tr:first-child {
      display: none !important;
    }

    .em_main_table table > tbody:nth-child(n+4) {
      display: none !important;
    }

    // [style='font-family: roboto, arial, sans-serif; font-weight: 500; font-size: 21px; line-height: 25px; color: rgb(32, 38, 41)'] {
    //   outline: 1px solid red;
    // }
  }


  .react-select__label.react-select__label {
    color: #747A7A;
  }

  .react-select__menu-list {
    color: #111;
  }

  .loading-overlay__positioning {
    position: fixed;
    width: 100%;
    max-height: 75vh;
  }

  .DayPickerInput { 
    display: block;

    .text-input__label {
      width: calc(100% - 0.7rem);
    }

    .text-input__label {
      color: #747A7A;
    }
    
    .text-input__input {
      opacity: 1;
      background: #fff;
      color: inherit;
    }
  }
}

.DayPicker:not(.DayPicker--interactionDisabled) {
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    box-shadow: 0 0 0 2px $focus-border-color;
    background: $focus-background-color;
  }
}

.quote-order-sections--read-only {
  .react-select--read-only {
    .react-select__control {
      border: none;
      background: none;
    }

    .react-select__indicators {
      display: none;
    }

    .react-select__label {
      font-size: .75rem;
      color: #747A7A;
      font-weight: normal;
      left: 0;
    }

    .react-select__value-container {
      margin-left: 0;
      padding-left: 0;
    }

    .react-select__value {
      font-size: 1rem;
      font-weight: bold;
      color: #333636;
      line-height: 1.3;
      padding-left: 0;
      padding-right: 0;  
    }

    .react-select__single-value {
      padding-left: 0;
      margin-left: 0;
    }
  }

  .text-input--read-only {
    border: none;

    .text-input__label {
      font-size: .75rem;
      color: #747A7A;
      font-weight: normal;

      left: 0;
      top: 25%;
      z-index: 1;
    }

    .text-input__input {
      font-size: 1rem;
      font-weight: bold;
      color: #333636;
      line-height: 1.3;
      padding-left: 0;
      padding-right: 0;
      background: none;
      opacity: 1;
    }

    .text-input__input::placeholder {
      font-weight: bold;
      color: #333636;
      line-height: 1.3;
      opacity: 1;
    }
  }

  .quote-order-notes {
    border: none;
    padding: 0;
    outline: none;
    resize: none;
  }
}

.quote-order__header {
  background-color: #E5F1F8;
  margin: -1rem -1rem 0;
  z-index: 1;
  position: sticky;
  top: -$quote-header-info-height;

  &::after {
    content: ' ';
    position: absolute;
    display: block;
    height: 4px;
    width: 100%;
    top: 100%;
    background: linear-gradient(#ccc, rgba(80, 80, 80, 0));
  }
}

.quote-order__header-info {
  border-top: 1px solid #99C7E5;
  padding: 1rem 1.5rem;
  font-size: .875rem;
  line-height: 1.4;
  height: $quote-header-info-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 250ms linear;

  .quote-order--ordered & {
    background: #E9F7EF;
    border-color: #9BD8B1;
  }
}

.quote-order__header-details {
  border-top: 1px solid #99C7E5;
  padding: 1rem 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  transition: background-color 250ms linear;

  .quote-order--ordered & {
    background: #E9F7EF;
    border-color: #9BD8B1;
  }

  & > * {
    padding: 0 1rem;
    text-align: right;
  }
}

.quote-order-sections {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .quote-order-section {
    flex: 1 1 48%;
    padding: 2rem 1%;
    border-bottom: 1px solid #ddd;

    &.quote-order-section--full-width {
      flex: 1 1 100%;
      padding-right: 51%;
    }

    &.quote-order-section--no-border {
      border: none;
      padding-bottom: 0;
    }
  }
}

.quote-order-section-header {
  display: flex;
  justify-content: space-between;
  color: #1A1B1D;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 1rem 0;
}

.quote-order-section-content {
  display: flex;
  flex-flow: row wrap;

  & > * {
    flex: 0 0 47.5%;
    width: 47.5%;
    margin-right: 2.5%;
    margin-bottom: 1rem;
  }
}

.quote-order-section-subheader {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
  flex: 1 1 100%;
  display: inline-flex;
  align-items: center;

  .checkbox {
    margin-left: 2rem;
    vertical-align: middle;
    font-weight: 400;
    display: inline-flex;
  }
}

.quote-order-header-checkbox {
  font-size: .75rem;
  align-self: flex-end;
  margin-right: 2.5%;
}

.quote-order-address__header {
  color: #333636;
  font-size: .75rem;
}

.quote-order-address__line {
  color: #333636;
  font-weight: 500;
  line-height: 1.3;
}

.quote-order-notes {
  font-size: .875rem;
  padding: .75em;
  height: 5rem;
  border: 1px solid #C0C3C3;
  border-radius: 4px;
  flex: 1 1 auto;
  width: 100%;
  margin: 0;

  &::placeholder { 
    color: #8D9393;
  }
}

.quote-order-group {
  flex: 1 1 100%;
  display: flex;

  .checkbox + .checkbox {
    margin-left: 1rem;
  }
}

.quote-order-comment {
  color: #1982C5;
  font-size: .75rem;
  margin-top: .5rem;
  line-height: 1.2;
}

.quote-order__header-detail-label {
  font-size: .75rem;
  color: #747A7A;
}

.quote-order__header-detail-value {
  font-size: 1rem;
  font-weight: 500;
  color: #333636;
  line-height: 1.3;
}

.quote-order__detail-label {
  font-size: .75rem;
  color: #747A7A;

  .button {
    font-size: .75rem;
    font-weight: normal;
    color: #0069AB;
    display: inline;
  }
}

.quote-order__detail-value {
  font-size: 1rem;
  color: #333636;
  line-height: 1.3;
  white-space: pre-line;

  .quote-order-sections--read-only  & {
    font-weight: 500;
  }
}

.quote-order-radio-group {
  display: flex;
  flex-flow: row wrap;
  line-height: 1.3;
  font-size: 1rem;
  color: #333636;

  .ant-radio-wrapper {
    padding: 0;
    height: auto;
    line-height: inherit;
    font-size: inherit;
    color: inherit;
    opacity: .5;
    transition: all .2s;
    font-weight: 500;
    flex: 0 0 auto;
    display: inline-flex;
  }

  .ant-radio-wrapper-checked {
    opacity: 1;
  }

  .ant-radio-wrapper:last-child {
    padding-right: 0;
    margin-right: 0;

    .ant-radio + * {
      padding-right: 0;
    }
  }

  .ant-radio-checked::after {
    height: 1rem;
    width: 1rem;
  }
}

.quote-order-option-byline {
  font-size: .75rem;
  // margin-left: 1.5rem;
  font-weight: normal;
}

.quote-order-terms {
  flex: 1 1 100%;
  font-size: .75rem;

  .checkbox-label {
    .button {
      font-size: .75rem;
      font-weight: normal;
      color: $link-button-color;
      display: inline;

      &:hover {
        color: $link-button-color--hovered;
      }
    }
  }
}

.quote-order-comment--location {
  flex: 0 0 97.5%;
  margin-top: -.75rem;
  margin-bottom: 1.25rem;
}

.quote-order__request-demarc-disclaimer {
  margin-top: .25rem;
  font-size: .5rem;
  color: #545454;
}

.quote-order-form--full-width {
  flex: 0 0 97.5%;
}

.modal-container-quote-order-terms-modal {
  .popup-content {
    a {
      color: $link-button-color;
      margin: .25rem;
    }
  }
}

.quote-order {
  .text-input.text-input--has-error {
    margin-bottom: 1.75rem;
  }

  .text-input__error {
    margin: 2px 0 0;
    font-size: .75rem;
  }
}

.quote-order-actions {
  margin: 1.5rem 0;
  
  .button {
    min-width: 9rem;
  }
  
  .button + .button {
    margin-left: 1.25rem;
  }
}

.notification-order-saved {
  position: absolute;
  width: 100%;
  top: 100%;
}

.quote-order__pending-approval-notification,
.quote-order__design-validation-notification {
  margin-bottom: 1rem;
}

.quote-order__notification--broadband {
  margin-bottom: 1.5rem;
  border-color: $rain;
  font-size: .75rem;
  font-style: italic;
}

.order-rejection-message {
  margin: .125rem 0 0;
}

// ECX stuff
.quote-order__header-notification {
  margin-top: 1rem;
}

.checkbox--negotiation {
  align-self: center;
}

.text-input-caption {
  margin: 0 0 .25rem;
  font-size: .75rem;
  line-height: 1.25;
}

.notification__content time {
  text-transform: lowercase;
}