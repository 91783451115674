@import '~Sass/variables';

.widget-create-quote {
  position: relative;
  border: 1px solid #E5E7E7;
  border-radius: 5px;
  padding: 1rem 30px 30px;
  background: #fff;
  box-shadow: 0 3px 8px 0 rgba(32, 38, 41, 0.1);

  .lookups, .options {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 20px;

    > .lookup {
      flex: 0 0 32%;
      max-width: 32%;
      position: relative;
    }
  }

  .customer, .locationA, .locationZ { 
    .react-select__dropdown-indicator {  
      display: none;
    }
  }

  .locationZ {
    .react-select--is-disabled {
      cursor: not-allowed;

      .react-select__control {
        background-color: #fff;
        border: 1px solid #eee;
      }
    }

    .react-select__single-value--is-disabled {
      color: #333;
    }
  }

  .term {
    .react-select__value-container,
    .react-select__value {
      height: 100%;
    }
  }

  // Reduce menu height to fit within container
  .folder {
    .react-select__menu-list {
      max-height: 230px;
    }
  }

  .location-combo,
  .location-combo-container {
    flex: 0 0 32%;
  }

  .notification--warning {
    margin-bottom: 1rem;
  }

  .ipv4-container {
    margin-top: .5rem;
    width: 50%;
    min-width: 10rem;
  }

  .excluded-carriers {
    &.lookup.react-select .react-select__control {
      min-height: 50px;
    }

    .react-select__value-container--has-value + .react-select__indicators {
      flex-direction: column;
    }
  }

  .options {
    .react-select__control {
      height: 50px;
      font-size: .875rem;
      color: #5A5F5F;
    }

    .react-select__value-container--has-value {
      &::before {
        content: ' ';
        position: absolute;
        display: inline-block;
        top: 0;
        left: 11px;
        font-size: 11px;
        font-weight: 500;
      }

      .react-select__single-value {
        top: 65%;
      }

      // input field container
      .css-1g6gooi {
        margin: 10px 2px 0;
      }
    }
  }

  .advanced-options {
    background-color: #F2F3F3;
    padding: 0 30px;
    margin: .5rem -30px;

    &.toggleable {
      max-height: 1000px;
      opacity: 1;
      transition: margin 500ms, max-height 500ms, opacity 500ms;
      visibility: visible;
    }

    &.toggleable--collapsed {
      max-height: 0;
      overflow: hidden;
      transition: margin 500ms, max-height 500ms, opacity 500ms, visibility 1ms 500ms;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0;
      
      input { 
        visibility: hidden;
      }
    }
  }

  .advanced-options-toggler-container {
    display: flex;
    justify-content: space-between;
  }

  .advanced-options-toggler-container,
  .widget-create-quote__top-row {
    .toggler {
      font-size: .75rem;
      padding: 5px;
      display: inline-flex;
      align-items: center;
      margin-left: 0;

      .icon.icon-caret-down {
        color: inherit;
        font-size: 1.25rem;

        svg {
          fill: currentColor;
          transition: transform 200ms;
        }
      }
    
      &.toggler--collapse .icon-caret-down svg {
        transform: rotate(180deg);
      }
    }
  }

  .widget-create-quote__top-row {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: .5rem;
  }

  .advanced-options-groups {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    font-size: .75rem;
    margin-top: 2rem;

    .checkbox {
      display: block;
    }
  }

  .advanced-options-group {
    flex: 0 0 32%;
    max-width: 32%;
    position: relative;
    margin-bottom: 2rem;
    margin-left: 2%;

    &:nth-of-type(3n-2) {
      margin-left: 0;
    }

    h3 {
      font-size: .875rem;
      padding-bottom: .25rem;
      margin: 0 0 .5rem;
      border-bottom: 1px solid #D9DBDB;
    }

    .checkbox > label {
      font-weight: 500;
      margin-bottom: 10px;
      position: relative;

      span {
        font-weight: normal;
      }
    }
  }

  .advanced-options-lookups {
    justify-content: flex-start;
    margin-bottom: 0;
    padding-top: 2.5rem;
    
    .lookup + .lookup {
      margin-left: 2%;
    }
  }

  .create-quote-button-container {
    text-align: center;
  }

  .create-quote-button {
    width: 32%;
    min-width: 200px;
    font-weight: 500;
  }

  .widget-create-quote-errors {
    padding: 20px 30px;
    background: #FDEDEE;
    border: 1px solid #ED021E;
    font-weight: bold;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;
  }

  .widget-create-quote__currency-lookup {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .lookup {
      width: 6rem;
    }

    .react-select__control {
      height: unset;
      background-color: transparent;
      border-color: transparent;
      transition: background-color 800ms;
    }

    .react-select__control:hover {
      border-color: transparent;
    }

    .react-select__control--menu-is-open {
      background-color: #fff;
      border: 1px solid $input-border-color; 
    }
    .react-select__single-value {
      padding: 0;
      margin: 0;
    }

    .react-select__value-container {
      padding: 0 0 0 8px;
    }

    .react-select__dropdown-indicator {
      padding: 0 2px;
    }
  }

  .widget-create-quote__currency-label {
    font-size: .875rem;
    padding-right: .5rem;
    color: #404444;
  }

  &__additional-costs {
    color: $rain-dark-30;
    font-size: .675rem;
    padding-left: .5rem; 
    white-space: nowrap;
  }

  .checkbox__description {
    font-size: .625rem;
    color: #747A7A;
    font-style: italic;
    margin-top: -0.2rem;
  }
}
