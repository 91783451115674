.ant-timeline-item-head {
  .icon {
    font-size: 1.5rem;
  }
}

.timeline-item__label {
  font-size: 1.125rem;
  font-weight: 400;
}

.timeline-item__description {
  font-size: .75rem;
  color: #5A5F5F;
}