@import '~Sass/variables';

$quote-requirements-bg-color: $mineral-light-90;
$quote-requirements-color: $mineral-dark-50;

$ordered-background-color: rgba(234,247,239,0.5);
$ordered-border-color: $tree;

.quote-viewer {
  margin: 0 auto 5rem;
  width: 90%;
  font-size: 14px;

  .breadcrumbs {
    margin-left: 0;
  }

  .ant-tabs {
    overflow: visible;
  }

  .ant-tabs-tabpane {
    min-height: 15rem;
  }

  .button:disabled {
    pointer-events: none;
  }

  .context-menu-button.button .icon-ellipsis {
    font-size: 1.5rem;
  }

  .notification--success {
    position: sticky;
    top: 0;
    z-index: 3;
    width: 100vw;
    margin: 0 -5vw;
  }
}

.quote-viewer__loading-overlay {
  background: rgba(255, 255, 255, .8);
  font-size: 1.5rem;

  .loading-overlay__positioning {
    max-height: 100vh;
  }
}

.quote-viewer__currency {
  color: #545454;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.quote-viewer__currency-label {
  font-size: .875rem;
  padding-right: .5rem;
  color: #404444;
}

.quote-requirements {
  background-color: $quote-requirements-bg-color;
  color: $quote-requirements-color;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1.25rem .5rem;
  border-radius: 8px 8px 0 0;
}

.quote-viewer__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 1.25rem 0 .25rem;

  .icon-export {
    font-size: 1.5rem;
    vertical-align: middle;
  }

  .quote-viewer__export {
    padding: 0;
    margin: 0;
  }
}

.quote-viewer__status {
  font-weight: 500;
  text-transform: capitalize;

  .status-icon__text {
    display: inline-block;
    vertical-align: middle;
  }
}

.pricing-quote-container {
  text-align: center;
  font-size: 16px;
  color: #5B6061;
  
  .jumbo-loading-icon {
    color: #ED8702;
  }
}

.pricing-quote-headline {
  margin: .25em 0;
  font-size: 28px;
  font-weight: 500;
  color: #202629;
}


.toggle-legs-container {
  overflow: visible;
  text-align: center;
  position: relative;
}

.toggle-legs-button {
  font-size: 30px;
  width: 1.5em;
  height: 1.5em;
  //line-height: 1.6em;
  // border-radius: 50%;
  text-align: center;
  // position: absolute;
  // background-color: #CCE3F2;	
  // box-shadow: 0 1px 0 0 #676C6C;
  // border: none;
  // outline: none;
  // top: -.75em;
  // z-index: 2;
  cursor: pointer;
  background: none;
  border: none;
}

// span.icon.icon-location.quote-design-icon::after {
//   content: '';
//   border: 1px solid red;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 1em;
// }

.quote-viewer__manual-pricing-notification {
  margin-bottom: 1.5em;
}

.design-editors {
  margin-top: 2em;
}

.design-editors,
.solutions-sets {
  .design-editor {
    margin: 1em 0;
  }
}

.design-editors__button--add-design {
  padding-left: 3em;
  padding-right: 3em;
  border-radius: 3px;

  .icon {
    margin-right: 0.5rem;
  }

  svg {
    transform: scale(1.75);
  }
}

.new-designs__header {
  font-size: 1rem;
  color: #545454;
  line-height: 2;
}

.quote-designs__notification {
  margin-bottom: 1rem;
}

.quote-designs__notification--still-pricing {
  margin-bottom: 1rem;

  .notification__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
}

.quote-designs__notification--broadband {
  margin-bottom: 1.5rem;
  border-color: $rain;
  font-size: .75rem;
  font-style: italic;
}

.design-status--ordered {
  background-color: $ordered-background-color;
  color: white;
  display: table;
  border: 2px solid $ordered-border-color;
  border-radius: 3px;
  padding: .5rem 1rem;
  margin-right: 1rem;
  vertical-align: inherit;
  font-weight: 500;
  letter-spacing: 1px;
}

.quote-viewer__error {
  font-size: 1rem;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  justify-items: center;
  align-items: center;
  width: 460px;
  margin: 2rem auto;
  border-radius: 4px;
  padding: 4rem 0;

  .icon-quote-error {
    margin: 0;
    font-size: 5rem;
    width: .7em;
    text-align: center;
  }

  .quote-error__icon-caption {
    color: #0069AB;
    font-weight: 400;
    font-size: 1rem;
    margin: 0.75rem 0 1rem;
  }

  .quote-viewer__error-headline {
    font-size: 1.75rem;
    text-align: center;
    font-weight: 500;
  }

  .quote-viewer__error-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 21.5rem;
    margin-top: 2rem;

    .button {
      width: 10rem;
    }
  }
}

.quote-viewer__folder-description {
  font-size: .75rem;
  display: inline-flex;

  a {
    display: inline-flex;
    align-items: center;
  }
  .icon {
    color: #1982C5;
    font-size: 1rem;
    margin-right: .25rem;
  }
}

.quote-viewer__review-status-notification {
  margin-bottom: .5rem;

  .flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkbox {
    margin-right: 1rem;

    svg {
      fill: #fff;
    }
  }
}

.quote-viewer__context-menu {
  .react-contexify__item .icon-add-circle-outline {
    transform: rotate(45deg);
  }
}