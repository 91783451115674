@import '~Sass/variables';

$solution-background-color: $white-ice;
$solution-border-color: $rain;

$ordered-background-color: rgba(234,247,239,0.65);
$ordered-border-color: $tree;

.design {
  position: relative;
  color: #222;
  margin-bottom: 1em;
  border-radius: 10px;
  background-color: $solution-background-color;
  border: 1px solid $solution-border-color;

  &.design--ordered {
    background-color: $ordered-background-color;
    border-color: $ordered-border-color;
  }

  .design__message {
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
    
    span {
      font-size: .675rem;
      font-weight: 500;
    }

    .icon {
      font-size: 1.25rem;
      margin: 0 .5rem;
    }
  }
}

.design-row {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.design-endpoint {
  position: relative;
  white-space: nowrap;
  padding: .75em 0 .75em 4rem;
  order: 0;

  > div { 
    line-height: 1.3;
  }
  
  .icon {
    font-size: 2rem;
    position: absolute;
    left: 2rem;
    margin-left: -.5em;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    z-index: 1;
  }
}

.design-row.row2 {
  flex-flow: row nowrap;
  align-items: center;
  min-height: 5em;

  .design-endpoint {
    padding: .25em 0 1em 4rem;
  }
}

.design-pricing-labels {
  font-size: .75rem;
  color: #747A7A;
}


.design-pricing-totals {
  font-weight: 500;
  font-size: 1rem;

  .design-item--broadband-bandwidth {
    margin-right: -0.4rem;

    div {
      display: inline-block;
    }

    div:first-child::after {
      content: '/';
      margin: 0 .25rem 0 0;  
    }
  }
}

.design__tracking-info {
  margin: 0.5rem 1rem 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.design__carrier {
  display: inline-flex;
  align-items: self-end;
  gap: 0.75rem;
  font-weight: 500;

  .icon {
    font-size: 1.5rem;
  }
}

.design__design-reference {
  color: #747A7A;
  font-size: .675rem;
}

.design.--showing-details {
  .design-row.row2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .design-endpoint {
      order: 100;
      align-self: flex-start;
    }
  }
  .design-pricing {
    padding: 0;
  }

  .design-pricing-labels,
  .design-pricing-totals {
    background-color: #F8F9F9;

    & > li { 
      border: none;
      padding: 10px 20px;
    }
  }

  .design-pricing-values > li {
    background-color: #fff;
    border-top: 1px solid #ccc;
    transform: scaleY(1);
    font-size: 1em;
    padding: 20px;

    &:first-child {
      padding-left: 4rem;
    }
  }

  .design-pricing-totals > li {
    font-weight: bold;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #98C7E5;
  }
}

.design-actions__ordered-status {
  display: flex;
  align-items: center;
  font-size: .75rem;
  padding: 0 20px;

  .status-icon__text {
    color: #257D45;
    font-weight: bold;
  }
}

.design-actions {
  .button {
    height: 39px;
    font-weight: 500;
    border-radius: 4px;
    margin: auto .875rem auto 0;
    display: inline-block;
    overflow: visible;
  }

  .split-button .button {
    margin-right: 0;
  }
}

.design-pricing {
  text-align: right;
  overflow: hidden;
  width: 100%;
  position: relative;
  order: 1;
  padding: 0 20px;
}

//.thead, .tr, .tfoot,
.design-pricing-labels,
.design-pricing-values,
.design-pricing-totals {
  display: table-row;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  
  // td
  & > li { 
    list-style-type: none;
    display: table-cell;
    padding: 0;
    transition: transform .1s;
    vertical-align: middle;
    white-space: nowrap;
    width: 1%; // force equal cell spacing 

    &:first-child {
      text-align: left;
    }
  }
}

.design-pricing-labels,
.design-pricing-totals {
  background-color: transparent;

  & > li {
    border: none;
    vertical-align: top;
  }
}

.design-pricing-values > li {
  max-height: 0;
  font-size: 0;
  transform: scaleY(0);
  padding: 0;
  border: none;
}

.tfoot .td:first-child * {
  visibility: hidden;
  height: 0;
}

// Routing lines and icons...
.wrapper::before{
  content: '';
  position: absolute;
  left: 2em;
  top: 0;
  bottom: 0;
  border: 1px dashed lightblue;
}

.loc1, .loc2{
  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 50%;
    width: 4em;
    background-color: #E4F1F7;
  }
}

.loc1::before {
  top: 0;
}

.loc2::before {
  bottom: 0;
}

// COPIED OVER>....
.quote-design {
  .quote-design-start,
  .quote-design-end {


    &::before {
      content:'';
      position: absolute;
      height: 50%;
      border: 1px dashed #3490CB;
      top: 0;
      bottom: 0;
      left: 20px;
    }
  }

  .quote-design-start {
    background-color: #E5F1F8;
    
    &::before {
      top: 50%;
    }
  }

  .quote-design-end {
    background-color: #CCE2F2;
    
    &::before {
      bottom: 50%;
    }
  }

  .quote-design-address {
    padding: 15px;
    flex: 1 1 auto;
    white-space: pre-line;
    font-size: .75rem;
    line-height: .875rem;
  }

  .quote-design-icon {
    background-color: inherit;
    color: #3390cb;
    z-index: 1;
    padding: 0 0 0 2em;
  }

  .quote-design-cost {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    > div + div {
      margin-left: 30px;
    }
  }

  .quote-cost-value {
    font-size: 1.3125rem;
    line-height: 24px;
    font-weight: 500;
    text-align: left;
  }
}

// PRICES
.price {
  white-space: nowrap;

  sup {
    font-size: .5rem;
  }
}

// Dashed line
.design-endpoint::before,
.design.--showing-details .design-pricing::before {
  content:'';
  position: absolute;
  border-left: 1px dashed #3490CB;
  top: 0;
  bottom: 0;
  left: 2rem;
  z-index: 1;
}

.design-endpoint:first-child::before {
  top: 50%;
}
.design-endpoint:last-child::before {
  bottom: 50%;
}

// mileage
.mileage-container {
  position: absolute;
  overflow: visible;
  bottom: -.5rem;
  left: 1rem;
  z-index: 2;

  .mileage {
    border-color: #98C7E5;
    border-radius: 10px;
    color: #333;
    padding: .25em 1em;
  }
}

@media only screen and (max-width: 768px) {
  .design-pricing-totals { 
    .design-item--broadband-bandwidth {
      div {
        display: block;
      }
  
      div:first-child::after {
        content: none;  
      }
    }
  }
}