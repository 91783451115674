@import '~Sass/variables';
$table-row-background-color: $rain-light-90;

.notifications-page {
  .sortable-table {
    td {
      border-bottom: 1px solid #D9DBDB;
    }

    tbody {
      background-color: $table-row-background-color;
      color: #333636;

      .sortable-table-column--date {
        min-width: 7rem;
      }
    
      .sortable-table-column--icon {
        width: 1px;
        padding-left: .75rem;
        position: relative;
      }
    
      .sortable-table-column--user {
        white-space: nowrap;
      }

      .sortable-table-column--comment {
        font-style: italic;
        color: #545454;
        max-width: 22rem;
        padding-right: 2rem;
      }
    }
  }

  .icon-comments {
    font-size: 1.5rem;
    margin-top: -2px;
  }

  .quote-comment-count {
    position: absolute;
    top: 1rem;
    left: 0.6rem;
    padding: 1px 3px 0;
    color: white;
    font-weight: 500;
    border-radius: 4px;
    font-size: 0.75rem;
    opacity: .8;
    background: $icon-color;
  }

  .loading-overlay__positioning {
    position: fixed;
    width: 100%;
  }

  .notifications-page__description {
    margin: -1.5rem 0 1.5rem;
    font-size: .875rem;
    color: #333636;
    opacity: .5;
  }

  .line-clamped {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
