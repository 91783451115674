@import '~Sass/variables';

.footer-links {
  margin: auto 0 0;
  padding: 0 0 2rem;
  text-align: center;

  li {
    display: inline;
    font-size: .75rem;
  }

  li + li::before {
    content: '•';
    color: #404444;
    margin: 0 .5rem;
  }

  a {
    color: $link-button-color;
    font-weight: 400;

    &:hover {
      color: $link-button-color--hovered;
    }
  }
}