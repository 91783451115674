@import 'variables';

@font-face {
  font-family: 'anticon';
  src: url('https://at.alicdn.com/t/font_148784_dky7e838xq4obt9.eot');
  src:
  url('https://at.alicdn.com/t/font_148784_dky7e838xq4obt9.woff') format('woff'),
  url('https://at.alicdn.com/t/font_148784_dky7e838xq4obt9.ttf') format('truetype'),
  url('https://at.alicdn.com/t/font_148784_dky7e838xq4obt9.svg#iconfont') format('svg');
}

.breadcrumbs {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .ant-breadcrumb {
    font-family: unset;
    font-size: 0.875rem;

    a {
      color: $mineral-light-20;
      text-decoration: underline;
      font-weight: normal;

      &:hover {
        color: $mineral;
      }
    }
  }

  .ant-breadcrumb-link,
  .ant-breadcrumb-separator {
    color: $mineral-light-20;
  }
}