@import '~Sass/variables';

.status-dot {
  color: $mineral-light-40;

  &.blue {
    color: $blue-dark-10;
  }

  &.green {
    color: $success;
  }

  &.yellow {
    color: #f4a622;
  }

  &.red {
    color: $error;
  }
}
