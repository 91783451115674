@import url('https://fonts.googleapis.com/css?family=Red+Hat+Text&display=swap');

.poc-page {
  color: #000;
  font-family: ProximaNova, "Red Hat Text", Roboto, "Helvetica Neue", Helvetica, sans-serif;

  header {
    text-align: center;
    font-size: 20px;
    letter-spacing: 3px;
    border-bottom: solid 1px #e2e6eb;
    width: 85%;
    margin: auto;

    .company-name {
      margin: -28px -16px 10px 0;
    }
  }

  .login-poc--eq-form-select {
    display: inline-block;
    margin-left: 10px;
  }

  .login-poc--ug-form-select {
    display: inline-block;
    position: relative;
    top: -10px;
  }

  .login-poc--logo {
    display: inline-block;
  }

}

.body--poc-page {
  background-color: #eeeff3;

  nav {
    display: none;
  }
}
