@import '~Sass/variables';

$arrow-color: $mineral-dark-10;

.context-menu-button.button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;

  &:active {
    background-color: transparent;
    box-shadow: 0px 0px 4px rgba(33,33,33,0.6);
    border: none;
  }

  &:hover {
    background-color: transparent;
    border: none;
  }

  .icon-ellipsis {
    font-size: 1rem;
    color: #818888;
  }  
}

.react-contexify__submenu {
  left: unset !important;
  right: 100%;
}

.context-menu--position-right {
  left: unset !important;
  right: 5%;
}

.react-contexify__item {
  .react-contexify__item__content {
    .icon {
      font-size: 1.5em;
      margin-right: 0.5rem;
    }
  }
  .react-contexify__submenu-arrow {
    top: .5rem;
    right: .125rem;
    transition: right 100ms;

    .icon {
      color: $arrow-color;
    }
  }
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content {
  background-color: $selected-background-color;

  .icon {
    color: #fff;
  }

  .react-contexify__submenu-arrow {
    right: -.125rem;
  }
}

.react-contexify__submenu-wrapper--left {
  .react-contexify__submenu {
    left: unset !important;
  }
}

.react-contexify__submenu-wrapper--selectable {
  & > .react-contexify__submenu {
    .react-contexify__item__content {
      color: #656565;
    }

    .react-contexify__item--selected {
      font-weight: 500;

      .contexify__item__content {
        color: #000;
      }
    }
  
    .react-contexify__item--selected:hover::after {
      color: #fff;
    }
  }
}