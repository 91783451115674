.ecx-login-page {
  color: #2A303D;
  background-color: #eeeff3;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ecx-redirect-text-container {
  text-align: center;
  padding-bottom: 20%;
}

.ecx-redirect-text--reauthenticate {
  max-width: 52rem;
  font-size: 1.75rem;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.ecx-redirect-text--wait {
  font-size: 1.3125rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.ecx-redirect-text--redirect {
  font-size: .875rem;
  margin-bottom: .75rem;
}

.ecx-redirect-link {
  display: inline-block;
  font-size: .75rem;
  font-weight: 500;
  border: 2px solid #369DDB;
  border-radius: 2px;
  color: #48A6DE;
  background-color: transparent;
  padding: .35rem 2rem;
  text-decoration: none;
}