@import '~Sass/variables';

.modal-container.modal-container-contact-vendor-modal {
  .popup {
    transition: transform 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .popup--popped-under {
    filter: blur(2px);
    transform: scale(0.9) translate(-55%, -50%);
  }

  .popup-content {
    min-height: 20rem;
  }
}

.contact-vendor__header {
  margin: 1.5rem 0 .25rem;
}

.contact-vendor__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .lookup {
    flex: 1 1;
  }
}

.contact-vendor__add-comment {
  flex: 0 0;

  .button {
    display: inline-flex;
    align-items: center;
    font-size: .875rem;
  }

  .icon {
    font-size: 1.5rem;
    margin-right: 2px;
  }

}

.contact-vendor__comment-added {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: $success;
}

.contact-vendor__comment-added-label {
  font-size: .875rem;
}

.contact-vendor__list {
  display: flex;
  flex-flow: row wrap;
  gap: .5rem;
  font-size: .75rem;
}

.contact-vendor__list-item {
  flex: 1 1 49%;
  max-width: 49%;
  border-radius: 4px;
  border: 1px solid lightblue;
  padding: .5rem;
}

