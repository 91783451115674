@import '~Sass/variables';

$gap: 1.25rem;

.create-quote {
  position: relative;
  text-align: left;
  margin-top: 2rem;

  .grid {
    display: grid;
    gap: $gap;
    margin-bottom: $gap;
  }

  .col--2 {
    grid-template-columns: 1fr 1fr;
  }

  .col--3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .create-quote__button-wrapper {
    margin-top: 2.5rem;
    text-align: center;

    .button {
      width: 47.5%;
    }
  }

  .address-option {
    font-size: .875rem;
    font-weight: 500;
    color: #4D5151;
    width: 100%;

    &.em-flag {
      background-position-x: left;
      padding-left: 2rem;
    }
  }

  .react-select__dropdown-indicator {
    color: #777;
  }

  .create-quote__currency {
    margin: .25rem 0;
    text-align: right;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 99%;

    .create-quote__currency-label {
      font-size: .875rem;
      color: #1A1B1D;
    }

    .react-select.currency {
      width: 6rem;
      display: inline-block;
      margin-left: .5rem;

      .react-select__control {
        height: unset;
        background-color: transparent;
        border-color: transparent;
        //transition: background-color 800ms;
      }

      .react-select__control:hover {
        border-color: transparent;
      }

      .react-select__control--menu-is-open {
        background-color: #fff;
        border: 1px solid $input-border-color;

        .react-select__single-value {
          color: black;
        }
      }
      .react-select__single-value {
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: .875rem;
        color: #1A1B1D;
      }

      .react-select__value-container {
        padding: 0 0 0 8px;
      }

      .react-select__dropdown-indicator {
        padding: 0 4px;
      }
    }
  }
}